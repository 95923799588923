import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { Navigate, useLocation } from 'react-router-dom';
import { useValidatedState } from '@mantine/hooks';
import { useAuthenticationStatus, useSignInEmailPasswordless } from '@nhost/react';
import { useError } from '@components/Notification';
import { APP_CALENDAR, APP_SETUP } from '@path';
import { handleEnterPress } from '@utils';
import { validateEmail } from '@validate';
import FadeTransition from '@framework/Transition/FadeTransition';
import { PrimaryButton } from '@framework/Button';
import { TextField } from '@framework/Input';
import './Login.scss';

interface RouteProps {}

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.6, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.3 } },
};

const Login: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [authComplete, setAuthComplete] = useState<boolean>(false);
  const { isAuthenticated } = useAuthenticationStatus();
  const triggerError = useError();
  const location = useLocation();
  const { signInEmailPasswordless, isLoading, isError, error } = useSignInEmailPasswordless();

  const [{ value: email, valid: isEmailValid }, setEmail] = useValidatedState('', (val) => validateEmail(val), true);

  useEffect(() => {
    if (isError) {
      triggerError(error);
    }
  }, [isError, error, triggerError]);

  const signInWithMagicLink = async () => {
    if (isEmailValid && email !== '') {
      await signInEmailPasswordless(email, { redirectTo: APP_SETUP });
      setAuthComplete(true);
    } else {
      triggerError('We need a valid email to help you log in.');
    }
  };

  if (isAuthenticated) {
    return <Navigate to={APP_CALENDAR} state={{ from: location }} replace />;
  }

  return (
    <FadeTransition>
      <div className="login-page-container single-page-container side-padding">
        <AnimatePresence mode="wait">
          {!authComplete && (
            <motion.div
              key="login-form"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitionVariants}
              className="login-form"
            >
              <h2 className="spray-magic">No Fuss, No Passwords!</h2>
              <p>
                Simply enter your email address below, and we'll whisk a magic login link straight to your inbox. Say
                goodbye to passwords and embrace the quickest way to begin your exciting adventure with us. Let the
                magic guide you!
              </p>
              <TextField
                placeholder="bruce.wayne@batcave.com, perhaps?"
                className="login-email"
                value={email}
                onChange={setEmail}
                error={!isEmailValid}
                onKeyDown={(e) => handleEnterPress(e, signInWithMagicLink, true)}
              />
              <PrimaryButton size="md" onClick={signInWithMagicLink} loading={isLoading}>
                Shoot!
              </PrimaryButton>
            </motion.div>
          )}
          {authComplete && (
            <motion.div
              key="check-mail-banner"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitionVariants}
              className="auth-complete-banner"
            >
              <h3>Our email should be arriving in your inbox shortly.</h3>
              <p>
                Simply <span className="highlighter-text">click the magic link in your email</span> to begin your
                hassle-free experience.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </FadeTransition>
  );
});

export default Login;
