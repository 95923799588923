import React, { useEffect, useState } from 'react';
import Image from '@framework/Image';
import './Avatar.scss';
import Tooltip from '@framework/Tooltip';

interface RouteProps {
  src?: string;
  name: string;
  className?: string;
  onClick?: Function;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  showNameOnTooltip?: boolean;
}

const fetchInitials = (name: string): string => {
  if (!name || name === '') {
    return 'XX';
  }

  const words = name.split(' ').filter((word) => word.length > 0); // Split by spaces and filter out any empty strings

  if (words.length === 1) {
    // If there's only one word, return the first two characters
    return words[0].substring(0, 2).toUpperCase();
  } else {
    // If there are multiple words, take the first character from the first two words
    return (words[0][0] + words[1][0]).toUpperCase();
  }
};

const Avatar: React.FC<RouteProps> = React.memo(
  ({ src, name, className, onClick, size = 'sm', showNameOnTooltip = true }): JSX.Element => {
    const [showInitials, setShowInitials] = useState<boolean>(false);

    useEffect(() => {
      if (!src) {
        setShowInitials(true);
      }
    }, [src]);

    return (
      <div className={`avatar-wrapper ${className} ${size}`} onClick={() => (onClick ? onClick() : null)}>
        {showNameOnTooltip ? (
          !showInitials ? (
            <Tooltip message={name} align="left">
              <Image src={src} alt={name} className="avatar-image-container" />
            </Tooltip>
          ) : (
            <Tooltip message={name} align="left">
              <div className="avatar-initials-wrapper">{fetchInitials(name)}</div>
            </Tooltip>
          )
        ) : !showInitials ? (
          <Image src={src} alt={name} className="avatar-image-container" />
        ) : (
          <div className="avatar-initials-wrapper">{fetchInitials(name)}</div>
        )}
      </div>
    );
  },
);

export default Avatar;
