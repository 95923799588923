import Icon from '@framework/Icon';
import Tooltip from '@framework/Tooltip';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import Button from './Button';

const MarkButton = ({ format, tooltip, icon }) => {
  const editor = useSlate();

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  return (
    <Tooltip message={tooltip}>
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon name={icon} />
      </Button>
    </Tooltip>
  );
};

export default MarkButton;
