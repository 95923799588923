import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Image.scss';

interface RouteProps {
  src?: string;
  alt?: string;
  className?: string;
  onClick?: Function;
}

const Image: React.FC<RouteProps> = React.memo(({ src, alt, className, onClick }): JSX.Element => {
  const [imageLoading, setImageLoading] = useState(true);

  const imageLoaded = () => {
    setImageLoading(false);
  };

  return (
    <motion.img
      initial={{ opacity: 0 }}
      className={className}
      animate={{
        opacity: imageLoading ? 0 : 1,
      }}
      transition={{ opacity: { delay: 0, duration: 0.2 } }}
      onLoad={imageLoaded}
      onContextMenu={(e) => e.preventDefault()}
      onClick={() => (onClick ? onClick() : null)}
      src={src}
      alt={alt}
      draggable="false"
    />
  );
});

export default Image;
