import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, Variants } from 'framer-motion';
import { useClickOutside } from '@mantine/hooks';
import { storeState } from '@state';
import { APP_LOGOUT, APP_SETTINGS, APP_WORKSPACES } from '@path';
import './UserContextMenu.scss';

interface RouteProps {
  onClose: Function;
}

const containerVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.6, type: 'spring' } },
  exit: { opacity: 0, y: 5, transition: { duration: 0.3 } },
};

const UserContextMenu: React.FC<RouteProps> = React.memo(({ onClose }): JSX.Element => {
  const navigate = useNavigate();
  const ref = useClickOutside(() => onClose());

  const teams = storeState((state: any): any => state.user.teams);
  const self = storeState((state: any): any => state.user.self);

  const navigateToView = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={containerVariants}
      className="user-context-menu-container"
    >
      <div ref={ref} className="user-context-menu-wrapper">
        <div className="greetings">Hey {self.name}</div>
        <nav>
          <ul>
            {teams.length > 1 && <li onClick={() => navigateToView(APP_WORKSPACES)}>Workspaces</li>}
            <li onClick={() => navigateToView(APP_SETTINGS)}>Settings</li>
            <li onClick={() => navigateToView(APP_LOGOUT)}>Logout</li>
          </ul>
        </nav>
      </div>
    </motion.div>
  );
});

export default UserContextMenu;
