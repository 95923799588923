import { useQuery } from '@apollo/client';
import { useAuthenticated, useUserEmail } from '@nhost/react';
import { APP_ERROR, APP_LOGIN, APP_WORKSPACES } from '@path';
import { SELECT_TEAM_BY_WORKSPACE } from '@queries';
import { storeActions, storeState } from '@state';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface RouteProps {}

const WorkspaceDomainHandler: React.FC<RouteProps> = ({}) => {
  const [subdomain, setSubdomain] = useState<string>('');

  const { port } = window.location;
  const isProd = port ? false : true;

  const teams = storeState((state: any): any => state.user.teams);
  const activeTeam = storeState((state: any): any => state.user.activeTeam);
  const setActiveTeam = storeActions((state: any): any => state.user.setActiveTeam);

  const authenticated = useAuthenticated();
  const userEmail = useUserEmail();
  const navigate = useNavigate();
  const location = useLocation();

  const errorTitle = 'Ouch!';
  const errorMessage =
    "Your attempt to access this URL was unsuccessful. This could be because the team doesn't exist or you don't have the necessary permissions to access this team. The most practical step forward is to switch to a workspace where your access is guaranteed. Please proceed to select a workspace you are authorized to use.";
  const actionTitle = 'Access My Workspaces';
  const actionRoute = `https://${process.env.REACT_APP_HOSTNAME}/workspaces`;

  const { data: workspaceData } = useQuery(SELECT_TEAM_BY_WORKSPACE, {
    variables: { workspace: subdomain },
    skip: subdomain === '' || subdomain === null,
  });

  useEffect(() => {
    setSubdomain(getSubdomain());
  }, [window.location]);

  useEffect(() => {
    if (workspaceData?.meating_team) {
      const timeoutId = setTimeout(() => {
        processWorkspace(workspaceData);
      }, 500);

      // Cleanup timeoutDonald
      return () => clearTimeout(timeoutId);
    }
  }, [workspaceData, teams, authenticated]);

  useEffect(() => {
    if (subdomain === null && teams && teams?.length > 0 && !activeTeam && location.pathname !== APP_ERROR) {
      navigate(APP_WORKSPACES);
    }
  }, [subdomain, teams]);

  const getSubdomain = () => {
    const { hostname } = window.location;

    // Split the hostname into parts.
    const parts = hostname.split('.');

    if (
      (!isProd && parts.length === 2 && parts[0] === 'meating' && parts[1] === 'local') ||
      (isProd && parts.length === 2 && parts[0] === 'meating' && parts[1] === 'app')
    ) {
      // If we're on the main domain (either local or production), no subdomain exists.
      return null;
    }

    // If we're here, we're not on the main domain, so we must be on a subdomain.
    // The first part of our hostname should be our subdomain.
    return parts[0];
  };

  const processWorkspace = (data: any) => {
    const teamArray = data.meating_team;
    if (teamArray.length > 0) {
      if (authenticated) {
        const activeTeamDetails = teams?.find((team) => team.workspace === subdomain);
        const isInvited = teamArray[0].rel_invites.some((invite) => invite.email === userEmail);
        if (activeTeamDetails || isInvited) {
          setActiveTeam(activeTeamDetails);
        } else {
          navigateToError();
        }
      }
    } else {
      if (authenticated) {
        navigateToError();
      } else {
        navigateToRoute(APP_LOGIN);
      }
    }
  };

  const navigateToError = () => {
    navigateToRoute(`/error?title=${errorTitle}&detail=${errorMessage}&action=${actionTitle}&route=${actionRoute}`);
  };

  const navigateToRoute = (route: string) => {
    window.location.href = `https://${process.env.REACT_APP_HOSTNAME}${route}`;
  };

  return <></>;
};

export default WorkspaceDomainHandler;
