import React, { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAuthenticated, useUserId } from '@nhost/react';
import { SELECT_USER_TEAM } from '@queries';
import { storeActions, storeState } from '@state';

interface RouteProps {}

const UserTeams: React.FC<RouteProps> = ({}) => {
  const refetchTeams = storeState((state: any): any => state.app.refetchTeams);
  const setTeams = storeActions((action: any): any => action.user.setTeams);
  const setRefetchTeams = storeActions((action: any): any => action.app.setRefetchTeams);

  const isAuthenticated = useAuthenticated();
  const userId = useUserId();

  const { data, refetch } = useQuery(SELECT_USER_TEAM, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  });

  const setTeamsData = useCallback(
    async (data) => {
      if (data?.length) {
        const teams = data.map((item) => ({
          id: item.id,
          name: item.name,
          owner: item.owner,
          workspace: item.workspace,
          day_end_time: item.day_end_time,
          day_start_time: item.day_start_time,
          permitted_users: item.permitted_users,
          next_billing_date: item.next_billing_date,
          time_zone: item.time_zone,
          role: item.rel_members[0].role,
        }));
        await setTeams(teams);
      }
    },
    [setTeams],
  );

  useEffect(() => {
    if (isAuthenticated && userId) {
      refetch();
    }
  }, [userId, isAuthenticated, refetch]);

  useEffect(() => {
    refetch();
  }, [refetchTeams, refetch]);

  useEffect(() => {
    if (data?.meating_team?.length > 0) {
      setTeamsData(data?.meating_team);
      setRefetchTeams(false);
    }
  }, [data, setTeamsData]);

  return <></>;
};

export const useRefetchTeams = () => {
  const setRefetchTeams = storeActions((action: any): any => action.app.setRefetchTeams);

  const triggerTeamsRefetch = useCallback(() => {
    setRefetchTeams(true);
  }, [setRefetchTeams]);

  return { triggerTeamsRefetch };
};

export default UserTeams;
