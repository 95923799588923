import React from 'react';
import './SilentDayNote.scss';

interface RouteProps {
  isWeekend?: boolean;
}

const SILENT_NOTES = [
  'A hush-hush kind of day.',
  'A whisper-quiet agenda.',
  "Today's vibe: Radio silence.",
  'All quiet on the meeting front.',
  "It's a tumbleweed-through-the-office kind of day.",
  "The calendar's taking a siesta.",
  'Unplugged and uninterrupted.',
  'Meetings? They took the day off.',
  "It's a slide-on-socks-through-the-hallway day.",
  'Clear skies, clear schedule.',
  "On today's agenda: peace and quiet.",
];

const SilentDayNote: React.FC<RouteProps> = React.memo(({ isWeekend }): JSX.Element => {
  return (
    <div className="silent-day-note">
      <div className="note-wrapper">
        <p>{isWeekend ? "That's a quiet weekend" : SILENT_NOTES[Math.floor(Math.random() * SILENT_NOTES.length)]}</p>
      </div>
    </div>
  );
});

export default SilentDayNote;
