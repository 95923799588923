import React, { useEffect, useState } from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import TabbedForm, { TabItem } from '@components/TabbedForm';
import BasicsForm from './components/BasicsForm';
import AgendaForm from './components/AgendaForm';
import { useError, useSuccess } from '@components/Notification';
import { MEETING_STANDARD, MEETING_STANDUP } from '@constants';
import {
  INSERT_MEETING,
  INSERT_MEETING_MODERATORS,
  INSERT_MEETING_PARTICIPANTS,
  SELECT_MEETING,
  UPDATE_MEETING,
} from '@queries';
import { useMutation, useQuery } from '@apollo/client';
import { useUserId } from '@nhost/react';
import { diffInMinutes } from '@utils';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { APP_CALENDAR } from '@path';
import { storeState } from '@state';
import './Launchpad.scss';
import StandupItemsForm from './components/StandupItemsForm';
import ensureUserSetup from '@components/User';

interface RouteProps {}

const SECTION_BASICS = 'BASICS';
const SECTION_AGENDA = 'AGENDA';
const SECTION_TASK = 'TASK';

const SYSTEM_AGENDA = [
  {
    title: 'Prelude Period',
    detail:
      'A few moments reserved at the start for everyone to log in, adjust their settings, and prepare mentally for the session ahead.. This phase concludes the moment an agenda item is started.',
    duration: 5,
    order: 1,
  },
];

const Launchpad: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>(SECTION_BASICS);
  const [meetingType, setMeetingType] = useState<string>(MEETING_STANDARD);
  const [totalMins, setTotalMins] = useState<number>(0);
  const [meetingData, setMeetingData] = useState<any>();
  const [switchTabs, setSwitchTabs] = useState<boolean>(false);

  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const [insertMeeting] = useMutation(INSERT_MEETING);
  const [updateMeeting] = useMutation(UPDATE_MEETING);
  const [addParticipants] = useMutation(INSERT_MEETING_PARTICIPANTS);
  const [addModerators] = useMutation(INSERT_MEETING_MODERATORS);

  const userId = useUserId();
  const navigate = useNavigate();
  const triggerSuccess = useSuccess();
  const triggerError = useError();

  const { id } = useParams();

  const { data: meetingDetail } = useQuery(SELECT_MEETING, {
    variables: { id: id },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const useQueryParam = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQueryParam();
  const tab = query.get('tab');

  useEffect(() => {
    const meetingData = meetingDetail?.meating_meeting[0];
    if (meetingData) {
      setMeetingData(meetingData);
      setMeetingType(meetingData.type);
      setTotalMins(diffInMinutes(meetingData.from_time, meetingData.to_time));
      setSwitchTabs(true);
    }
  }, [meetingDetail]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  const onMeetingTypeSelection = (type: string) => {
    setMeetingType(type);
  };

  const scheduleMeeting = async (
    meetingTitle: string,
    meetingDate: Date,
    meetingStartTime: Date,
    meetingEndTime: Date,
    participants: any[],
    moderators: any[],
  ) => {
    //Set the total duration of the meeting
    setTotalMins(diffInMinutes(meetingStartTime, meetingEndTime));

    if (id) {
      const participantsList: { user_id: string }[] =
        participants && participants.length
          ? participants.map((obj) => ({
              meeting_id: id,
              user_id: obj.id,
            }))
          : [];

      const moderatorsList: { user_id: string }[] =
        moderators && moderators.length
          ? moderators.map((obj) => ({
              meeting_id: id,
              user_id: obj.id,
            }))
          : [];

      if (!meetingTitle || !meetingDate || !meetingStartTime || !meetingEndTime) {
        triggerError(
          'Fill in all the fields to save the changes. We see a few fields left empty. If you have modified the meeting date, ensure to set the duration and time of the meeting.',
        );
        return;
      }

      if (activeTeam !== undefined && activeTeam !== null && participantsList.length > 0) {
        await addParticipants({ variables: { participants: participantsList } });
      }

      if (activeTeam !== undefined && activeTeam !== null && moderatorsList.length > 0) {
        await addModerators({ variables: { moderators: moderatorsList } });
      }
      const response = await updateMeeting({
        variables: {
          meetingId: id,
          name: meetingTitle,
          meetingDate: meetingDate,
          fromTime: meetingStartTime,
          toTime: meetingEndTime,
        },
      });
      triggerSuccess('Modified successfully');
    } else {
      let participantsList: { user_id: string }[] = [];
      if (meetingType === MEETING_STANDARD) {
        participantsList = [
          { user_id: userId },
          ...(participants && participants.length
            ? participants.map((obj) => ({
                user_id: obj.id,
              }))
            : []),
        ];
      }
      let moderatorsList: { user_id: string }[] = [];
      if (meetingType === MEETING_STANDUP) {
        moderatorsList = [
          { user_id: userId },
          ...(moderators && moderators.length
            ? moderators.map((obj) => ({
                user_id: obj.id,
              }))
            : []),
        ];
      }
      const response = await insertMeeting({
        variables: {
          type: meetingType,
          name: meetingTitle,
          meetingDate: meetingDate,
          fromTime: meetingStartTime,
          toTime: meetingEndTime,
          createdBy: userId,
          teamId: activeTeam?.id,
          participants: participantsList,
          moderators: moderatorsList,
          agenda: meetingType === MEETING_STANDARD ? SYSTEM_AGENDA : [],
        },
      });

      const meetingId = response?.data?.insert_meating_meeting?.returning?.[0]?.id;
      triggerSuccess('Meeting scheduled successfully');

      if (meetingType === MEETING_STANDARD) {
        navigate(`${APP_CALENDAR}/${meetingId}?tab=${SECTION_AGENDA}`);
      } else if (meetingType === MEETING_STANDUP) {
        navigate(`${APP_CALENDAR}/${meetingId}?tab=${SECTION_TASK}`);
      }
    }
  };

  const tabs: TabItem[] = [
    {
      key: SECTION_BASICS,
      title: 'Meeting Basics',
      component: (
        <BasicsForm
          forEdit={id ? true : false}
          activeType={meetingType}
          meetingData={meetingData}
          onTypeSelect={onMeetingTypeSelection}
          onSubmit={scheduleMeeting}
        />
      ),
      disabled: false,
    },
    meetingType === MEETING_STANDARD && {
      key: SECTION_AGENDA,
      title: 'Agenda Builder',
      component: <AgendaForm meetingId={id} totalMins={totalMins} />,
      disabled: false,
    },
    meetingType === MEETING_STANDUP && {
      key: SECTION_TASK,
      title: 'Discussion Items',
      component: <StandupItemsForm meetingId={id} />,
      disabled: false,
    },
  ];

  return (
    <FadeTransition>
      <div className="new-meeting-page-container side-padding">
        <div className="new-meeting-header-wrapper">
          <div className="new-meeting-header-container">
            <h2>Launchpad</h2>
            <p>
              From idea to execution, this is your meeting command center. Pick a type, set the vibe, invite the crew,
              and chalk out the strategy. Ready, set, meet!
            </p>
          </div>
        </div>
        <TabbedForm active={activeTab} tabs={tabs} switchOnClick={switchTabs} />
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Launchpad);
