import React, { PropsWithChildren, ReactNode } from 'react';
import './ButtonGroup.scss';

interface ButtonGroupType {
  children?: ReactNode | undefined;
}

const ButtonGroup: React.FC<ButtonGroupType> = React.memo(({ children }): JSX.Element => {
  return <div className="editor-button-group">{children}</div>;
});

export default ButtonGroup;
