import React, { useEffect, useState } from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import { PrimaryButton } from '@framework/Button';
import { useLocation } from 'react-router-dom';
import './Error.scss';

interface RouteProps {}

const Error: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [title, setTitle] = useState<string>('Whoops!');

  const useQueryParam = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQueryParam();
  const queryTitle = query.get('title');
  const queryDetail = query.get('detail');
  const queryAction = query.get('action');
  const queryRoute = query.get('route');

  useEffect(() => {
    if (queryTitle) {
      setTitle(queryTitle);
    }
  }, [queryTitle, setTitle]);

  const reloadToPath = (path: string) => {
    window.location.href = path;
  };

  return (
    <FadeTransition>
      <div className="error-page-container single-page-container side-padding">
        <h3>
          {title}
          <h3 className="glitch glitch-1">{title}</h3>
          <h3 className="glitch glitch-2">{title}</h3>
        </h3>
        {queryDetail && <p>{queryDetail}</p>}
        {queryAction && queryRoute && (
          <PrimaryButton onClick={() => reloadToPath(`${queryRoute}`)}>{queryAction}</PrimaryButton>
        )}
      </div>
    </FadeTransition>
  );
});

export default Error;
