import React from 'react';
import './Switch.scss';

interface RouteProps {
  state: boolean;
  onChange: Function;
}

const Switch: React.FC<RouteProps> = React.memo(({ state, onChange }): JSX.Element => {
  return (
    <div className="switch-container">
      <input type="checkbox" name="switch" id="switch" checked={state} onChange={console.log} />
      <label className="switch" onClick={() => onChange()}></label>
    </div>
  );
});

export default Switch;
