import React, { useCallback, useEffect, useState } from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import Icon from '@framework/Icon';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { PrimaryButton } from '@framework/Button';
import { TextField } from '@framework/Input';
import { validateElementName, validateEmail, validateHumanName, validateWorkspaceDomain } from '@validate';
import { useDebouncedValue, useListState, useValidatedState } from '@mantine/hooks';
import Avatar from '@framework/Elements/Avatar';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  INSERT_TEAM,
  INVITE_USER,
  SELECT_USER_DETAILS,
  UPDATE_TEAM_BASICS,
  UPDATE_USER_NAME,
  SELECT_SELF_TEAM_COUNT,
  SELECT_TEAM,
  SELECT_PENDING_INVITES,
  INSERT_TEAM_MEMBER,
  DELETE_TEAM_INVITE,
  SELECT_INVOICES,
  SELECT_WORKSPACE_NAME,
} from '@queries';
import { useUserEmail, useUserId } from '@nhost/react';
import { SelectOption, Team, TeamInvite, UserDetail, UserRole } from '@types';
import { useError, useSuccess } from '@components/Notification';
import { handleEnterPress, toDate } from '@utils';
import './Settings.scss';
import InlineSelect from '@framework/InlineSelect';
import { storeState } from '@state';
import { PLANS_PRICE, USER_ROLES } from '@constants';
import NoRecord from '@components/NoRecord';
import ensureUserSetup from '@components/User';
import { useRefetchTeams } from '@components/Lurker/components/UserTeams';
import { useNavigate } from 'react-router-dom';
import { APP_WORKSPACES } from '@path';

interface RouteProps {}

const SETTINGS_PERSONAL = 'PERSONAL';
const SETTINGS_TEAM = 'TEAM';
const SETTINGS_INVITES = 'INVITES';
const SETTINGS_INVOICE = 'INVOICE';

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.6, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.3 } },
};

const Settings: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [activeState, setActiveState] = useState<string>(SETTINGS_PERSONAL);
  const [isTeamCreated, setIsTeamCreated] = useState<boolean>(false);
  const [teamId, setTeamId] = useState<string>('');
  const [members, setMembers] = useState<any[]>();
  const [pendingInvites, setPendingInvites] = useState<any[]>();
  const [invoices, setInvoices] = useState<any[]>();
  const [menu, setMenu] = useState<any[]>();
  const [selfInvites, selfInvitesHandler] = useListState<any>();
  const [isWorkspaceAvailable, setIsWorkspaceAvailable] = useState<boolean>(false);
  const [showWorkspaceStatus, setShowWorkspaceStatus] = useState<boolean>(false);
  const [{ value: name, valid: isNameValid }, setName] = useValidatedState('', (val) => validateHumanName(val), true);
  const [{ value: teamName, valid: isTeamNameValid }, setTeamName] = useValidatedState(
    '',
    (val) => validateElementName(val),
    true,
  );
  const [{ value: workspace, valid: isWorkspaceValid }, setWorkspaceDomain] = useValidatedState(
    '',
    (val) => validateWorkspaceDomain(val),
    true,
  );
  const [workspaceDomain] = useDebouncedValue(workspace, 200);
  const [{ value: inviteEmail, valid: isInviteEmailValid }, setInviteEmail] = useValidatedState(
    '',
    (val) => validateEmail(val),
    false,
  );

  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const userId = useUserId();
  const userEmail = useUserEmail();
  const triggerSuccess = useSuccess();
  const triggerError = useError();
  const navigate = useNavigate();

  const [getWorkspaceCount, { data: workspaceData }] = useLazyQuery(SELECT_WORKSPACE_NAME);

  const { data: userData } = useQuery(SELECT_USER_DETAILS, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'network-only',
  });

  const { data: selfTeamAggregate, refetch: refetchSelfTeamAggregate } = useQuery(SELECT_SELF_TEAM_COUNT, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'network-only',
  });

  const { data: teamData } = useQuery(SELECT_TEAM, {
    variables: { id: activeTeam?.id },
    skip: !activeTeam || activeTeam.role !== USER_ROLES.ADMIN,
    fetchPolicy: 'network-only',
  });

  const { data: invoicesData } = useQuery(SELECT_INVOICES, {
    variables: { teamId: activeTeam?.id },
    skip: !activeTeam || activeTeam.role !== USER_ROLES.ADMIN,
    fetchPolicy: 'network-only',
  });

  const { data: selfInvitesData } = useQuery(SELECT_PENDING_INVITES, {
    variables: { email: userEmail },
    skip: !userEmail,
  });

  const [savePersonalDetails, { loading: personalSaveLoading, error: personalSaveError }] =
    useMutation(UPDATE_USER_NAME);

  const [createTeam, { loading: createTeamLoading, data: createTeamData, error: createTeamError }] =
    useMutation(INSERT_TEAM);

  const [updateTeamBasics, { loading: updateTeamBasicsLoading, error: updateTeamBasicsError }] =
    useMutation(UPDATE_TEAM_BASICS);

  const [inviteUser, { loading: inviteUserLoading, error: inviteUserError }] = useMutation(INVITE_USER);
  const [saveTeamMember] = useMutation(INSERT_TEAM_MEMBER);
  const [deleteTeamInvite] = useMutation(DELETE_TEAM_INVITE);

  const preparePersonalTab = useCallback((userData: UserDetail) => {
    setName(userData.name);
  }, []);

  useEffect(() => {
    if (activeTeam) {
      let menu = [{ key: SETTINGS_PERSONAL, name: 'Personal', icon: 'settings-personal' }];
      if (activeTeam?.role === USER_ROLES.ADMIN || !isTeamCreated) {
        menu.push({ key: SETTINGS_TEAM, name: 'Team', icon: 'settings-team' });
      }
      menu.push({ key: SETTINGS_INVITES, name: 'Invites', icon: 'email-send' });
      if (activeTeam?.role === USER_ROLES.ADMIN) {
        menu.push({ key: SETTINGS_INVOICE, name: 'Invoices', icon: 'dollar' });
      }
      setMenu(menu);
    }
  }, [activeTeam, isTeamCreated, setMenu]);

  useEffect(() => {
    if (workspaceDomain.length >= 3) {
      validateDomain(workspaceDomain);
    } else {
      setShowWorkspaceStatus(false);
    }
  }, [workspaceDomain]);

  const validateDomain = (domain: string) => {
    getWorkspaceCount({
      variables: {
        workspace: `%${domain}%`,
      },
    });
  };

  const prepareTeamTab = useCallback((teamData: any) => {
    setTeamId(teamData.id);
    setTeamName(teamData.name);
    setWorkspaceDomain(teamData.workspace);
    setMembers(teamData.rel_members);
    setPendingInvites(teamData.rel_invites);
  }, []);

  const prepareInvoicesTab = useCallback((invoicesData: any) => {
    setInvoices(invoicesData);
  }, []);

  const prepareInvites = useCallback((invites: TeamInvite[]) => {
    return invites.map((item) => ({ ...item, complete: false }));
  }, []);

  useEffect(() => {
    if (workspaceData) {
      setShowWorkspaceStatus(true);
      setIsWorkspaceAvailable(workspaceData.meating_team_aggregate.aggregate.count === 0);
    }
  }, [workspaceData]);

  useEffect(() => {
    if (selfInvitesData && selfInvitesData.meating_team_invites?.length > 0) {
      selfInvitesHandler.setState(prepareInvites(selfInvitesData.meating_team_invites));
    }
  }, [selfInvitesData]);

  useEffect(() => {
    setIsTeamCreated(selfTeamAggregate?.meating_team_aggregate?.aggregate?.count);
  }, [selfTeamAggregate, setIsTeamCreated]);

  useEffect(() => {
    if (userData && userData.meating_user_detail?.length > 0) {
      preparePersonalTab(userData.meating_user_detail[0]);
    }
  }, [userData, preparePersonalTab]);

  useEffect(() => {
    if (teamData && teamData.meating_team?.length > 0) {
      prepareTeamTab(teamData.meating_team[0]);
    } else {
    }
  }, [teamData, prepareTeamTab]);

  useEffect(() => {
    if (invoicesData && invoicesData.meating_invoice?.length > 0) {
      prepareInvoicesTab(invoicesData.meating_invoice);
    } else {
    }
  }, [invoicesData, prepareTeamTab]);

  useEffect(() => {
    if (
      (personalSaveError && personalSaveError.message) ||
      (createTeamError && createTeamError.message) ||
      (updateTeamBasicsError && updateTeamBasicsError.message) ||
      (inviteUserError && inviteUserError.message)
    ) {
      triggerError(
        personalSaveError.message ||
          createTeamError.message ||
          updateTeamBasicsError.message ||
          inviteUserError.message,
      );
    }
  }, [personalSaveError, triggerError]);

  useEffect(() => {
    if (
      createTeamData &&
      createTeamData.insert_meating_team &&
      createTeamData.insert_meating_team.returning.length > 0
    ) {
      prepareTeamTab(createTeamData.insert_meating_team.returning[0]);
    }
  }, [createTeamData]);

  const navigatetoTab = (state: string) => {
    setActiveState(state);
  };

  const saveSelfDetails = async () => {
    await savePersonalDetails({
      variables: {
        id: userId,
        name,
      },
    });
    triggerSuccess('Your details have been saved successfully.');
  };

  const createSelfTeam = async () => {
    if (teamName != '' && isWorkspaceAvailable) {
      await createTeam({
        variables: {
          name: teamName,
          workspace,
          owner: userId,
        },
      });
      await refetchSelfTeamAggregate();
      triggerSuccess('Team created successfully.');
      navigate(APP_WORKSPACES);
    } else {
      triggerError('To proceed with saving, please input a valid name and workspace url.');
    }
  };

  const saveTeamBasics = async () => {
    if (teamName != '' && (isWorkspaceAvailable || activeTeam.workspace === workspace)) {
      await updateTeamBasics({
        variables: {
          id: teamId,
          name: teamName,
          workspace,
        },
      });
      triggerSuccess('Your details have been saved successfully.');
    } else {
      triggerError('To proceed with saving, please input a valid name and workspace url.');
    }
  };

  const inviteUserByEmail = async () => {
    await inviteUser({
      variables: {
        id: teamId,
        email: inviteEmail,
      },
    });
    setInviteEmail('');
    triggerSuccess('Invite sent to user');
  };

  const rejectInvite = async (teamId: string, index: number) => {
    await deleteTeamInvite({
      variables: {
        email: userEmail,
        teamId,
      },
    });
    selfInvitesHandler.remove(index);
  };

  const acceptInvite = async (teamId: string, role: UserRole, index: number) => {
    await saveTeamMember({ variables: { teamId, userId, role } });
    await deleteTeamInvite({
      variables: {
        email: userEmail,
        teamId,
      },
    });
    selfInvitesHandler.remove(index);
  };

  return (
    <FadeTransition>
      <div className="settings-page-container single-page-container side-padding">
        <div className="tabs-container">
          <ul>
            {menu?.map((section) => (
              <li
                key={section.key}
                className={`tab-item ease-element ${activeState === section.key ? 'active' : ''}`}
                onClick={() => navigatetoTab(section.key)}
              >
                <Icon name={section.icon} />
                <label>{section.name}</label>
              </li>
            ))}
          </ul>
        </div>
        <div className="details-container">
          <AnimatePresence mode="wait">
            {activeState === SETTINGS_PERSONAL && (
              <motion.div
                key={SETTINGS_PERSONAL}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={transitionVariants}
                className="section-item"
              >
                <section>
                  <div className="title-section">
                    <h4>Basic Details</h4>
                    <PrimaryButton
                      size="sm"
                      onClick={saveSelfDetails}
                      loading={personalSaveLoading}
                      disabled={!isNameValid}
                    >
                      Save
                    </PrimaryButton>
                  </div>
                  <div className="form-section">
                    <div className="field-item field-wrapper">
                      <label>Your Name</label>
                      <p>
                        How do we call you? Your identity matters to us! Let us know how you'd like to be addressed.
                      </p>
                      <TextField
                        placeholder="Bruce Wayne or Batman, who are we addressing?"
                        value={name}
                        onChange={setName}
                        error={!isNameValid}
                      />
                    </div>
                  </div>
                </section>
              </motion.div>
            )}
            {activeState === SETTINGS_TEAM && (
              <motion.div
                key={SETTINGS_TEAM}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={transitionVariants}
                className="section-item"
              >
                {!isTeamCreated && (
                  <section>
                    <div className="title-section">
                      <h4>Create your own Team</h4>
                      <PrimaryButton size="sm" onClick={createSelfTeam} loading={createTeamLoading}>
                        Create
                      </PrimaryButton>
                    </div>
                    <div className="form-section">
                      <div className="field-item field-wrapper">
                        <label>Team Name</label>
                        <p>What do you call your squad? Share your team's name.</p>
                        <TextField
                          placeholder="The Wayne Enterprise"
                          value={teamName}
                          onChange={setTeamName}
                          error={!isTeamNameValid}
                        />
                      </div>
                      <div className="field-item field-wrapper">
                        <label>Workspace URL</label>
                        <p>Carve out a digital space for your team. Like 'yourteam.meating.app'</p>
                        <div className="workspace-name-field-container">
                          <input
                            type="text"
                            placeholder="batman"
                            value={workspace}
                            className="text-field"
                            onChange={(event) => setWorkspaceDomain(event.target.value)}
                          />
                          <label>.meating.app</label>
                          {showWorkspaceStatus &&
                            (isWorkspaceAvailable ? (
                              <span className="available status">workspace name available</span>
                            ) : (
                              <span className="not-available status">workspace name not available</span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </section>
                )}
                {activeTeam?.role === USER_ROLES.ADMIN && (
                  <React.Fragment>
                    <section>
                      <div className="title-section">
                        <div className="info-box">
                          <h4>Manage Team</h4>
                        </div>
                        <PrimaryButton size="sm" onClick={saveTeamBasics} loading={updateTeamBasicsLoading}>
                          Save
                        </PrimaryButton>
                      </div>
                      <div className="form-section">
                        <div className="field-item field-wrapper">
                          <label>Team Name</label>
                          <p>What do you call your squad? Share your team's name.</p>
                          <TextField
                            placeholder="The Wayne Enterprise"
                            value={teamName}
                            onChange={setTeamName}
                            error={!isTeamNameValid}
                          />
                        </div>
                        <div className="field-item field-wrapper">
                          <label>Workspace URL</label>
                          <p>Carve out a digital space for your team. Like 'yourteam.meating.app'</p>
                          <div className="workspace-name-field-container">
                            <input
                              type="text"
                              placeholder="batman"
                              value={workspace}
                              className="text-field"
                              onChange={(event) => setWorkspaceDomain(event.target.value)}
                            />
                            <label>.meating.app</label>
                            {activeTeam.workspace !== workspace &&
                              showWorkspaceStatus &&
                              (isWorkspaceAvailable ? (
                                <span className="available status">workspace name available</span>
                              ) : (
                                <span className="not-available status">workspace name not available</span>
                              ))}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="title-section">
                        <h4>Members</h4>
                      </div>
                      <div className="form-section">
                        <div className="members-container field-wrapper">
                          {members &&
                            members?.map((member) => (
                              <div key={member.rel_user_detail.rel_user.email} className="member-item">
                                <Avatar
                                  src={member.rel_user_detail.avatar}
                                  name={member.rel_user_detail.name}
                                  className="member-avatar"
                                />
                                <div className="member-details">
                                  <h4>{member.rel_user_detail.name}</h4>
                                  <p className="timestamp">Member since {toDate(new Date(member.joined_date))}</p>
                                  <p className="user-email">{member.rel_user_detail.rel_user.email}</p>
                                </div>
                                <div className="role-switch">{member.role}</div>
                              </div>
                            ))}
                          {pendingInvites &&
                            pendingInvites?.map((invite) => (
                              <div key={invite.email} className="invited-item">
                                <div className="member-avatar">
                                  <Icon name="email-send" />
                                </div>
                                <div className="member-details">
                                  <p className="user-email">{invite.email}</p>
                                  <p className="timestamp">Invited on {toDate(new Date(invite.invite_date))}</p>
                                </div>
                                <div className="role-switch">{invite.role}</div>
                              </div>
                            ))}
                        </div>
                        <div className="member-invite-container field-wrapper">
                          {activeTeam?.permitted_users === PLANS_PRICE.whisper.maxUser ? (
                            <p>
                              Hey there! <span className="highlighter-text">Got a team of 5</span> or fewer? Lucky you!
                              Use all our features for free. Just pop in an email address below, hit 'invite', and get
                              your teammates on board. Easy-peasy. 🚀
                              <br />
                              <br />
                              <span className="highlighter-text">More than 5 in your squad?</span> No worries! Just
                              complete our subscription formalities, and you're all set. Let's keep the collaboration
                              ball rolling!
                            </p>
                          ) : (
                            <p>
                              Just pop in an email address below, hit 'invite', and get your teammates on board.
                              Easy-peasy. 🚀
                            </p>
                          )}
                          <div className="invite-user-container">
                            <input
                              placeholder="enter email to invite"
                              value={inviteEmail}
                              onChange={(event) => setInviteEmail(event.target.value)}
                              onKeyDown={(e) =>
                                isInviteEmailValid ? handleEnterPress(e, inviteUserByEmail, false) : null
                              }
                            />
                            <PrimaryButton
                              size="xs"
                              onClick={inviteUserByEmail}
                              disabled={!isInviteEmailValid}
                              loading={inviteUserLoading}
                            >
                              Invite
                            </PrimaryButton>
                          </div>
                        </div>
                      </div>
                    </section>
                  </React.Fragment>
                )}
              </motion.div>
            )}
            {activeState === SETTINGS_INVITES && (
              <motion.div
                key={SETTINGS_INVITES}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={transitionVariants}
                className="section-item"
              >
                <section>
                  <div className="title-section">
                    <h4>Open Invites</h4>
                  </div>
                  <div className="form-section">
                    <div className="invites-container field-wrapper">
                      {selfInvites?.map((invite, index) => (
                        <div key={index} className="invite-item">
                          <div className="invite-details">
                            <h4>{invite.rel_team.name}</h4>
                            <p className="timestamp">Invited {toDate(new Date(invite.invite_date))}</p>
                          </div>
                          <div className="invite-actions">
                            <PrimaryButton
                              size="xs"
                              forSecondaryAction={true}
                              onClick={() => rejectInvite(invite.team_id, index)}
                            >
                              Reject
                            </PrimaryButton>
                            <PrimaryButton size="xs" onClick={() => acceptInvite(invite.team_id, invite.role, index)}>
                              Accept
                            </PrimaryButton>
                          </div>
                        </div>
                      ))}
                      {selfInvites.length === 0 && <NoRecord />}
                    </div>
                  </div>
                </section>
              </motion.div>
            )}
            {activeState === SETTINGS_INVOICE && (
              <motion.div
                key={SETTINGS_INVOICE}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={transitionVariants}
                className="section-item"
              >
                <section>
                  <div className="title-section">
                    <h4>Invoices</h4>
                  </div>
                  <div className="form-section">
                    {activeTeam?.role === USER_ROLES.ADMIN && (
                      <div className="table-container">
                        <div className="row-item">
                          <div className="col-item">Billing Date</div>
                          <div className="col-item">Estimate</div>
                          <div className="col-item">Discount</div>
                          <div className="col-item">Amount Paid</div>
                          <div className="col-item">Invoice/Receipt</div>
                        </div>
                        {invoices?.map((invoice) => (
                          <div className="row-item">
                            <div className="col-item">{toDate(new Date(invoice.billing_date))}</div>
                            <div className="col-item">${invoice.payable}</div>
                            <div className="col-item">${invoice.discount}</div>
                            <div className="col-item">${invoice.payable - invoice.discount}</div>
                            <div className="col-item">
                              <a href={invoice.stripe_invoice_url} target="_blank">
                                Invoice/Receipt
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </section>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Settings);
