import React from 'react';
import Icon from '@framework/Icon';
import './NoRecord.scss';

interface RouteProps {}

const NoRecord: React.FC<RouteProps> = React.memo(({}): JSX.Element => {
  return (
    <div className="no-records-container">
      <Icon name="open-box" />
      <p>All's quiet here!</p>
    </div>
  );
});

export default NoRecord;
