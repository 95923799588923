import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useQuery, useSubscription } from '@apollo/client';
import { SELECT_MINUTES, SUBSCRIBE_MINUTES } from '@queries';
import './MinutesList.scss';
import MinutesItem from './components/MinutesItem';
import NoRecord from '@components/NoRecord';

interface RouteProps {
  meetingId: string;
  polling?: boolean;
  readOnly?: boolean;
}

const MinutesList: React.FC<RouteProps> = React.memo(
  ({ meetingId, polling = false, readOnly = false }): JSX.Element => {
    const [minutes, setMinutes] = useState<any>();

    const minutesSubscription = useSubscription(SUBSCRIBE_MINUTES, {
      variables: { meetingId },
      skip: !meetingId,
    });

    const { data: minutesData } = useQuery(SELECT_MINUTES, {
      variables: { meetingId },
      skip: polling || !meetingId,
    });

    useEffect(() => {
      setMinutes(minutesSubscription?.data?.meating_meeting_minutes);
    }, [minutesSubscription, setMinutes]);

    useEffect(() => {
      setMinutes(minutesData?.meating_meeting_minutes);
    }, [minutesData, setMinutes]);

    return (
      <div className="minutes-list-container">
        <AnimatePresence>
          {minutes?.map((minute, index) => (
            <motion.div
              key={minute.id}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ duration: 1, delay: index * 0.2 }}
            >
              <MinutesItem key={index} data={minute} />
            </motion.div>
          ))}
          {(minutes === undefined || minutes?.length === 0) && <NoRecord />}
        </AnimatePresence>
      </div>
    );
  },
);

export default MinutesList;
