import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import { PrimaryButton } from '@framework/Button';
import './Success.scss';
import { useNavigate } from 'react-router-dom';
import { APP_DASHBOARD } from '@path';
import ensureUserSetup from '@components/User';

interface RouteProps {}

const Success: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const navigate = useNavigate();
  return (
    <FadeTransition>
      <div className="payment-success-page-container single-page-container side-padding">
        <h2>Your payment was successful! Get ready to explore all the exciting Pro features coming your way.</h2>
        <PrimaryButton onClick={() => navigate(APP_DASHBOARD)}>Start Exploring Pro Features</PrimaryButton>
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Success);
