import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Activity.scss';
import ensureUserSetup from '@components/User';

interface RouteProps {}

const Activity: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <FadeTransition>
      <div>Activity</div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Activity);
