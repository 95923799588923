import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Refund.scss';

interface RouteProps {}

const Refund: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <FadeTransition>
      <div className="refund-page-container side-padding">
        <h1>Refund Policy</h1>
        <p>Last updated: 28-Oct-23</p>
        <p>
          Thank you for using Meating.app. We at Foolish Works Technologies Pvt. Ltd. ("we," "us," "our," "Meating.app")
          strive to ensure our users have a productive experience while using our services. Below are the terms and
          conditions governing the Refund Policy. By opting for a paid subscription, you agree to this policy.
        </p>
        <h2>1. General Terms</h2>
        <p>
          All users are encouraged to use our free tier to try and test Meating.app to get a basic understanding of what
          our platform entails. The free version is designed to provide a representative experience of our
          functionalities before you decide to upgrade to a Pro user.
        </p>
        <h2>2. No Refunds</h2>
        <p>
          All payments made for the Pro version of Meating.app are non-refundable, and there are no prorations or
          credits for partially used periods. Upon payment, the user acknowledges and agrees that the sum is charged for
          the entire period stated at the time of purchase, and no refunds will be issued for unused seats or unexpired
          periods.
        </p>
        <h2>3. Upgrading Your Plan</h2>
        <p>
          If you need more seats than your current plan allows, you can contact our support team to adjust your plan
          accordingly. The upgrade will take effect immediately, and the additional cost will be discussed and agreed
          upon before any changes are applied.
        </p>
        <h2>4. Cancellations</h2>
        <p>
          You may cancel your Pro subscription at any time. Upon cancellation, you will have access to the Pro features
          until the end of your current billing cycle, after which the subscription will not renew. We do not provide
          refunds for cancellation or unused functionalities during your active subscription period.
        </p>
        <h2>5. Changes to the Refund Policy</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace this Refund Policy at any time. If a
          revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new
          terms taking effect.
        </p>
        <h2>6. Governing Law</h2>
        <p>
          This Refund Policy is governed and construed in accordance with the laws of Tamil Nadu, India, without regard
          to its conflict of law provisions.
        </p>
        <h2>Contact Us</h2>
        <p>If you have any questions about our Refund Policy, please contact us at:</p>
        <address>
          Foolish Works Technologies Pvt. Ltd.
          <br />
          42/3, Kallankadu Pudur, Elur PO, Namakkal, Tamil Nadu - 637018, Chennai,
          <br />
          Tamil Nadu, India
          <br />
          GSTIN: 33AAECF4842Q1Z9
          <br />
          PAN: AAECF4842Q
          <br />
        </address>
      </div>
    </FadeTransition>
  );
});

export default Refund;
