import React from 'react';
import './TextField.scss';

interface RouteProps {
  label?: string;
  value: string;
  placeholder?: string;
  className?: string;
  onChange: Function;
  autoFocus?: boolean;
  inline?: boolean;
  error?: boolean;
  align?: 'left' | 'center' | 'right';
  onKeyDown?: Function;
  disabled?: boolean;
}

const TextField: React.FC<RouteProps> = React.memo(
  ({
    label,
    value,
    placeholder = 'Type here',
    className,
    onChange,
    autoFocus = false,
    inline = false,
    error,
    align = 'left',
    onKeyDown,
    disabled = false,
  }): JSX.Element => {
    return (
      <div className="input-field-container">
        {label && <label>{label}</label>}
        <div
          className={`input-field-wrapper text-field-wrapper ${inline ? 'inline' : ''} ${
            error ? 'error' : ''
          } ${align} ${className}`}
        >
          <input
            type="text"
            value={value}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className="text-field"
            onChange={(event) => onChange(event.target.value)}
            onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : null)}
            disabled={disabled}
          />
        </div>
      </div>
    );
  },
);

export default TextField;
