import { NhostClient } from '@nhost/react';
import Cookies from 'js-cookie';

// Define a custom storage handler using cookies
const cookieStorage = {
  getItem: (key) => Cookies.get(key),
  setItem: (key, value) => {
    Cookies.set(key, value, {
      expires: 7,
      path: '/',
      domain: process.env.REACT_APP_COOKIE_PATH,
      secure: true,
      sameSite: 'None',
    });
  },
  removeItem: (key) => Cookies.remove(key, { domain: process.env.REACT_APP_COOKIE_PATH }),
};

const nhostClient = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
  clientStorage: cookieStorage,
  clientStorageType: 'custom',
});

export default nhostClient;
