import React from 'react';
import moment from 'moment';
import mobile from 'is-mobile';
import { motion, AnimatePresence } from 'framer-motion';
import { MeetingMinutes } from '@types';
import MeetingItem from './MeetingItem';
import SilentDayNote from './SilentDayNote';
import './Weekday.scss';

interface RouteProps {
  data: any;
}

const Weekday: React.FC<RouteProps> = React.memo(({ data }): JSX.Element => {
  const isMobile = mobile();

  const renderDay = (date) => {
    if (isMobile) {
      return moment(date).format('dd').charAt(0);
    } else {
      return moment(date).format('ddd');
    }
  };

  const renderDate = (date) => {
    if (isMobile) {
      const day = moment(date).format('D');
      const suffix = moment(date).format('Do').slice(-2);
      return (
        <div>
          {day}<span className="suffix">{suffix}</span>
        </div>
      );
    } else {
      return moment(date).format('DD');
    }
  };

  return (
    <div className={`calendar-day-item weekday-item ${data.date === moment().format('YYYY-MM-DD') ? 'today' : ''}`}>
      <div className="calendar-day-header">
        <div className="calendar-day">{renderDay(data.date)}</div>
        <div className="calendar-date">{renderDate(data.date)}</div>
      </div>
      <AnimatePresence>
        {!data.meetings || data.meetings.length === 0 ? (
          <SilentDayNote key={`hush-${data.date}`} />
        ) : (
          <div className="meeting-item-list">
            {data.meetings?.map((meeting: MeetingMinutes, index) => (
              <motion.div
                key={`wrapper-${meeting.id}`}
                initial={{ y: 5, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -5, opacity: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <MeetingItem key={`meeting-${meeting.id}`} data={meeting} />
              </motion.div>
            ))}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default Weekday;
