import React, { ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useViewportSize } from '@mantine/hooks';
import './Notification.scss';

type NotificationProviderProps = {
  children: ReactNode;
};

const containerVariants = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.4, type: 'spring' } },
  exit: { opacity: 0, y: 10, transition: { duration: 0.2 } },
};

// Create Error context
type NotificationContextType = {
  message: string | null;
  type: 'success' | 'error' | null;
  triggerNotification: (msg: string, type: 'success' | 'error') => void;
};

// Create Error context with default values
const NotificationContext = createContext<NotificationContextType>({
  message: null,
  type: null,
  triggerNotification: () => undefined,
});

export const NotificationProvider: React.FC<NotificationProviderProps> = React.memo(({ children }) => {
  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<'success' | 'error' | null>(null);

  // Trigger an error
  const triggerNotification = useCallback((message: string, type: 'success' | 'error') => {
    setMessage(message);
    setType(type);

    // Clear error after 5 seconds
    setTimeout(() => {
      setMessage(null);
      setType(null);
    }, 5000);
  }, []);

  return (
    <NotificationContext.Provider value={{ message, type, triggerNotification }}>
      {children}
    </NotificationContext.Provider>
  );
});

export const useSuccess = (): Function => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useSuccess must be used within a NotificationProvider');
  }
  return (msg: string) => context.triggerNotification(msg, 'success');
};

export const useError = (): Function => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useError must be used within a NotificationProvider');
  }
  return (msg: string) => context.triggerNotification(msg, 'error');
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

interface RouteProps {}

const Notification: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const notificationRef = useRef(null);
  const notification = useNotification();
  const { width } = useViewportSize();
  const [parentWidth, setParentWidth] = useState(0);
  const [rightOffset, setRightOffset] = useState(0);

  useEffect(() => {
    if (notificationRef.current) {
      setParentWidth(notificationRef.current.parentNode.getBoundingClientRect().width);
    }
  }, [notificationRef]);

  useEffect(() => {
    const rightOffset = (width - parentWidth) / 2;
    setRightOffset(rightOffset);
  }, [width, parentWidth]);

  return (
    <div ref={notificationRef} className="notification-container" style={{ right: `${rightOffset}px` }}>
      <AnimatePresence>
        {notification.type && notification.message && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={containerVariants}
            className={`notification-item ${notification.type}`}
          >
            {notification.type === 'error' && <h4>We've Got a Little Situation…</h4>}
            {notification.type === 'success' && <h4>Smooth Sailing!</h4>}
            <p>{notification.message}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default Notification;
