import React from 'react';
import { motion, Variants } from 'framer-motion';

interface RouteProps {
  className?: string;
  children: any;
}

const transitionVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.6 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
};

const FadeTransition: React.FunctionComponent<RouteProps> = ({ className, children }): JSX.Element => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      className={className ? className : ''}
      variants={transitionVariants}
    >
      {children}
    </motion.div>
  );
};

export default FadeTransition;
