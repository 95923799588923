import React, { useState } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useClickOutside } from '@mantine/hooks';
import Icon from '@framework/Icon';
import { toDateWithYear } from '@utils';
import './DatePicker.scss';

interface RouteProps {
  value?: Date;
  onSelect: Function;
  placeholder?: string;
  initialDate?: Date;
  disablePreviousDates?: boolean;
  monthsToShow?: number;
}

interface DatePickerState {
  selectedDate: Date | null;
  startingMonth: Date;
}

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.15 } },
};

const DatePicker: React.FC<RouteProps> = React.memo(
  ({
    value,
    onSelect,
    placeholder = 'Dec 12, 1950',
    initialDate = new Date(),
    disablePreviousDates = true,
    monthsToShow = 2,
  }): JSX.Element => {
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [picker, setPicker] = useState<DatePickerState>({
      selectedDate: initialDate,
      startingMonth: new Date(initialDate.getFullYear(), initialDate.getMonth(), 1),
    });

    const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    const openPicker = () => {
      setShowPicker(true);
    };

    const closePicker = () => {
      setShowPicker(false);
    };

    const ref = useClickOutside(closePicker);

    const isLeapYear = (year: number) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    const getDaysInMonth = (month: number, year: number) => {
      switch (month) {
        case 1: // February
          return isLeapYear(year) ? 29 : 28;
        case 3:
        case 5:
        case 8:
        case 10: // April, June, September, November
          return 30;
        default:
          return 31; // All other months
      }
    };

    const isDateDisabled = (date: Date) => {
      if (!disablePreviousDates) return false;
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to compare just the date
      return date < today;
    };

    const handleDateClick = (date: Date) => {
      if (!isDateDisabled(date)) {
        setPicker((prevState) => ({ ...prevState, selectedDate: date }));
        onSelect(date);
        closePicker();
      }
    };

    const handleNavigation = (increment: number) => {
      setPicker((prevState) => ({
        ...prevState,
        startingMonth: new Date(
          prevState.startingMonth.getFullYear(),
          prevState.startingMonth.getMonth() + increment,
          1,
        ),
      }));
    };

    const renderMonth = (monthStart: Date) => {
      const daysInMonth = getDaysInMonth(monthStart.getMonth(), monthStart.getFullYear());
      const days: JSX.Element[] = [];
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to compare just the date

      // Determine the day of the week for the first day of the month
      const firstDayOfMonth = new Date(monthStart.getFullYear(), monthStart.getMonth(), 1).getDay();

      // Fill in the gaps for the days before the first day of the month
      for (let i = 0; i < firstDayOfMonth; i++) {
        days.push(<span key={`empty-${i}`} className="date-item empty"></span>);
      }

      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(monthStart.getFullYear(), monthStart.getMonth(), day);
        const isSelected =
          picker.selectedDate?.getDate() === day &&
          picker.selectedDate?.getMonth() === monthStart.getMonth() &&
          picker.selectedDate?.getFullYear() === monthStart.getFullYear();

        const isToday = date.getTime() === today.getTime();
        const isDisabled = isDateDisabled(date);

        days.push(
          <span
            key={`date-${date}`}
            onClick={() => handleDateClick(date)}
            className={`date-item ease-element ${isDisabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''} ${
              isToday ? 'today' : ''
            }`}
          >
            {day}
          </span>,
        );
      }

      return (
        <div key={`month-${monthStart.toLocaleString()}`} className="month-box">
          <h4>
            {monthStart.toLocaleString('default', { month: 'long' })} {monthStart.getFullYear()}
          </h4>
          <div className="day-markers">
            {weekdays.map((day, index) => (
              <span key={`day-${monthStart}-${day}-${index}`} className="day-item">
                {day}
              </span>
            ))}
          </div>
          <div className="date-markers">{days}</div>
        </div>
      );
    };

    // Check if the "Previous" button should be disabled
    const today = new Date();
    const isPreviousButtonDisabled =
      disablePreviousDates &&
      picker.startingMonth.getFullYear() === today.getFullYear() &&
      picker.startingMonth.getMonth() === today.getMonth();

    return (
      <div className="date-picker-field-container">
        <div className={`selected-value ${!value ? 'placeholder' : ''}`} onClick={openPicker}>
          {value ? toDateWithYear(value) : placeholder}
        </div>
        <AnimatePresence mode="wait">
          {showPicker && (
            <motion.div
              ref={ref}
              className="date-picker-wrapper"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitionVariants}
            >
              <Icon
                name="arrow-left"
                className={`switch-action ease-element ${isPreviousButtonDisabled ? 'disabled' : ''}`}
                onClick={() => (!isPreviousButtonDisabled ? handleNavigation(-monthsToShow) : null)}
              />
              <div className="month-wrapper">
                {[...Array(monthsToShow)].map((_, index) =>
                  renderMonth(new Date(picker.startingMonth.getFullYear(), picker.startingMonth.getMonth() + index, 1)),
                )}
              </div>
              <Icon
                name="arrow-right"
                className="switch-action ease-element"
                onClick={() => handleNavigation(monthsToShow)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  },
);

export default DatePicker;
