import React, { useEffect, useState } from 'react';
import './StandupItemsForm.scss';
import { storeState } from '@state';
import { TextArea, TextField } from '@framework/Input';
import SearchUser from '@components/SearchUser';
import Avatar from '@framework/Elements/Avatar';
import { PrimaryButton } from '@framework/Button';
import { useUserId } from '@nhost/react';
import TaskList from '@components/Session/TaskList';
import { useMutation } from '@apollo/client';
import { INSERT_MEETING_PARTICIPANTS, INSERT_STANDUP_TASK } from '@queries';

interface RouteProps {
  meetingId: string;
}

const StandupItemsForm: React.FC<RouteProps> = React.memo(({ meetingId }): JSX.Element => {
  const [itemTitle, setItemTitle] = useState<string>('');
  const [itemDetail, setItemDetail] = useState<string>('');
  const [itemLead, setItemLead] = useState<any>(null);

  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const userId = useUserId();

  const [addParticipants] = useMutation(INSERT_MEETING_PARTICIPANTS);

  const [saveTask, { loading: taskSaveLoading }] = useMutation(INSERT_STANDUP_TASK);

  const saveItem = async () => {
    const data = {
      meetingId,
      title: itemTitle,
      description: itemDetail,
      assignedTo: itemLead.id,
      addedBy: userId,
    };

    await saveTask({ variables: data });
    await addParticipants({ variables: { participants: [{ meeting_id: meetingId, user_id: itemLead.id }] } });

    //Save the item
    clearForm();
  };

  const clearForm = () => {
    setItemTitle('');
    setItemDetail('');
    setItemLead(null);
  };

  return (
    <div className="standup-items-form-container">
      <div className="form-wrapper">
        <div className="field-item">
          <TextField
            placeholder="Upcoming Tasks for the Next Week"
            label="Define the Central Theme of This Agenda Point"
            value={itemTitle}
            onChange={setItemTitle}
          />
        </div>
        <div className="field-item">
          <TextArea
            placeholder="Outline the tasks slated for the upcoming week, assigning roles and
                  setting deadlines."
            label="Define the Central Theme of This Agenda Point"
            value={itemDetail}
            onChange={setItemDetail}
          />
        </div>
        <div className={`field-item ${activeTeam === undefined || activeTeam === null ? 'disabled' : ''}`}>
          <label>Designate the Individual Leading This Discussion Topic</label>
          <SearchUser
            teamId={activeTeam?.id}
            onSelect={setItemLead}
            disabled={activeTeam === undefined || activeTeam === null}
          />
        </div>
        {itemLead && (
          <div className="field-item">
            <div className="lead-card-container">
              <Avatar size="sm" src={itemLead.avatar} name={itemLead.name} />
              <label>{itemLead.name}</label>
            </div>
          </div>
        )}
        <div className="actions">
          <PrimaryButton size="sm" onClick={saveItem} loading={taskSaveLoading}>
            Add Item
          </PrimaryButton>
        </div>
      </div>
      <div className="item-list-wrapper">
        <TaskList meetingId={meetingId} polling={true} />
      </div>
    </div>
  );
});

export default StandupItemsForm;
