import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { APP_CALENDAR, APP_DIGEST, APP_SESSION } from '@path';
import { diffInMinutes, isDateAndTimePast } from '@utils';
import './MeetingItem.scss';

interface RouteProps {
  data: any;
  isWeekend?: boolean;
}

const MeetingItem: React.FC<RouteProps> = React.memo(({ data, isWeekend = false }): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      className="calendar-meeting-item"
      onClick={() =>
        isDateAndTimePast(new Date(data.to_time))
          ? navigate(`${APP_DIGEST}/${data.id}`)
          : navigate(`${APP_SESSION}/${data.id}`)
      }
    >
      <div className="timeline">
        <div>{!isWeekend ? moment(data.from_time).format('HH[h]mm') : moment(data.from_time).format('DD-HH[h]mm')}</div>
        <div className="duration">{diffInMinutes(new Date(data.from_time), new Date(data.to_time))}</div>
      </div>
      <div className="title">{data.name}</div>
      <div className="participants">
        {data.rel_meeting_participants.length > 0
          ? `+${data.rel_meeting_participants.length} participant(s)`
          : `Just you`}
      </div>
      {data.rel_meeting_minutes?.length > 0 && (
        <div className="action-items">
          {data.rel_meeting_minutes?.map((minute) => {
            if (minute.type === 'Action') return <span key={`action-${minute.id}`}></span>;
          })}
        </div>
      )}
    </div>
  );
});

export default MeetingItem;
