import React from 'react';
import { useNavigate } from 'react-router-dom';
import FadeTransition from '@framework/Transition/FadeTransition';
import { PrimaryButton } from '@framework/Button';
import { APP_HOME } from '@path';
import './NotFound.scss';

interface RouteProps {}

const NotFound: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const navigate = useNavigate();

  return (
    <FadeTransition>
      <div className="lost-page-container single-page-container side-padding">
        <h3>
          Whoops!
          <h3 className="glitch glitch-1">Whoops!</h3>
          <h3 className="glitch glitch-2">Whoops!</h3>
        </h3>
        <p>
          We've looked under the rug and behind the curtains. Looks like the page took a day off. Let's get you to the
          main event.
        </p>
        <PrimaryButton onClick={() => navigate(APP_HOME)}>Start Fresh</PrimaryButton>
      </div>
    </FadeTransition>
  );
});

export default NotFound;
