export const validateEmail = (input: string): boolean => {
  const regex = /^\S+@\S+\.\S+$/;
  return regex.test(input);
};

export const validateHumanName = (input: string): boolean => {
  const regex = /^[A-Za-z\s]+$/;
  return regex.test(input);
};

export const validateElementName = (input: string): boolean => {
  return input.length > 5;
};

export const validateWorkspaceDomain = (input: string): boolean => {
  // Subdomain regex pattern
  // - Begins with a letter or number
  // - Only contains letters, numbers, or hyphens
  // - Ends with a letter or number
  // - Is between 1 and 63 characters long (common limitation for subdomains)
  const pattern = /^[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?$/i;
  return input.length >= 3 && pattern.test(input);
};
