import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_HOME } from '@path';
import Logo from '@images/app-logo-dark.png';
import './LeftPane.scss';

interface RouteProps {}

const LeftPane: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="header-pane header-menu-pane">
      <img src={Logo} alt="Meating" className="app-logo" onClick={() => navigate(APP_HOME)} />
    </div>
  );
});

export default LeftPane;
