import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { useParams } from 'react-router-dom';
import FadeTransition from '@framework/Transition/FadeTransition';
import { useQuery, useSubscription } from '@apollo/client';
import { SELECT_MEETING, SUBSCRIBE_MEETING_SESSION } from '@queries';
import SessionInfo from './components/SessionInfo';
import TabbedForm, { TabItem } from '@components/TabbedForm';
import AgendaList from './components/AgendaList';
import Participants from './components/Participants';
import MinutesEditor from './components/MinutesEditor';
import MinutesList from './components/MinutesList';
import SessionIntro from './components/SessionIntro';
import { MEETING_SESSION_STATUS } from '@constants';
import SessionOutro from './components/SessionOutro';
import VideoCallInterface from './components/VideoCallInterface';
import './Session.scss';
import { useUserId } from '@nhost/react';

interface RouteProps {}

const transitionVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.6 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
};

const SECTION_AGENDA = 'AGENDA';
const SECTION_PARTICIPANTS = 'PARTICIPANTS';
const SECTION_MINUTES = 'MINUTES';
const SECTION_MINUTES_EDITOR = 'MINUTES_EDITOR';
const SECTION_CHAT_EDITOR = 'CHAT_EDITOR';

const Session: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [passedIntro, setPassedIntro] = useState<boolean>(false);
  const [endSession, setEndSession] = useState<boolean>(false);
  const [meetingData, setMeetingData] = useState<any>();
  const [sessionId, setSessionId] = useState<number>();
  const [rtcToken, setRtcToken] = useState<string>('');

  const { id } = useParams();
  const userId = useUserId();

  const { data: meetingDetail } = useQuery(SELECT_MEETING, {
    variables: { id: id },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const sessionSubscription = useSubscription(SUBSCRIBE_MEETING_SESSION, {
    variables: { id: sessionId },
    skip: !sessionId,
  });

  useEffect(() => {
    if (MEETING_SESSION_STATUS.EXPIRED === sessionSubscription?.data?.meating_meeting_sessions[0].status) {
      setEndSession(true);
    }
  }, [sessionSubscription]);

  useEffect(() => {
    const meetingData = meetingDetail?.meating_meeting[0];
    if (meetingData) {
      setMeetingData(meetingData);
    }
  }, [meetingDetail]);

  useEffect(() => {
    const participants = meetingData?.rel_meeting_participants;
    if (participants) {
      const user = participants?.find((item) => item.user_id === userId);
      const rtcToken = user?.webrtc_token;
      if (rtcToken) {
        setRtcToken(rtcToken);
      }
    }
  }, [meetingData]);

  const detailSectionTabs: TabItem[] = [
    {
      key: SECTION_AGENDA,
      title: 'Agenda',
      component: <AgendaList meetingId={id} />,
      disabled: false,
    },
    {
      key: SECTION_PARTICIPANTS,
      title: 'Participants',
      component: <Participants meetingId={id} />,
      disabled: false,
    },
    {
      key: SECTION_MINUTES,
      title: 'Minutes',
      component: <MinutesList meetingId={id} />,
      disabled: false,
    },
  ];

  const activitySectionTabs: TabItem[] = [
    {
      key: SECTION_MINUTES_EDITOR,
      title: 'Minutes',
      component: <MinutesEditor meetingId={id} editMessage={null} onEditComplete={null} />,
      disabled: false,
    },
    {
      key: SECTION_CHAT_EDITOR,
      title: 'Chat',
      component: <div>Chat</div>,
      disabled: true,
    },
  ];

  const startSession = (sessionId: number) => {
    setSessionId(sessionId);
    setPassedIntro(true);
  };

  return (
    <FadeTransition>
      <AnimatePresence mode="wait">
        {endSession ? (
          <motion.div key="session-outro" initial="initial" animate="animate" exit="exit" variants={transitionVariants}>
            <SessionOutro />
          </motion.div>
        ) : passedIntro ? (
          <motion.div key="session" initial="initial" animate="animate" exit="exit" variants={transitionVariants}>
            <div className="session-page-container single-page-container side-padding">
              <div className="session-left-lane">
                <SessionInfo meetingData={meetingData} sessionId={sessionId} />
                <section className="session-details-container">
                  <TabbedForm tabs={detailSectionTabs} switchOnClick={true} />
                </section>
              </div>
              <div className="session-right-lane">
                <VideoCallInterface authToken={rtcToken} />
                <section className="session-activity-container">
                  <TabbedForm tabs={activitySectionTabs} switchOnClick={true} />
                </section>
              </div>
            </div>
          </motion.div>
        ) : (
          <motion.div key="session-intro" initial="initial" animate="animate" exit="exit" variants={transitionVariants}>
            <SessionIntro data={meetingData} onJoin={startSession} />
          </motion.div>
        )}
      </AnimatePresence>
    </FadeTransition>
  );
});

export default Session;
