import { KeyboardEvent } from 'react';
import moment from 'moment';
import { SESSION_STATE } from '@constants';

// Keyboard utils
export const handleEnterPress = (
  e: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>,
  callback: () => void,
  allowShiftEnter = false,
) => {
  if (e.key === 'Enter' && !(allowShiftEnter && e.shiftKey)) {
    callback();
    e.preventDefault();
  }
};

// String utils
export const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

// Date utils
export const toTimeAndDate = (date: Date): string => {
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);

  // Get only the date (without time)
  const onlyDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  let day: string;
  if (onlyDate.getTime() === new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()) {
    // check if it was less than an hour ago
    const diffInMinutes = Math.round((now.getTime() - date.getTime()) / 60000);
    if (diffInMinutes === 0) {
      return 'just now';
    }
    if (diffInMinutes === 1) {
      return 'a min ago';
    }
    if (diffInMinutes < 60) {
      return diffInMinutes + ' minutes ago';
    }
    day = 'today';
  } else if (
    onlyDate.getTime() === new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()).getTime()
  ) {
    day = 'yesterday';
  } else {
    // Change this to adjust date format
    day = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  }

  // Add leading zero to minutes if needed
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  // Change this to adjust time format
  const time = date.getHours() + ':' + minutes + ' hours';

  return time + ', ' + day;
};

export const toDate = (date: Date): string => {
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);

  // Get only the date (without time)
  const onlyDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  let day: string;
  if (onlyDate.getTime() === new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()) {
    day = 'today';
  } else if (
    onlyDate.getTime() === new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()).getTime()
  ) {
    day = 'yesterday';
  } else {
    // Change this to adjust date format
    day = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  }

  return day;
};

export const toDateWithYear = (date: Date): string => {
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);

  // Get only the date (without time)
  const onlyDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  let day: string;
  if (onlyDate.getTime() === new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()) {
    day = 'today';
  } else if (
    onlyDate.getTime() === new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()).getTime()
  ) {
    day = 'yesterday';
  } else {
    // Change this to adjust date format
    day = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
  }

  return day;
};

export const toTime = (date: Date): string => {
  const now = new Date();

  // check if it was less than an hour ago
  const diffInMinutes = Math.round((now.getTime() - date.getTime()) / 60000);
  if (diffInMinutes === 0) {
    return 'just now';
  }
  if (diffInMinutes === 1) {
    return 'a min ago';
  }
  if (diffInMinutes < 60) {
    return diffInMinutes + ' minutes ago';
  }

  // Add leading zero to hours and minutes if needed
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  return hours + ':' + minutes;
};

export const toStrictTime = (date: Date): string => {
  // Add leading zero to hours and minutes if needed
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  return hours + ':' + minutes;
};

export const padWithZeros = (n: number, expectedLength: number): string => {
  const str = n.toString();
  if (str.length >= expectedLength) return str;

  const zerosToPrepend = expectedLength - str.length;
  return '0'.repeat(zerosToPrepend) + str;
};

export const diffInMinutes = (fromDate: Date, toDate: Date) => {
  const from = moment(fromDate);
  const to = moment(toDate);

  return to.diff(from, 'minutes');
};

export const diffInMinutesAndSeconds = (fromDate: Date, toDate: Date) => {
  const from = moment(fromDate);
  const to = moment(toDate);

  const totalMinutes = to.diff(from, 'minutes');
  const remainingSeconds = to.diff(from, 'seconds') % 60;

  return `${totalMinutes}.${String(remainingSeconds).padStart(2, '0')}`;
};

export const formatDateForSession = (startDate: Date, endDate: Date): string => {
  // Define the time and date format
  const timeFormat = 'HH:mm';
  const dateFormat = 'DD MMM, YY';

  // Format the start and end times
  const startTime = moment(startDate).format(timeFormat);
  const endTime = moment(endDate).format(timeFormat);

  // Check if the start and end dates are on the same day
  if (moment(startDate).isSame(endDate, 'day')) {
    // Format the date they have in common
    const commonDate = moment(startDate).format(dateFormat);
    return `${startTime} - ${endTime}, ${commonDate}`;
  } else {
    // Format the individual start and end dates
    const startDateFormatted = moment(startDate).format(dateFormat);
    const endDateFormatted = moment(endDate).format(dateFormat);
    return `${startTime} ${startDateFormatted} - ${endTime} ${endDateFormatted}`;
  }
};

export const isSessionActive = (startDate: Date, endDate: Date) => {
  const from = moment(startDate);
  const to = moment(endDate);
  const now = moment();

  if (now.isBetween(from, to)) {
    return true;
  } else {
    return false;
  }
};

export const isDateAndTimePast = (date: Date) => {
  const todayDate = moment();
  const pastDate = moment(date);
  return pastDate.isBefore(todayDate);
};

export const isDatePast = (date: Date) => {
  const todayDate = moment().startOf('day');
  const pastDate = moment(date).startOf('day');
  return pastDate.isBefore(todayDate);
};

export const isDateFuture = (date: Date) => {
  const todayDate = moment().startOf('day');
  const pastDate = moment(date).startOf('day');
  return todayDate.isBefore(pastDate);
};

export const getDateStatus = (startDate, endDate) => {
  const now = moment();
  if (now.isBefore(startDate)) {
    return SESSION_STATE.SCHEDULED;
  } else if (now.isAfter(endDate)) {
    return SESSION_STATE.EXPIRED;
  } else {
    return SESSION_STATE.ACTIVE;
  }
};
