import React from 'react';
import { NhostApolloProvider } from '@nhost/react-apollo';
import { NhostProvider } from '@nhost/react';
import { BrowserRouter as Router } from 'react-router-dom';
import nhostClient from '@foundation';
import Routes from '@routes';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Notification, { NotificationProvider } from '@components/Notification';
import Celebration from '@components/Animations/Celebration';
import './Reset.scss';
import './App.scss';
import Lurker from '@components/Lurker';

const App: React.FC = (): JSX.Element => {
  return (
    <NhostProvider nhost={nhostClient}>
      <NhostApolloProvider nhost={nhostClient}>
        <Router>
          <NotificationProvider>
            <Lurker />
            <Celebration />
            <div className="app-container ease-element">
              <Notification />
              <Header />
              <Routes />
            </div>
            <Footer />
          </NotificationProvider>
        </Router>
      </NhostApolloProvider>
    </NhostProvider>
  );
};

export default App;
