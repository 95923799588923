import React, { useEffect } from 'react';
import { storeState } from '@state';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Workspaces.scss';
import ensureUserSetup from '@components/User';
import { APP_DASHBOARD } from '@path';
import { useRefetchTeams } from '@components/Lurker/components/UserTeams';

interface RouteProps {}

const Workspaces: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const teams = storeState((state: any): any => state.user.teams);
  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const { triggerTeamsRefetch } = useRefetchTeams();

  useEffect(() => {
    triggerTeamsRefetch();
  }, []);

  useEffect(() => {
    let switchTeamTimeout;

    const checkAndSwitchTeam = () => {
      if (teams && teams.length === 1) {
        const workspace = teams[0].workspace;
        switchTeam(workspace);
      }
    };

    if (teams) {
      // timeout is set here to give space for triggerTeamsRefetch to complete
      switchTeamTimeout = setTimeout(checkAndSwitchTeam, 500);
    }

    return () => {
      clearTimeout(switchTeamTimeout);
    };
  }, [teams]);

  const switchTeam = (domain: string) => {
    const { protocol } = window.location;
    window.location.href = `${protocol}//${domain}.${process.env.REACT_APP_HOSTNAME}${APP_DASHBOARD}`;
  };

  return (
    <FadeTransition>
      <div className="workspaces-page-container side-padding">
        <h3>Switch Workspace</h3>
        <p>Easily navigate and manage your projects by switching between different workspaces seamlessly.</p>
        <div className="workspace-list-container">
          <ul>
            {teams?.map((team) => (
              <li
                key={team.id}
                className={`workspace-item ease-element ${team.id === activeTeam?.id ? 'active' : ''}`}
                onClick={() => switchTeam(team.workspace)}
              >
                <h5>{team.name}</h5>
                <p>{team.workspace}.meating.app</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Workspaces);
