import { gql } from '@apollo/client';

//Query
export const PLACEHOLDER_FOR_COMPONENT_BUILT_QUERY = gql`
  query PLACEHOLDER_FOR_COMPONENT_BUILT_QUERY {
    meating_user_detail {
      name
    }
  }
`;

export const SELECT_USER_DETAILS = gql`
  query SELECT_USER_DETAILS($id: uuid!) {
    meating_user_detail(where: { id: { _eq: $id } }) {
      id
      name
      avatar
      rel_user {
        email
      }
      rel_team_members_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const SELECT_PENDING_INVITES = gql`
  query SELECT_PENDING_INVITES($email: String!) {
    meating_team_invites(where: { email: { _eq: $email } }) {
      invite_date
      team_id
      role
      rel_team {
        name
      }
    }
  }
`;

export const SELECT_TEAM = gql`
  query SELECT_TEAM($id: bigint!) {
    meating_team(where: { id: { _eq: $id } }) {
      id
      name
      owner
      workspace
      time_zone
      day_start_time
      day_end_time
      rel_invites {
        email
        invite_date
        role
      }
      rel_members {
        rel_user_detail {
          id
          name
          avatar
          rel_user {
            email
          }
        }
        role
        joined_date
      }
    }
  }
`;

export const SELECT_SELF_TEAM_COUNT = gql`
  query SELECT_SELF_TEAM_COUNT($id: uuid!) {
    meating_team_aggregate(where: { owner: { _eq: $id } }) {
      aggregate {
        count
      }
    }
  }
`;

export const SELECT_MEETING = gql`
  query SELECT_MEETING($id: uuid!) {
    meating_meeting(where: { id: { _eq: $id } }) {
      id
      type
      name
      meeting_date
      from_time
      to_time
      created_at
      created_by
      rel_meeting_participants {
        user_id
        webrtc_token
        rel_user_detail {
          id
          name
          avatar
          rel_user {
            email
          }
        }
      }
    }
  }
`;

export const SELECT_AGENDA = gql`
  query SELECT_AGENDA($meetingId: uuid!) {
    meating_meeting_agenda(where: { meeting_id: { _eq: $meetingId } }, order_by: { order: asc }) {
      id
      title
      detail
      lead
      duration
      order
      complete
      active
      start_at
      end_at
      rel_user_detail {
        id
        name
        avatar
      }
    }
  }
`;

export const SELECT_TASK = gql`
  query SELECT_TASK($meetingId: uuid!) {
    meating_meeting_task(where: { meeting_id: { _eq: $meetingId } }, order_by: { id: asc }) {
      title
      description
      assigned_to
      status
      added_by
      rel_user_detail {
        name
        avatar
        rel_user {
          email
        }
      }
    }
  }
`;

export const SELECT_MINUTES = gql`
  query SELECT_MINUTES($meetingId: uuid!) {
    meating_meeting_minutes(where: { meeting_id: { _eq: $meetingId } }, order_by: { id: asc }) {
      id
      time
      type
      message
      actor
    }
  }
`;

export const SELECT_INVOICES = gql`
  query SELECT_INVOICES($teamId: bigint!) {
    meating_invoice(where: { team_id: { _eq: $teamId }, success: { _is_null: false } }) {
      billing_date
      discount
      payable
      seats
      stripe_invoice_url
    }
  }
`;

export const SELECT_TEAM_BY_WORKSPACE = gql`
  query SELECT_TEAM_BY_WORKSPACE($workspace: String!) {
    meating_team(where: { workspace: { _eq: $workspace } }) {
      id
      name
      owner
      workspace
      day_end_time
      day_start_time
      time_zone
      rel_invites {
        email
      }
    }
  }
`;

export const SELECT_USER_TEAM = gql`
  query SELECT_USER_TEAM($id: uuid!) {
    meating_team(where: { rel_members: { user_id: { _eq: $id } } }) {
      id
      name
      owner
      workspace
      time_zone
      day_start_time
      day_end_time
      permitted_users
      next_billing_date
      rel_invites {
        email
        invite_date
        role
      }
      rel_members(where: { rel_user_detail: { id: { _eq: $id } } }) {
        rel_user_detail {
          id
          name
          avatar
          rel_user {
            email
          }
        }
        role
        joined_date
      }
    }
  }
`;

export const SELECT_WORKSPACE_NAME = gql`
  query SELECT_WORKSPACE_NAME($workspace: String!) {
    meating_team_aggregate(where: { workspace: { _ilike: $workspace } }) {
      aggregate {
        count
      }
    }
  }
`;

//Subscription

export const SUBSCRIBE_TEAM_MEETINGS = gql`
  subscription SUBSCRIBE_MEETINGS($startDate: timestamptz!, $endDate: timestamptz!, $user: uuid!, $teamId: bigint!) {
    meating_meeting(
      where: {
        _and: [
          { meeting_date: { _gte: $startDate, _lte: $endDate } }
          { team_id: { _eq: $teamId } }
          { _or: [{ created_by: { _eq: $user } }, { rel_meeting_participants: { user_id: { _eq: $user } } }] }
        ]
      }
    ) {
      id
      name
      meeting_date
      from_time
      to_time
      created_by
      rel_meeting_participants {
        rel_user_detail {
          id
          name
          avatar
          rel_user {
            email
          }
        }
      }
      rel_meeting_minutes {
        type
      }
    }
  }
`;

export const SUBSCRIBE_SELF_MEETINGS = gql`
  subscription SUBSCRIBE_MEETINGS($startDate: timestamptz!, $endDate: timestamptz!, $user: uuid!) {
    meating_meeting(
      where: {
        _and: [
          { meeting_date: { _gte: $startDate, _lte: $endDate } }
          { team_id: { _is_null: true } }
          { _or: [{ created_by: { _eq: $user } }, { rel_meeting_participants: { user_id: { _eq: $user } } }] }
        ]
      }
    ) {
      id
      name
      meeting_date
      from_time
      to_time
      created_by
      rel_meeting_participants {
        rel_user_detail {
          id
          name
          avatar
          rel_user {
            email
          }
        }
      }
      rel_meeting_minutes {
        type
      }
    }
  }
`;

export const SUBSCRIBE_MINUTES = gql`
  subscription SUBSCRIBE_MINUTES($meetingId: uuid!) {
    meating_meeting_minutes(where: { meeting_id: { _eq: $meetingId } }, order_by: { id: asc }) {
      id
      time
      type
      message
      actor
    }
  }
`;

export const SUBSCRIBE_AGENDA = gql`
  subscription SUBSCRIBE_AGENDA($meetingId: uuid!) {
    meating_meeting_agenda(where: { meeting_id: { _eq: $meetingId } }, order_by: { order: asc }) {
      id
      title
      detail
      lead
      duration
      order
      complete
      active
      start_at
      end_at
      rel_user_detail {
        id
        name
        avatar
      }
    }
  }
`;

export const SUBSCRIBE_TASK = gql`
  subscription SUBSCRIBE_TASK($meetingId: uuid!) {
    meating_meeting_task(where: { meeting_id: { _eq: $meetingId } }, order_by: { id: asc }) {
      title
      description
      assigned_to
      status
      added_by
      rel_user_detail {
        name
        avatar
        rel_user {
          email
        }
      }
    }
  }
`;

export const SUBSCRIBE_PARTICIPANTS = gql`
  subscription SUBSCRIBE_PARTICIPANTS($meetingId: uuid!) {
    meating_meeting_participants(where: { meeting_id: { _eq: $meetingId } }) {
      rel_user_detail {
        id
        name
        avatar
        rel_user {
          email
        }
      }
    }
  }
`;

export const SUBSCRIBE_MEETING_SESSION = gql`
  subscription SUBSCRIBE_MEETING_SESSION($id: bigint!) {
    meating_meeting_sessions(where: { id: { _eq: $id } }) {
      status
    }
  }
`;

export const SUBSCRIBE_NOTIFICATION = gql`
  subscription SUBSCRIBE_NOTIFICATION($id: uuid!) {
    meating_notification(where: { user_id: { _eq: $id } }, order_by: { id: asc }) {
      id
      team_id
      type
      message
      actions
      rel_team {
        name
      }
    }
  }
`;

//Mutation
export const INSERT_TEAM = gql`
  mutation INSERT_TEAM(
    $name: String!
    $workspace: String!
    $owner: uuid!
    $timeZone: String
    $dayStartTime: String
    $dayEndTime: String
  ) {
    insert_meating_team(
      objects: {
        name: $name
        workspace: $workspace
        owner: $owner
        time_zone: $timeZone
        day_start_time: $dayStartTime
        day_end_time: $dayEndTime
        rel_members: { data: { role: "ADMIN", user_id: $owner } }
      }
    ) {
      returning {
        id
        name
        workspace
        time_zone
        day_start_time
        day_end_time
        rel_invites {
          email
          invite_date
          role
        }
        rel_members {
          rel_user_detail {
            id
            name
            avatar
            rel_user {
              email
            }
          }
          role
          joined_date
        }
      }
    }
  }
`;

export const INSERT_TEAM_MEMBER = gql`
  mutation INSERT_TEAM_MEMBER($teamId: bigint!, $userId: uuid!, $role: String!) {
    insert_meating_team_members(objects: { team_id: $teamId, user_id: $userId, role: $role }) {
      affected_rows
    }
  }
`;

export const INSERT_MEETING = gql`
  mutation INSERT_MEETING(
    $type: String!
    $name: String!
    $meetingDate: timestamptz!
    $fromTime: timestamptz!
    $toTime: timestamptz!
    $createdBy: uuid!
    $teamId: bigint
    $participants: [meating_meeting_participants_insert_input!]!
    $moderators: [meating_meeting_moderators_insert_input!]!
    $agenda: [meating_meeting_agenda_insert_input!]!
  ) {
    insert_meating_meeting(
      objects: {
        type: $type
        name: $name
        meeting_date: $meetingDate
        from_time: $fromTime
        to_time: $toTime
        created_by: $createdBy
        team_id: $teamId
        rel_meeting_participants: { data: $participants }
        rel_meeting_moderators: { data: $moderators }
        rel_meeting_agendas: { data: $agenda }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_USER_NAME = gql`
  mutation INSERT_USER_NAME($id: uuid!, $name: String!) {
    insert_meating_user_detail(
      objects: { id: $id, name: $name }
      on_conflict: { constraint: user_detail_pkey, update_columns: name }
    ) {
      affected_rows
    }
  }
`;

export const INVITE_USER = gql`
  mutation INVITE_USER($id: bigint!, $email: String!) {
    insert_meating_team_invites(objects: { email: $email, team_id: $id }) {
      affected_rows
    }
  }
`;

export const INSERT_MEETING_MINUTE = gql`
  mutation INSERT_MEETING_MINUTE($meetingId: uuid!, $type: String!, $message: String!, $time: timestamptz!) {
    insert_meating_meeting_minutes(objects: { meeting_id: $meetingId, type: $type, message: $message, time: $time }) {
      affected_rows
    }
  }
`;

export const INSERT_MEETING_AGENDA = gql`
  mutation INSERT_MEETING_AGENDA(
    $meetingId: uuid!
    $title: String!
    $detail: String!
    $duration: Int!
    $lead: uuid!
    $order: Int!
    $addedBy: uuid!
  ) {
    insert_meating_meeting_agenda(
      objects: {
        title: $title
        detail: $detail
        meeting_id: $meetingId
        duration: $duration
        lead: $lead
        order: $order
        added_by: $addedBy
      }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_STANDUP_TASK = gql`
  mutation INSERT_STANDUP_TASK(
    $meetingId: uuid!
    $title: String!
    $description: String!
    $assignedTo: uuid!
    $addedBy: uuid!
  ) {
    insert_meating_meeting_task(
      objects: {
        title: $title
        description: $description
        assigned_to: $assignedTo
        added_by: $addedBy
        meeting_id: $meetingId
      }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_MEETING_SESSION = gql`
  mutation INSERT_MEETING_SESSION($meetingId: uuid!, $userId: uuid!, $startTime: timestamptz!, $status: String!) {
    insert_meating_meeting_sessions(
      objects: { meeting_id: $meetingId, user_id: $userId, start_time: $startTime, status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_MEETING_PARTICIPANTS = gql`
  mutation INSERT_MEETING_PARTICIPANTS($participants: [meating_meeting_participants_insert_input!]!) {
    insert_meating_meeting_participants(
      objects: $participants
      on_conflict: { constraint: meeting_participants_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_MEETING_MODERATORS = gql`
  mutation INSERT_MEETING_MODERATORS($moderators: [meating_meeting_moderators_insert_input!]!) {
    insert_meating_meeting_moderators(objects: $moderators) {
      affected_rows
    }
  }
`;

export const INSERT_TEAM_INVOICE = gql`
  mutation INSERT_TEAM_INVOICE(
    $teamId: bigint!
    $cycle: String!
    $estimate: numeric!
    $payable: numeric!
    $seats: numeric!
    $stripeSessionId: String!
  ) {
    insert_meating_invoice(
      objects: {
        team_id: $teamId
        cycle: $cycle
        estimate: $estimate
        payable: $payable
        seats: $seats
        stripe_session_id: $stripeSessionId
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_NAME = gql`
  mutation UPDATE_USER_NAME($id: uuid!, $name: String!) {
    update_meating_user_detail(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

export const UPDATE_TEAM_BASICS = gql`
  mutation UPDATE_TEAM_BASICS($id: bigint!, $name: String!, $workspace: String!) {
    update_meating_team(where: { id: { _eq: $id } }, _set: { name: $name, workspace: $workspace }) {
      affected_rows
    }
  }
`;

export const UPDATE_MEETING_NAME = gql`
  mutation UPDATE_MEETING_NAME($id: bigint!, $name: String!) {
    update_meating_meeting(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

export const UPDATE_MEETING_MINUTE = gql`
  mutation UPDATE_MEETING_MINUTE($id: uuid!, $type: String!, $message: String!) {
    update_meating_meeting_minutes(where: { id: { _eq: $id } }, _set: { type: $type, message: $message }) {
      affected_rows
    }
  }
`;

export const UPDATE_START_AGENDA = gql`
  mutation UPDATE_START_AGENDA($id: Int!, $startAt: timestamptz!) {
    update_meating_meeting_agenda(where: { id: { _eq: $id } }, _set: { start_at: $startAt, active: true }) {
      affected_rows
    }
  }
`;

export const UPDATE_COMPLETE_AGENDA = gql`
  mutation UPDATE_COMPLETE_AGENDA($id: Int!, $endAt: timestamptz!) {
    update_meating_meeting_agenda(
      where: { id: { _eq: $id } }
      _set: { end_at: $endAt, active: false, complete: true }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_END_USER_SESSION = gql`
  mutation UPDATE_END_USER_SESSION($id: bigint!, $endTime: timestamptz!, $status: String!) {
    update_meating_meeting_sessions(where: { id: { _eq: $id } }, _set: { end_time: $endTime, status: $status }) {
      affected_rows
    }
  }
`;

export const UPDATE_END_ALL_USER_SESSION = gql`
  mutation UPDATE_END_ALL_USER_SESSION($id: uuid!, $endTime: timestamptz!, $fromStatus: String!, $toStatus: String!) {
    update_meating_meeting_sessions(
      where: { meeting_id: { _eq: $id }, status: { _eq: $fromStatus } }
      _set: { status: $toStatus, end_time: $endTime }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_MEETING = gql`
  mutation UPDATE_MEETING(
    $meetingId: uuid!
    $name: String!
    $meetingDate: timestamptz!
    $fromTime: timestamptz!
    $toTime: timestamptz!
  ) {
    update_meating_meeting(
      where: { id: { _eq: $meetingId } }
      _set: { name: $name, meeting_date: $meetingDate, from_time: $fromTime, to_time: $toTime }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_MEETING_MINUTE = gql`
  mutation DELETE_MEETING_MINUTE($id: bigint!) {
    delete_meating_meeting_minutes(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_TEAM_INVITE = gql`
  mutation DELETE_TEAM_INVITE($email: String!, $teamId: bigint!) {
    delete_meating_team_invites(where: { email: { _eq: $email }, team_id: { _eq: $teamId } }) {
      affected_rows
    }
  }
`;

export const CREATE_CHECKOUT_SESSION = gql`
  mutation CREATE_CHECKOUT_SESSION($cycle: String!, $seats: Int!, $success_url: String!, $cancel_url: String!) {
    createCheckoutSession(cycle: $cycle, seats: $seats, success_url: $success_url, cancel_url: $cancel_url) {
      sessionId
    }
  }
`;
