import React, { useCallback, useEffect } from 'react';
import { useSignOut } from '@nhost/react';
import ensureUserSetup from '@components/User';

interface RouteProps {}

const LogOut: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const { signOut } = useSignOut();

  const signOutUser = useCallback(async () => {
    await signOut();
  }, [signOut]);

  useEffect(() => {
    signOutUser();
  }, [signOutUser]);

  return <></>;
});

export default ensureUserSetup(LogOut);
