import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_SETUP } from '@path';
import { useUserId } from '@nhost/react';
import { useQuery } from '@apollo/client';
import { SELECT_USER_DETAILS } from '@queries';

const ensureUserSetup = (Component: React.FC): React.FC => {
  return (props) => {
    const userId = useUserId();
    const navigate = useNavigate();

    const { data: userData, loading } = useQuery(SELECT_USER_DETAILS, {
      variables: { id: userId },
      skip: !userId,
      fetchPolicy: 'network-only',
    });

    useEffect(() => {
      if (
        !loading &&
        (!userData ||
          userData.meating_user_detail?.length === 0 ||
          !userData.meating_user_detail[0].rel_team_members_aggregate ||
          userData.meating_user_detail[0].rel_team_members_aggregate?.aggregate.count === 0)
      ) {
        navigate(APP_SETUP);
      }
    }, [userData, loading, navigate]);

    return <Component {...props} />;
  };
};

export default ensureUserSetup;
