import React, { useEffect, useState } from 'react';
import mobile from 'is-mobile';
import { useSubscription } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useUserId } from '@nhost/react';
import moment from 'moment';
import { MeetingMinutes } from '@types';
import { APP_LAUNCHPAD } from '@path';
import FadeTransition from '@framework/Transition/FadeTransition';
import ensureUserSetup from '@components/User';
import Icon from '@framework/Icon';
import { PrimaryButton } from '@framework/Button';
import { WEEKEND_DAYS } from '@constants';
import Weekend from './components/Weekend';
import Weekday from './components/Weekday';
import './Calendar.scss';
import { storeState } from '@state';
import { SUBSCRIBE_SELF_MEETINGS, SUBSCRIBE_TEAM_MEETINGS } from '@queries';

interface RouteProps {}

type WeekDay = {
  date: string;
  dayNames: string;
  meetings: MeetingMinutes[];
};

const Calendar: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const userId = useUserId();
  const navigate = useNavigate();
  const isMobile = mobile();
  const [meetings, setMeetings] = useState<MeetingMinutes[]>();
  const [currentWeek, setCurrentWeek] = useState<moment.Moment>(moment());

  const meetingList = useSubscription(activeTeam ? SUBSCRIBE_TEAM_MEETINGS : SUBSCRIBE_SELF_MEETINGS, {
    variables: {
      startDate: currentWeek.clone().startOf('isoWeek').toDate(),
      endDate: currentWeek.clone().endOf('isoWeek').toDate(),
      user: userId,
      teamId: activeTeam?.id,
    },
    skip: !userId,
  });

  useEffect(() => {
    if (meetingList && meetingList.data && meetingList.data.meating_meeting) {
      setMeetings(meetingList.data.meating_meeting);
    }
  }, [meetingList]);

  const getWeekDays = (): WeekDay[] => {
    const startOfWeek = currentWeek.clone().startOf('isoWeek');
    let weekendMeetings: MeetingMinutes[] = [];
    let weekendDates: string[] = [];
    let weekendDayNames: string[] = [];
    let combinedDays: WeekDay[] = [];

    for (let i = 0; i < 7; i++) {
      const date = startOfWeek.clone().add(i, 'days');
      const dayName = date.format('ddd');
      const meetingsOnDay = meetings?.filter((m) => moment(m.meeting_date).isSame(date, 'day')) || [];

      // Check if the day is a weekend day
      if (WEEKEND_DAYS.includes(dayName)) {
        weekendMeetings = [...weekendMeetings, ...meetingsOnDay];
        weekendDates.push(date.format('YYYY-MM-DD'));
        weekendDayNames.push(dayName);

        // If the next day isn't a weekend or we are on the last day of the week, add the weekend entry
        if (i === 6 || !WEEKEND_DAYS.includes(date.clone().add(1, 'days').format('ddd'))) {
          combinedDays.push({
            date: weekendDates.join(' & '),
            dayNames: weekendDayNames.join(' - '),
            meetings: weekendMeetings,
          });
          weekendMeetings = [];
          weekendDates = [];
          weekendDayNames = [];
        }
      } else {
        combinedDays.push({ date: date.format('YYYY-MM-DD'), dayNames: dayName, meetings: meetingsOnDay });
      }
    }

    return combinedDays;
  };

  const handleNextWeek = () => {
    setCurrentWeek(currentWeek.clone().add(1, 'week'));
  };

  const handlePreviousWeek = () => {
    setCurrentWeek(currentWeek.clone().subtract(1, 'week'));
  };

  const handleToday = () => {
    setCurrentWeek(moment());
  };

  const displayTitle = (): string => {
    let startOfWeek = currentWeek.clone().startOf('isoWeek');
    let endOfWeek = currentWeek.clone().endOf('isoWeek');

    let dateString = startOfWeek.format('DD');

    if (startOfWeek.month() !== endOfWeek.month()) {
      // If the start and end of the week are in different months, include the month in both dates
      dateString += ' ' + startOfWeek.format('MMM') + ' - ' + endOfWeek.format('DD MMM');
    } else {
      // If the start and end of the week are in the same month, include the month only in the end date
      dateString += ' - ' + endOfWeek.format('DD MMM');
    }
    dateString += "' " + currentWeek.format('YY');

    return dateString;
  };

  return (
    <FadeTransition>
      <div className="meetings-page-container side-padding">
        <div className="meetings-calendar-header">
          <div className="calendar-info-section">
            <h3>{displayTitle()}</h3>
          </div>
          <div className="calendar-action-section">
            <div className="calendar-actions">
              <button className="action-trigger timeline-switch ease-element" onClick={handlePreviousWeek}>
                <Icon name="arrow-line-left" />
              </button>
              <button className="action-trigger timeline-switch ease-element" onClick={handleNextWeek}>
                <Icon name="arrow-line-right" />
              </button>
              <button className="action-trigger ease-element" onClick={handleToday}>
                Today
              </button>
              <button className="action-trigger disabled">Week</button>
            </div>
            <PrimaryButton size={isMobile ? 'xs' : 'sm'} onClick={() => navigate(APP_LAUNCHPAD)}>
              New Meeting
            </PrimaryButton>
          </div>
        </div>
        <div className="meetings-calendar-data">
          <div className="data-wrapper">
            {getWeekDays().map((day) =>
              day.date.includes('&') ? (
                <Weekend key={`weekend-${day.date}`} data={day} />
              ) : (
                <Weekday key={`weekday-${day.date}`} data={day} />
              ),
            )}
          </div>
        </div>
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Calendar);
