import React from 'react';
import LeftPane from './components/LeftPane';
import RightPane from './components/RightPane';
import './Header.scss';

interface RouteProps {}

const Header: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <header className="side-padding">
      <LeftPane />
      <RightPane />
    </header>
  );
});

export default Header;
