import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MINUTE_TYPES } from '@constants';
import './MinutesType.scss';

interface RouteProps {
  active: string;
  asValue?: boolean;
  onChange?: Function;
}

const typeMenuVariants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
    display: 'block',
  },
  exit: {
    opacity: 0,
    y: -5,
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

const MinutesType: React.FC<RouteProps> = React.memo(({ active, asValue = false, onChange }): JSX.Element => {
  const [showTypeMenu, setShowTypeMenu] = useState(false);

  const selectMinuteType = (type: string) => {
    onChange(type);
    setShowTypeMenu(false);
  };

  return (
    <motion.div
      className="minutes-message-type-wrapper"
      onHoverStart={() => (!asValue ? setShowTypeMenu(true) : null)}
      onHoverEnd={() => (!asValue ? setShowTypeMenu(false) : null)}
    >
      <div className="type-item">
        <span
          className="color-code"
          style={{ backgroundColor: (MINUTE_TYPES as { [key: string]: any })[active].color }}
        ></span>
        {active}
      </div>
      <motion.div
        className="type-list"
        initial="exit"
        animate={showTypeMenu ? 'enter' : 'exit'}
        variants={typeMenuVariants}
      >
        <div className="options-wrapper">
          <ul>
            {Object.keys(MINUTE_TYPES).map((key) => {
              const option = (MINUTE_TYPES as { [key: string]: any })[key];
              return (
                <li key={key} className={key === active ? 'active' : ''} onClick={() => selectMinuteType(key)}>
                  <label>
                    <span className="color-code" style={{ backgroundColor: option.color }}></span>
                    {key}
                  </label>
                  <p>{option.description}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </motion.div>
    </motion.div>
  );
});

export default MinutesType;
