import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, Variants } from 'framer-motion';
import { useClickOutside, useScrollLock } from '@mantine/hooks';
import { storeState } from '@state';
import { APP_CALENDAR, APP_SETTINGS, APP_WORKSPACES } from '@path';
import Avatar from '@framework/Elements/Avatar';
import './MobileMenu.scss';
import { SelectOption } from '@types';

interface RouteProps {
  onClose: Function;
}

const wrapperVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.2, type: 'spring', when: 'beforeChildren' } },
  exit: { opacity: 0, transition: { duration: 0.3, when: 'afterChildren' } },
};

const containerVariants: Variants = {
  initial: { opacity: 0, x: 20 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.6, type: 'spring' } },
  exit: { opacity: 0, x: 10, transition: { duration: 0.3 } },
};

const MobileMenu: React.FC<RouteProps> = React.memo(({ onClose }): JSX.Element => {
  const [scrollLocked, setScrollLocked] = useScrollLock();

  const navigate = useNavigate();
  const ref = useClickOutside(() => onClose());

  const self = storeState((state: any): any => state.user.self);

  useEffect(() => {
    setScrollLocked(true);
  }, [setScrollLocked]);

  const navigateToView = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={wrapperVariants}
      className="mobile-menu-container"
    >
      <motion.div variants={containerVariants} ref={ref} className="mobile-menu-wrapper">
        <nav className="menu-wrapper">
          <ul>
            <li onClick={() => navigateToView(APP_WORKSPACES)}>Workspaces</li>
            <li onClick={() => navigateToView(APP_CALENDAR)}>Calendar</li>
            <li onClick={() => navigateToView(APP_SETTINGS)}>Settings</li>
          </ul>
        </nav>
        {self && (
          <div className="user-container">
            <Avatar src={self.avatar || ''} name={self.name || 'user-avatar'} className="ease-element" />
            <div className="details-wrapper">
              <div className="name">{self.name}</div>
              <div className="email">{self.email}</div>
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
});

export default MobileMenu;
