import React, { useState, useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { motion, AnimatePresence } from 'framer-motion';
import { SUBSCRIBE_NOTIFICATION } from '@queries';
import { storeState } from '@state';
import { useUserId } from '@nhost/react';
import './Bulletin.scss';

interface RouteProps {}

interface Notification {
  id: bigint;
  team_id: bigint | null;
  type: string;
  message: string;
  actions: JSON | null;
  is_read: boolean;
  created_at: string;
}

const Bulletin: React.FC<RouteProps> = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const userId = useUserId();

  const notificationList = useSubscription(SUBSCRIBE_NOTIFICATION, {
    variables: {
      id: userId,
    },
    skip: !userId,
  });

  useEffect(() => {
    setNotifications(notificationList?.data?.meating_notification);
  }, [notificationList]);

  const handleDismiss = (id: bigint) => {
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));
  };

  const handleNavigation = (url: string) => {
    window.location.href = url;
  };

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
  };

  return (
    <div className="bulletin-container">
      <AnimatePresence>
        {notifications &&
          notifications?.map((notif, index) => (
            <motion.div key={`bulletin-${index}`} initial="hidden" animate="visible" exit="exit" variants={variants}>
              <div className="bulletin-item">
                <p>{notif.created_at}</p>
                <p>{notif.message}</p>
              </div>
              <button onClick={() => handleDismiss(notif.id)}>Got it</button>
            </motion.div>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default Bulletin;
