import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSubscription } from '@apollo/client';
import { SUBSCRIBE_MINUTES } from '@queries';
import MinuteItem from './MinuteItem';
import Icon from '@framework/Icon';
import './MinutesList.scss';
import NoRecord from '@components/NoRecord';

interface RouteProps {
  meetingId: string;
}

const MinutesList: React.FC<RouteProps> = React.memo(({ meetingId }): JSX.Element => {
  const [minutes, setMinutes] = useState<any>();

  const minutesSubscription = useSubscription(SUBSCRIBE_MINUTES, {
    variables: { meetingId },
    skip: !meetingId,
  });

  useEffect(() => {
    setMinutes(minutesSubscription?.data?.meating_meeting_minutes);
  }, [minutesSubscription]);

  return (
    <div className="session-minutes-items-wrapper">
      <div className="minutes-list">
        <AnimatePresence>
          {minutes?.map((minute, index) => (
            <motion.div
              key={minute.id}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ duration: 1, delay: index * 0.2 }}
            >
              <MinuteItem key={index} data={minute} />
            </motion.div>
          ))}
          {minutes?.length === 0 && <NoRecord />}
        </AnimatePresence>
      </div>
    </div>
  );
});

export default MinutesList;
