import React, { useEffect } from 'react';
import { useClipboard } from '@mantine/hooks';
import GridAnimations from '@components/Animations/GridAnimations';
import SessionType from '../../../components/Session/SessionType';
import SessionTimer from './SessionTimer';
import { formatDateForSession } from '@utils';
import Icon from '@framework/Icon';
import { APP_SESSION } from '@path';
import { useMutation } from '@apollo/client';
import { UPDATE_END_ALL_USER_SESSION, UPDATE_END_USER_SESSION } from '@queries';
import { MEETING_SESSION_STATUS } from '@constants';
import './SessionInfo.scss';

interface RouteProps {
  meetingData: any;
  sessionId: number;
}

const SessionInfo: React.FC<RouteProps> = React.memo(({ meetingData, sessionId }): JSX.Element => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  const clipboard = useClipboard({ timeout: 500 });

  const [endUserSession] = useMutation(UPDATE_END_USER_SESSION);
  const [endAllUserSession] = useMutation(UPDATE_END_ALL_USER_SESSION);

  const endSession = async () => {
    await endUserSession({
      variables: {
        id: sessionId,
        endTime: new Date(),
        status: MEETING_SESSION_STATUS.EXPIRED,
      },
    });
  };

  const endMeeting = async () => {
    await endAllUserSession({
      variables: {
        id: meetingData.id,
        endTime: new Date(),
        fromStatus: MEETING_SESSION_STATUS.ACTIVE,
        toStatus: MEETING_SESSION_STATUS.EXPIRED,
      },
    });
  };

  return (
    <section className="session-info-container">
      {meetingData ? (
        <React.Fragment>
          <div className="identifiers">
            <SessionType type={meetingData.type} />
            <button
              className="copy-link-trigger ease-element"
              onClick={() => clipboard.copy(`${domain}${APP_SESSION}/${meetingData.id}`)}
            >
              <span>Copy link</span>
              <Icon name="copy" />
            </button>
          </div>
          <h2>{meetingData.name}</h2>
          <p className="schedule">
            {formatDateForSession(new Date(meetingData.from_time), new Date(meetingData.to_time))}
          </p>
          <div className="actions">
            <div className="session-end-action-wrapper">
              <button className="ease-element" onClick={endMeeting}>
                End for all
              </button>
              <button onClick={endSession}>For me</button>
            </div>
            <SessionTimer from={new Date(meetingData.from_time)} to={new Date(meetingData.to_time)} />
          </div>
        </React.Fragment>
      ) : (
        <GridAnimations />
      )}
    </section>
  );
});

export default SessionInfo;
