import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useListState } from '@mantine/hooks';
import { APP_CALENDAR } from '@path';
import { storeState } from '@state';
import { MEETING_STANDARD } from '@constants';
import { PrimaryButton } from '@framework/Button';
import { DatePicker, TextField } from '@framework/Input';
import Icon from '@framework/Icon';
import Avatar from '@framework/Elements/Avatar';
import SearchUser from '@components/SearchUser';
import { diffInMinutes, isDatePast } from '@utils';
import SlotPicker from './components/SlotPicker';
import OptionSelector, { OptionSelectorItem } from './components/OptionSelector';

interface RouteProps {
  forEdit: boolean;
  meetingData?: any;
  onSubmit: Function;
}

const DURATION: OptionSelectorItem[] = [
  {
    name: (
      <div className="duration-option-item">
        <div className="title">15</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '15',
  },
  {
    name: (
      <div className="duration-option-item">
        <div className="title">30</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '30',
  },
  {
    name: (
      <div className="duration-option-item">
        <div className="title">45</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '45',
  },
  {
    name: (
      <div className="duration-option-item">
        <div className="title">60</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '60',
  },
];

const DEFAULT_MEETING_DURATION = 15;

const StandardForm: React.FC<RouteProps> = React.memo(({ forEdit, meetingData, onSubmit }): JSX.Element => {
  const [meetingType, setMeetingType] = useState<string>(MEETING_STANDARD);
  const [meetingTitle, setMeetingTitle] = useState<string>('');
  const [meetingDate, setMeetingDate] = useState<Date>(new Date());
  const [meetingDuration, setMeetingDuration] = useState<number>(DEFAULT_MEETING_DURATION);
  const [meetingStartTime, setMeetingStartTime] = useState<Date>();
  const [meetingEndTime, setMeetingEndTime] = useState<Date>();
  const [forPastDate, setForPastDate] = useState<boolean>(false);
  const [participants, participantsHandler] = useListState<any>();

  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const navigate = useNavigate();

  useEffect(() => {
    setForPastDate(isDatePast(meetingDate));
  }, [meetingDate]);

  useEffect(() => {
    participantsHandler.setState([]);
  }, [activeTeam]);

  useEffect(() => {
    if (meetingData) {
      const startTime = new Date(meetingData.from_time);
      const endTime = new Date(meetingData.to_time);
      setMeetingType(meetingData.type);
      setMeetingTitle(meetingData.name);
      setMeetingDate(new Date(meetingData.meeting_date));
      setMeetingStartTime(startTime);
      setMeetingEndTime(endTime);
      setMeetingDuration(diffInMinutes(startTime, endTime));
      participantsHandler.setState(prepareParticipants(meetingData.rel_meeting_participants));
    }
  }, [meetingData]);

  useEffect(() => {
    if (meetingDuration && meetingStartTime) {
      onStartDateTimeSelect(meetingStartTime);
    }
  }, [meetingDuration]);

  const prepareParticipants = (participants: any): any[] => {
    return participants.map(({ rel_user_detail }) => {
      const { id, name, avatar, rel_user } = rel_user_detail;
      return {
        id,
        name,
        avatar,
        email: rel_user.email,
      };
    });
  };

  const processForm = () => {
    onSubmit(meetingTitle, meetingDate, meetingStartTime, meetingEndTime, participants, null);
  };

  const addParticipant = (participant) => {
    participantsHandler.append(participant);
  };

  const removeParticipant = (participantIndex: number) => {
    participantsHandler.remove(participantIndex);
  };

  const onStartDateTimeSelect = useCallback(
    (startTime: string | Date) => {
      if (typeof startTime === 'string') {
        startTime = new Date();
      }

      const endTime = new Date(startTime);
      endTime.setMinutes(endTime.getMinutes() + meetingDuration);
      setMeetingStartTime(startTime);
      setMeetingEndTime(endTime);
    },
    [meetingDuration],
  );

  const chooseMeetingDate = (date: Date) => {
    setMeetingDate(date);
    setMeetingDuration(DEFAULT_MEETING_DURATION);
    setMeetingStartTime(null);
    setMeetingEndTime(null);
  };

  return (
    <div className="form-container">
      <p className="form-detail">
        Configure the foundational elements of your meeting here. Define its theme, set the timeline, and decide who
        joins the discussion.
      </p>
      <div className="form-wrapper">
        <div className="field-item">
          <label>Define a succinct title that encapsulates the essence of the meeting.</label>
          <div className="field-wrapper">
            <TextField placeholder="Q2 Sales Review" value={meetingTitle} onChange={setMeetingTitle} />
          </div>
        </div>
        {!activeTeam !== undefined && activeTeam !== null && (
          <React.Fragment>
            <div className="field-item">
              <label>Search and add meeting participants from your team</label>
              <div className="field-wrapper">
                <SearchUser
                  teamId={activeTeam?.id}
                  onSelect={addParticipant}
                  addedUser={participants}
                  showSelf={false}
                  disabled={activeTeam === undefined || activeTeam === null}
                />
              </div>
            </div>
            {participants && participants.length > 0 && (
              <div className="user-list-wrapper">
                {participants.map((participant, index) => (
                  <div key={index} className="participant-item">
                    <div className="remove-item-trigger">
                      <Icon name="close" onClick={() => removeParticipant(index)} />
                    </div>
                    <Avatar key={participant.id} size="sm" src={participant.avatar} name={participant.name} />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        )}
        <div className="field-item">
          <label>Determine the day when the meeting will take place.</label>
          <div className="field-wrapper">
            <DatePicker value={meetingDate} onSelect={chooseMeetingDate} />
          </div>
        </div>
        <div className="field-item">
          <label>Determine the Duration and Start Time of the Meeting.</label>
          <div className="field-wrapper multi-item-wrapper">
            <OptionSelector
              options={DURATION}
              value={meetingDuration.toString() || DEFAULT_MEETING_DURATION.toString()}
              onOptionSelect={(value) => setMeetingDuration(Number(value))}
              disabled={forPastDate}
            />
            <SlotPicker
              date={meetingDate}
              value={meetingStartTime}
              onSelect={onStartDateTimeSelect}
              disabled={forPastDate}
            />
          </div>
        </div>
      </div>
      <p className="large">
        Schedule the meeting to begin drafting the agenda. Scheduling invites participants to collaboratively shape the
        meeting's agenda for optimal productivity.
      </p>
      <div className="form-actions">
        <PrimaryButton size="md" forSecondaryAction={true} onClick={() => navigate(APP_CALENDAR)}>
          Back to Calendar
        </PrimaryButton>
        <PrimaryButton size="md" onClick={processForm}>
          {forEdit ? 'Modify' : 'Schedule'}
        </PrimaryButton>
      </div>
    </div>
  );
});

export default StandardForm;
