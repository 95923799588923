import React from 'react';
import './SessionType.scss';
import { SessionVariants } from '@types';

interface RouteProps {
  type: SessionVariants;
}

const SessionType: React.FC<RouteProps> = React.memo(({ type }): JSX.Element => {
  return <div className={`type ${type}`}>{type}</div>;
});

export default SessionType;
