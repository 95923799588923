import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useListState } from '@mantine/hooks';
import { storeState } from '@state';
import { MEETING_STANDARD, MEETING_STANDUP, MEETING_TYPES } from '@constants';
import { OptionSelectorItem } from '@views/Launchpad/components/BasicsForm/components/Form/components/OptionSelector';
import './BasicsForm.scss';
import { diffInMinutes, isDatePast } from '@utils';
import StandardForm from './components/Form/StandardForm';
import StandupForm from './components/Form/StandupForm';

interface RouteProps {
  forEdit: boolean;
  activeType: string;
  meetingData?: any;
  onTypeSelect: Function;
  onSubmit: Function;
}

const DURATION: OptionSelectorItem[] = [
  {
    name: (
      <div className="duration-option-item">
        <div className="title">15</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '15',
  },
  {
    name: (
      <div className="duration-option-item">
        <div className="title">30</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '30',
  },
  {
    name: (
      <div className="duration-option-item">
        <div className="title">45</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '45',
  },
  {
    name: (
      <div className="duration-option-item">
        <div className="title">60</div>
        <div className="sub">mins</div>
      </div>
    ),
    value: '60',
  },
];

const DEFAULT_MEETING_DURATION = 15;

const BasicsForm: React.FC<RouteProps> = React.memo(
  ({ forEdit, activeType, meetingData, onTypeSelect, onSubmit }): JSX.Element => {
    const [meetingTitle, setMeetingTitle] = useState<string>('');
    const [meetingDate, setMeetingDate] = useState<Date>(new Date());
    const [meetingDuration, setMeetingDuration] = useState<number>(DEFAULT_MEETING_DURATION);
    const [meetingStartTime, setMeetingStartTime] = useState<Date>();
    const [meetingEndTime, setMeetingEndTime] = useState<Date>();
    const [forPastDate, setForPastDate] = useState<boolean>(false);
    const [participants, participantsHandler] = useListState<any>();

    const activeTeam = storeState((state: any): any => state.user.activeTeam);

    const navigate = useNavigate();

    useEffect(() => {
      setForPastDate(isDatePast(meetingDate));
    }, [meetingDate]);

    useEffect(() => {
      participantsHandler.setState([]);
    }, [activeTeam]);

    useEffect(() => {
      if (meetingData) {
        const startTime = new Date(meetingData.from_time);
        const endTime = new Date(meetingData.to_time);
        setMeetingTitle(meetingData.name);
        setMeetingDate(new Date(meetingData.meeting_date));
        setMeetingStartTime(startTime);
        setMeetingEndTime(endTime);
        setMeetingDuration(diffInMinutes(startTime, endTime));
        participantsHandler.setState(prepareParticipants(meetingData.rel_meeting_participants));
      }
    }, [meetingData]);

    useEffect(() => {
      if (meetingDuration && meetingStartTime) {
        onStartDateTimeSelect(meetingStartTime);
      }
    }, [meetingDuration]);

    const prepareParticipants = (participants: any): any[] => {
      return participants.map(({ rel_user_detail }) => {
        const { id, name, avatar, rel_user } = rel_user_detail;
        return {
          id,
          name,
          avatar,
          email: rel_user.email,
        };
      });
    };

    const addParticipant = (participant) => {
      participantsHandler.append(participant);
    };

    const removeParticipant = (participantIndex: number) => {
      participantsHandler.remove(participantIndex);
    };

    const onStartDateTimeSelect = useCallback(
      (startTime: string | Date) => {
        if (typeof startTime === 'string') {
          startTime = new Date();
        }

        const endTime = new Date(startTime);
        endTime.setMinutes(endTime.getMinutes() + meetingDuration);
        setMeetingStartTime(startTime);
        setMeetingEndTime(endTime);
      },
      [meetingDuration],
    );

    const chooseMeetingDate = (date: Date) => {
      setMeetingDate(date);
      setMeetingDuration(DEFAULT_MEETING_DURATION);
      setMeetingStartTime(null);
      setMeetingEndTime(null);
    };

    return (
      <div className="basics-form-container">
        <div className="meeting-list-wrapper">
          <ul className="meeting-type-list">
            {MEETING_TYPES.map((meeting) => (
              <li
                key={meeting.key}
                className={`meeting-type-item ease-element ${meeting.disabled ? 'disabled' : ''} ${
                  activeType === meeting.key ? 'active' : ''
                }`}
                onClick={() => (!meeting.disabled ? onTypeSelect(meeting.key) : null)}
              >
                <h4>{meeting.title}</h4>
                <p>{meeting.description}</p>
              </li>
            ))}
          </ul>
        </div>
        {activeType === MEETING_STANDARD && (
          <StandardForm forEdit={forEdit} onSubmit={onSubmit} meetingData={meetingData} />
        )}
        {activeType === MEETING_STANDUP && (
          <StandupForm forEdit={forEdit} onSubmit={onSubmit} meetingData={meetingData} />
        )}
      </div>
    );
  },
);

export default BasicsForm;
