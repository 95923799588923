import React from 'react';
import { Loader } from '@framework/Elements';
import './PrimaryButton.scss';

interface RouteProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick: Function;
  forSecondaryAction?: boolean;
  children: any;
  flipShadow?: boolean;
}

const Primary: React.FC<RouteProps> = React.memo(
  ({
    size = 'xl',
    disabled = false,
    loading = false,
    className = '',
    onClick,
    forSecondaryAction = false,
    children,
    flipShadow = false,
  }): JSX.Element => {
    return (
      <button
        className={`primary ease-element ${size} ${className} ${forSecondaryAction ? 'secondary-action' : ''} ${
          flipShadow ? 'flip-shadow' : ''
        }`}
        disabled={disabled}
        onClick={() => onClick()}
      >
        {children}
        {loading && (
          <div className="loader-wrapper">
            <Loader size={size === 'xs' ? 'xxs' : 'xs'} negative={true} />
          </div>
        )}
      </button>
    );
  },
);

export default Primary;
