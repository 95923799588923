import React, { PropsWithChildren, Ref } from 'react';
import Menu from './Menu';
import './Toolbar.scss';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

type OrNull<T> = T | null;

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <Menu {...props} ref={ref} className="editor-toolbar-container" />
  ),
);

export default Toolbar;
