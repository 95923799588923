import React, { useEffect, useState } from 'react';
import { SUBSCRIBE_PARTICIPANTS } from '@queries';
import { useSubscription } from '@apollo/client';
import Avatar from '@framework/Elements/Avatar';
import NoRecord from '@components/NoRecord';
import './Participants.scss';

interface RouteProps {
  meetingId: string;
}

const Participants: React.FC<RouteProps> = React.memo(({ meetingId }): JSX.Element => {
  const [participants, setParticpants] = useState<any>();

  const participantsSubscription = useSubscription(SUBSCRIBE_PARTICIPANTS, {
    variables: { meetingId },
    skip: !meetingId,
  });

  useEffect(() => {
    setParticpants(participantsSubscription?.data?.meating_meeting_participants);
  }, [participantsSubscription]);

  return (
    <div className="session-participants-items-wrapper">
      <div className="participants-list">
        {participants?.map((participant) => (
          <Avatar
            key={participant.rel_user_detail.id}
            src={participant.rel_user_detail.avatar}
            name={participant.rel_user_detail.name}
          />
        ))}
        {participants?.length === 0 && <NoRecord />}
      </div>
    </div>
  );
});

export default Participants;
