export const USER_ROLES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
};

export const MINUTE_TYPES = {
  Discussion: { description: 'Captures main points and consensus reached on topics.', color: '#90e0ef' },
  Action: { description: 'Denotes tasks assigned, responsible party, and deadlines.', color: '#38b000' },
  Decision: { description: 'Documents decisions agreed upon in the meeting.', color: '#ffbe0b' },
  Information: { description: 'Covers any new or important information shared.', color: '#8338ec' },
  Question: { description: 'Records questions and answers from the meeting.', color: '#7785ac' },
  Suggestion: { description: 'Documents new ideas or suggestions and related discussions.', color: '#dddf00' },
  Issue: { description: 'Highlights problems or concerns raised and actions decided.', color: '#f2542d' },
  Meeting: { description: 'Details about meeting date, time, participants, and facilitator.', color: '#d8b4a0' },
  'Follow-Up': { description: 'Documents follow-up actions or meetings and who is responsible.', color: '#2196f3' },
  Other: { description: 'Includes any other relevant detail.', color: '#c8d5b9' },
};

export const MEETING_STANDARD = 'STANDARD';
export const MEETING_STANDUP = 'STANDUP';

export const MEETING_TYPES = [
  {
    key: MEETING_STANDARD,
    title: 'Standard Meeting',
    description:
      'A familiar and structured meeting format, enhanced with productivity features to optimize discussion and ensure efficiency.',
    disabled: false,
  },
  {
    key: MEETING_STANDUP,
    title: 'Intelligent Status Review',
    description:
      'An advanced status review tailored for periodic check-ins. Facilitates individual touchpoints, convening group sessions only when essential.',
    disabled: false,
  },
];

export const SESSION_STATE = {
  SCHEDULED: 'SCHEDULED',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
};

export const MEETING_SESSION_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
};

export const WEEKEND_DAYS = ['Sat', 'Sun'];

export const BILLING_CYCLE_MONTHLY = 'MONTHLY';
export const BILLING_CYCLE_ANNUAL = 'ANNUAL';

export const PLANS_PRICE = {
  whisper: { maxUser: 5, monthly: 0, annual: 0 },
  murmur: { maxUser: 50, monthly: 15, annual: 13.5 },
  chatter: { maxUser: 150, monthly: 12, annual: 10.8 },
  roar: { maxUser: 'Unlimited', monthly: 10, annual: 9 },
};
