import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FadeTransition from '@framework/Transition/FadeTransition';
import SessionType from '@components/Session/SessionType';
import Avatar from '@framework/Elements/Avatar';
import TabbedForm, { TabItem } from '@components/TabbedForm';
import AgendaList from '@components/Session/AgendaList';
import MinutesList from '@components/Session/MinutesList';
import './Digest.scss';
import { useQuery } from '@apollo/client';
import { SELECT_MEETING } from '@queries';
import { formatDateForSession } from '@utils';
import GridAnimations from '@components/Animations/GridAnimations';
import ensureUserSetup from '@components/User';

interface RouteProps {}

enum DIGEST_SECTION {
  AGENDA = 'agenda',
  MINUTES = 'minutes',
}

const Digest: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [meetingData, setMeetingData] = useState<any>();

  const { id } = useParams();

  const { data: meetingDetail } = useQuery(SELECT_MEETING, {
    variables: { id: id },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const tabs: TabItem[] = [
    {
      key: DIGEST_SECTION.AGENDA,
      title: 'Agenda',
      component: <AgendaList meetingId={id} readOnly={true} />,
      disabled: false,
    },
    {
      key: DIGEST_SECTION.MINUTES,
      title: 'Minutes',
      component: <MinutesList meetingId={id} readOnly={true} />,
      disabled: false,
    },
  ];

  useEffect(() => {
    const meetingData = meetingDetail?.meating_meeting[0];
    if (meetingData) {
      setMeetingData(meetingData);
    }
  }, [meetingDetail]);

  return (
    <FadeTransition>
      <div className="digest-page-container single-page-container side-padding">
        {meetingData ? (
          <React.Fragment>
            <div className="info-pane">
              <SessionType type={meetingData?.type} />
              <div className="when">
                {formatDateForSession(new Date(meetingData.from_time), new Date(meetingData.to_time))}
              </div>
              <h1>{meetingData?.name}</h1>
              {meetingData.rel_meeting_participants.length > 0 && (
                <div className="participants-container">
                  <label>PARTICIPANTS</label>
                  <div className="participants-list">
                    {meetingData.rel_meeting_participants?.map((participant) => (
                      <Avatar
                        key={participant.rel_user_detail.id}
                        size="sm"
                        src={participant.rel_user_detail.avatar}
                        name={participant.rel_user_detail.name}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="details-pane">
              <TabbedForm tabs={tabs} />
            </div>
          </React.Fragment>
        ) : (
          <GridAnimations />
        )}
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Digest);
