import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import { PrimaryButton } from '@framework/Button';
import { useNavigate } from 'react-router-dom';
import { APP_PAYMENT_SUMMARY } from '@path';
import './Cancel.scss';
import ensureUserSetup from '@components/User';

interface RouteProps {}

const Cancel: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const navigate = useNavigate();
  return (
    <FadeTransition>
      <div className="payment-cancel-page-container single-page-container side-padding">
        <h2>Looks like the payment was canceled. No worries, you can try again whenever you're ready.</h2>
        <PrimaryButton onClick={() => navigate(`${APP_PAYMENT_SUMMARY}?seats=200`)}>Retry Payment</PrimaryButton>
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Cancel);
