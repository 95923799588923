import React, { ReactNode, useEffect, useState } from 'react';
import './OptionSelector.scss';

interface SliderProps {
  options: OptionSelectorItem[];
  value?: string;
  onOptionSelect: (value: string) => void;
  disabled: boolean;
}

export interface OptionSelectorItem {
  name: string | ReactNode;
  value: string;
}

const OptionSelector: React.FC<SliderProps> = ({ options, value, onOptionSelect, disabled = false }) => {
  const [selectedValue, setSelectedValue] = useState<OptionSelectorItem>();

  useEffect(() => {
    if (selectedValue && selectedValue?.value !== value) onOptionSelect(selectedValue?.value);
  }, [selectedValue]);

  useEffect(() => {
    if (options.length > 0 && value) {
      const selectedOption = options?.find((item) => item.value === value);
      setSelectedValue(options?.find((item) => item.value === value));
    }
  }, [value, options]);

  return (
    <div className={`option-selector-container ${disabled ? 'disabled' : ''}`}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={`option-item ease-element ${selectedValue?.value === option.value ? 'active' : ''}`}
          onClick={() => (!disabled ? setSelectedValue(option) : null)}
        >
          {option.name}
        </div>
      ))}
    </div>
  );
};

export default OptionSelector;
