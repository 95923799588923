import React from 'react';
import { useAuthenticationStatus } from '@nhost/react';
import { Navigate, useLocation } from 'react-router-dom';
import PageLoader from '@components/PageLoader';
import { APP_LOGIN } from '@path';

interface RouteProps {
  children: any;
}

const ProtectedRoute: React.FC<RouteProps> = React.memo(({ children }): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const location = useLocation();

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) {
    return <Navigate to={APP_LOGIN} state={{ from: location }} replace />;
  }

  return children;
});

export default ProtectedRoute;
