import React, { useCallback, useEffect, useState } from 'react';
import { motion, Variants, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useUserEmail, useUserId } from '@nhost/react';
import { useDebouncedValue, useListState, useValidatedState } from '@mantine/hooks';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { handleEnterPress } from '@utils';
import { APP_WORKSPACES } from '@path';
import {
  DELETE_TEAM_INVITE,
  INSERT_TEAM,
  INSERT_TEAM_MEMBER,
  SELECT_PENDING_INVITES,
  SELECT_USER_DETAILS,
  INSERT_USER_NAME,
  SELECT_WORKSPACE_NAME,
} from '@queries';
import { validateElementName, validateHumanName, validateWorkspaceDomain } from '@validate';
import FadeTransition from '@framework/Transition/FadeTransition';
import { TextField } from '@framework/Input';
import { PrimaryButton } from '@framework/Button';
import { useError } from '@components/Notification';
import { useCelebration } from '@components/Animations/Celebration';
import { TeamInvite, UserRole } from '@types';
import Icon from '@framework/Icon';
import './Setup.scss';
import TabbedForm, { TabItem } from '@components/TabbedForm';

interface RouteProps {}

const SETUP_PERSONAL = 'PERSONAL';
const SETUP_WORKSPACE = 'WORKSPACE';
const SETUP_INVITES = 'INVITES';

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.6, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.3 } },
};

const Setup: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [setupState, setSetupState] = useState<string>(SETUP_PERSONAL);
  const [showInvites, setShowInvites] = useState<boolean>(false);
  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [bannerStep, setBannerStep] = useState(0);
  const [showWorkspaceStatus, setShowWorkspaceStatus] = useState<boolean>(false);
  const [isWorkspaceAvailable, setIsWorkspaceAvailable] = useState<boolean>(false);
  const [invites, invitesHandler] = useListState<any>();
  const [{ value: name, valid: isNameValid }, setName] = useValidatedState('', (val) => validateHumanName(val), true);
  const [{ value: teamName, valid: isTeamNameValid }, setTeamName] = useValidatedState(
    '',
    (val) => validateElementName(val),
    true,
  );
  const [{ value: workspace, valid: isWorkspaceValid }, setWorkspaceDomain] = useValidatedState(
    '',
    (val) => validateWorkspaceDomain(val),
    true,
  );
  const [workspaceDomain] = useDebouncedValue(workspace, 200);

  const [showConfetti] = useCelebration();
  const userId = useUserId();
  const userEmail = useUserEmail();
  const triggerError = useError();
  const navigate = useNavigate();

  const [getWorkspaceCount, { data: workspaceData }] = useLazyQuery(SELECT_WORKSPACE_NAME);

  const { data: userData, loading: userDataLoading } = useQuery(SELECT_USER_DETAILS, {
    variables: { id: userId },
    skip: !userId,
  });
  const { data: pendingInvites, loading: pendingInvitesLoading } = useQuery(SELECT_PENDING_INVITES, {
    variables: { email: userEmail },
    skip: !userEmail,
  });
  const [savePersonalDetails, { loading: savingPersonalDetails, error: personalSaveError }] =
    useMutation(INSERT_USER_NAME);
  const [saveTeamDetails, { loading: savingTeamDetails, error: workspaceSaveError }] = useMutation(INSERT_TEAM);
  const [saveTeamMember] = useMutation(INSERT_TEAM_MEMBER);
  const [deleteTeamInvite] = useMutation(DELETE_TEAM_INVITE);

  useEffect(() => {
    if (setupComplete) {
      const timers = [
        setTimeout(() => setBannerStep(1), 400),
        setTimeout(() => setBannerStep(2), 1000),
        setTimeout(() => setBannerStep(3), 1600),
        setTimeout(() => showConfetti(), 2200),
        setTimeout(() => navigate(APP_WORKSPACES), 2600),
      ];
      return () => timers.forEach((timer) => clearTimeout(timer));
    }
  }, [setupComplete]);

  useEffect(() => {
    if (workspaceData) {
      setShowWorkspaceStatus(true);
      setIsWorkspaceAvailable(workspaceData.meating_team_aggregate.aggregate.count === 0);
    }
  }, [workspaceData]);

  useEffect(() => {
    if (workspaceDomain.length >= 3) {
      validateDomain(workspaceDomain);
    } else {
      setShowWorkspaceStatus(false);
    }
  }, [workspaceDomain]);

  useEffect(() => {
    if (showInvites && invites.filter((item) => item.complete === false).length === 0) {
      setSetupState(SETUP_WORKSPACE);
    }
  }, [showInvites, invites, setSetupState]);

  useEffect(() => {
    if (
      !userDataLoading &&
      userData &&
      userData.meating_user_detail?.length > 0 &&
      userData.meating_user_detail[0].name &&
      userData.meating_user_detail[0].rel_team_members_aggregate &&
      userData.meating_user_detail[0].rel_team_members_aggregate?.aggregate.count > 0
    ) {
      navigate(APP_WORKSPACES);
    }

    if (!userData || userData.meating_user_detail?.length === 0 || !userData.meating_user_detail[0].name) {
      setSetupState(SETUP_PERSONAL);
    } else if (showInvites) {
      setSetupState(SETUP_INVITES);
    } else {
      setSetupState(SETUP_WORKSPACE);
    }
  }, [userData, userDataLoading, navigate, showInvites]);

  const validateDomain = (domain: string) => {
    getWorkspaceCount({
      variables: {
        workspace: `%${domain}%`,
      },
    });
  };

  const prepareInvites = useCallback((invites: TeamInvite[]) => {
    return invites.map((item) => ({ ...item, complete: false }));
  }, []);

  useEffect(() => {
    if (!pendingInvitesLoading && pendingInvites && pendingInvites.meating_team_invites?.length > 0) {
      setShowInvites(true);
      invitesHandler.setState(prepareInvites(pendingInvites.meating_team_invites));
    }
  }, [pendingInvites, pendingInvitesLoading, setShowInvites, prepareInvites]);

  useEffect(() => {
    if ((personalSaveError && personalSaveError.message) || (workspaceSaveError && workspaceSaveError.message)) {
      triggerError(personalSaveError.message || workspaceSaveError.message);
    }
  }, [personalSaveError, workspaceSaveError, triggerError]);

  const savePersonal = async () => {
    if (isNameValid && name !== '') {
      await savePersonalDetails({
        variables: {
          id: userId,
          name,
        },
      });
      setSetupState(showInvites ? SETUP_INVITES : SETUP_WORKSPACE);
    } else {
      triggerError('To proceed with saving, please input a valid name first.');
    }
  };

  const saveWorkspace = async () => {
    if (isTeamNameValid && teamName !== '' && isWorkspaceValid && workspace !== '' && isWorkspaceAvailable) {
      await saveTeamDetails({
        variables: {
          name: teamName,
          workspace,
          owner: userId,
        },
      });
      skipWorkspace();
    } else {
      triggerError('To proceed with saving, please input a valid name and workspace url.');
    }
  };

  const skipWorkspace = useCallback(() => {
    //setSetupComplete(true);
    navigate(APP_WORKSPACES);
  }, [setSetupComplete]);

  const rejectInvite = async (teamId: string, index: number) => {
    await deleteTeamInvite({
      variables: {
        email: userEmail,
        teamId,
      },
    });
    invitesHandler.setItemProp(index, 'complete', true);
  };

  const acceptInvite = async (teamId: string, role: UserRole, index: number) => {
    await saveTeamMember({ variables: { teamId, userId, role } });
    await deleteTeamInvite({
      variables: {
        email: userEmail,
        teamId,
      },
    });
    invitesHandler.setItemProp(index, 'complete', true);
  };

  const tabs: TabItem[] = [
    {
      key: SETUP_PERSONAL,
      title: 'Personal Details',
      component: (
        <div className="section-item">
          <TextField
            placeholder="Bruce Wayne or Batman, who are we addressing?"
            className="setup-name"
            label="How do we call you? Your identity matters to us! Let us know how you'd like to be addressed."
            value={name}
            onChange={setName}
            error={!isNameValid}
            onKeyDown={(e) => handleEnterPress(e, savePersonal, true)}
          />
          <div className="setup-actions">
            <PrimaryButton size="sm" onClick={savePersonal} loading={savingPersonalDetails}>
              Next
            </PrimaryButton>
          </div>
        </div>
      ),
      disabled: false,
    },
    showInvites && {
      key: SETUP_INVITES,
      title: 'Process Invites',
      component: (
        <div className="section-item">
          <p className="section-detail">You’ve invites from the following teams.</p>
          <div className="invites-list-container">
            {invites?.map((invite: any, index: number) => {
              return (
                <div key={invite.team_id} className="invite-item ease-element">
                  <h4>{invite.rel_team.name}</h4>
                  <AnimatePresence mode="wait">
                    {invite.complete && (
                      <motion.div
                        key={`complete-${invite.team_id}`}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={transitionVariants}
                      >
                        <Icon name="tick" className="invite-processed" />
                      </motion.div>
                    )}
                    {!invite.complete && (
                      <motion.div
                        key={`action-${invite.team_id}`}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={transitionVariants}
                        className="invite-action"
                      >
                        <PrimaryButton
                          size="xs"
                          forSecondaryAction={true}
                          onClick={() => rejectInvite(invite.team_id, index)}
                        >
                          Reject
                        </PrimaryButton>
                        <PrimaryButton size="xs" onClick={() => acceptInvite(invite.team_id, invite.role, index)}>
                          Accept
                        </PrimaryButton>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              );
            })}
          </div>
        </div>
      ),
      disabled: false,
    },
    {
      key: SETUP_WORKSPACE,
      title: 'Workspace Setup',
      component: (
        <div className="section-item">
          <p className="section-detail">Set up a team for collaborative work</p>
          <div className="field-item-container">
            <div className="field-item">
              <TextField
                placeholder="The Wayne Enterprise"
                className="setup-name"
                label="What’s your team name?"
                value={teamName}
                onChange={setTeamName}
                error={!isTeamNameValid}
              />
            </div>
            <div className="field-item">
              <label>Define your team's workspace.</label>
              <div className="workspace-name-field-container">
                <input
                  type="text"
                  placeholder="batman"
                  className="text-field"
                  onChange={(event) => setWorkspaceDomain(event.target.value)}
                />
                <label>.meating.app</label>
                {showWorkspaceStatus &&
                  (isWorkspaceAvailable ? (
                    <span className="available status">workspace name available</span>
                  ) : (
                    <span className="not-available status">workspace name not available</span>
                  ))}
              </div>
            </div>
          </div>
          <div className="setup-actions">
            {showInvites && invites?.some((item) => item.complete === true) && (
              <PrimaryButton size="sm" forSecondaryAction={true} onClick={skipWorkspace}>
                Skip for now
              </PrimaryButton>
            )}
            <PrimaryButton size="sm" onClick={saveWorkspace} loading={savingTeamDetails}>
              Next
            </PrimaryButton>
          </div>
        </div>
      ),
      disabled: false,
    },
  ];

  return (
    <FadeTransition>
      <AnimatePresence mode="wait">
        {!setupComplete && (
          <motion.div
            key="setup-form"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={transitionVariants}
            className="setup-page-container side-padding"
          >
            <div className="form-container">
              <h3 className="spray-magic">Last Stop Before Launching!</h3>
              <p>
                Alright, future meeting maestro! A few quick steps and you'll be set to redefine how meetings are done.
                Let's get your name shiny and bright, your team in the spotlight, and handle any pending invites waiting
                for you.
              </p>
              {!pendingInvitesLoading && (
                <TabbedForm className="setup-form-wrapper" active={setupState} tabs={tabs} switchOnClick={false} />
              )}
            </div>
          </motion.div>
        )}
        {setupComplete && (
          <motion.div
            key="setup-banner"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={transitionVariants}
            className="setup-complete-banner-container single-page-container side-padding"
          >
            <div>
              {bannerStep > 0 && (
                <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  Ready,{' '}
                </motion.span>
              )}
              {bannerStep > 1 && (
                <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  set,{' '}
                </motion.span>
              )}
              {bannerStep > 2 && (
                <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  meet!
                </motion.span>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </FadeTransition>
  );
});

export default Setup;
