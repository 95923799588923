import React from 'react';
import { storeState, storeActions } from '@state';
import Lottie from 'lottie-react';
import SuccessAnimation from '@lotties/celebration_confetti.json';
import './Celebration.scss';

interface RouteProps {}

const Celebration: React.FunctionComponent<RouteProps> = (): JSX.Element => {
  const showConfetti = storeState((state: any): any => state.app.showConfetti);
  const setShowConfetti = storeActions((action: any): any => action.app.setShowConfetti);

  const closeConfetti = () => {
    setShowConfetti(false);
  };

  return (
    <React.Fragment>
      {showConfetti && (
        <div className="celebration-container">
          <Lottie animationData={SuccessAnimation} onComplete={closeConfetti} loop={false} />
        </div>
      )}
    </React.Fragment>
  );
};

export function useCelebration() {
  const setShowConfetti = storeActions((action: any): any => action.app.setShowConfetti);
  const showConfetti = () => {
    setShowConfetti(true);
  };

  return [showConfetti];
}

export default Celebration;
