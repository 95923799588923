import React, { HTMLAttributes } from 'react';
import './Icon.scss';

interface RouteProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  className?: string;
}

const Icon: React.FunctionComponent<RouteProps> = ({ name, className, ...props }): JSX.Element => {
  return <div className={`icon icon-${name} ${className ? className : ''}`} {...props} />;
};

export default Icon;
