import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticationStatus } from '@nhost/react';
import { APP_LOGIN, APP_DASHBOARD } from '@path';
import Image from '@framework/Image';
import MoneyRollIllustration from '@images/dollar-roll.png';
import FadeTransition from '@framework/Transition/FadeTransition';
import { PrimaryButton } from '@framework/Button';
import './Home.scss';

interface RouteProps {}

const Home: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const { isAuthenticated } = useAuthenticationStatus();
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate(APP_LOGIN);
  };

  if (isAuthenticated) {
    return <Navigate to={APP_DASHBOARD} state={{ from: location }} replace />;
  }

  return (
    <FadeTransition>
      <div className="home-page-container side-padding">
        <section className="home-banner-container">
          <h1>
            <span className="scratch-through">Endless</span> Effective Meetings!
          </h1>
          <p>
            At Meating, we trim away the unnecessary and concentrate on the core. Every call becomes an opportunity for
            clearer communication, moving away from old habits and embracing a more focused approach. It's not just
            about meetings, but making them matter!
          </p>
          <PrimaryButton onClick={navigateToLogin} flipShadow={true}>
            Try it out!
          </PrimaryButton>
        </section>
        <section className="home-problems-container">
          <div className="section-title">
            <h4>Minor Missteps or</h4>
            <h2 className="spray-warn">Massive Misuse?</h2>
          </div>
          <div className="stats-box">
            <div className="stats-list-box">
              <div className="stat-item">
                <h4>$37 Billion</h4>
                <p>The estimated annual cost of unproductive meetings for U.S. businesses.</p>
              </div>
              <div className="stat-item">
                <h4>300,000 Hours</h4>
                <p>
                  Within a distinguished company, this staggering sum is dedicated annually by employees to just one
                  weekly executive committee meeting.
                </p>
              </div>
              <div className="stat-item">
                <h4>73%</h4>
                <p>Percentage of people who admit to doing other work in meetings due to lack of engagement.</p>
              </div>
              <div className="stat-item">
                <h4>33%</h4>
                <p>
                  Percentage of employees who find themselves feeling exhausted after virtual meetings, emphasizing the
                  mental strain they cause.
                </p>
              </div>
            </div>
            <div className="stats-list-box">
              <div className="stat-item">
                <h4>$400 to $580</h4>
                <p>
                  Estimated cost per hour of executive meetings, meaning each late-start minute costs $6.67 to $9.67.
                </p>
              </div>
              <div className="stat-item">
                <h4>66%</h4>
                <p>Percentage of meetings that start late, often waiting for attendees, leading to wasted time.</p>
              </div>
              <div className="stat-item">
                <h4>50%</h4>
                <p>
                  Percentage of meetings considered as time-wasted due to a lack of follow-up or action items, leading
                  to significant revenue losses.
                </p>
              </div>
              <div className="stat-item">
                <h4>90%</h4>
                <p>
                  Professionals who admit to daydreaming during meetings, signifying lack of engagement and potential
                  waste.
                </p>
              </div>
            </div>
            <Image
              src={MoneyRollIllustration}
              alt="The current legeacy meeting habits results in a huge loss for the company"
              className="money-roll-illustration"
            />
          </div>
          <p className="problem-detail">
            These aren't just numbers. They are a reflection of collective hours, efforts, and resources lost in the
            maze of unproductive meetings. Every wasted meeting minute costs dollars.
          </p>
          <p className="problem-disclaimer-note">
            * While we reference data from renowned sources, we've opted not to display specific logos or brand names to
            respect intellectual property rights. We're in talks with these entities for proper citation permissions,
            and until then, specific source details will be withheld. Thanks for understanding!
          </p>
        </section>
        <section className="home-solution-container">
          <div className="section-title">
            <h4>Turning the Tables</h4>
            <h2 className="spray-magic">From Misuse to Mastery</h2>
          </div>
          <p className="solution-description">
            With <span className="replace-with-meating-logo-inline">meating</span>, we've listened to the silent screams
            from cluttered calendars and the sighs of back-to-back video calls. We're here not just to offer another
            tool, but to redefine the meeting experience.
          </p>
          <div className="details-box">
            <div className="detail-item">
              <div className="index">01</div>
              <h3>Automated, Not Robotic</h3>
              <p>
                Imagine if meetings were streamlined - less redundancy, more efficiency. We're using automation to cut
                out the unnecessary fluff. Our aim? Meetings that feel productive, not exhausting.
              </p>
            </div>
            <div className="detail-item">
              <div className="index">02</div>
              <h3>A Palette of Meeting Types</h3>
              <p>
                Imagine if meetings were streamlined - less redundancy, more efficiency. We're using automation to cut
                out the unnecessary fluff. Our aim? Meetings that feel productive, not exhausting.
              </p>
            </div>
            <div className="detail-item">
              <div className="index">03</div>
              <h3>Heartbeat of Meetings - Agendas & MoM</h3>
              <p>
                Imagine if meetings were streamlined - less redundancy, more efficiency. We're using automation to cut
                out the unnecessary fluff. Our aim? Meetings that feel productive, not exhausting.
              </p>
            </div>
            <div className="detail-item">
              <div className="index">04</div>
              <h3>Punctuality’s Secret Weapon</h3>
              <p>
                Imagine if meetings were streamlined - less redundancy, more efficiency. We're using automation to cut
                out the unnecessary fluff. Our aim? Meetings that feel productive, not exhausting.
              </p>
            </div>
          </div>
          <div className="probe-section">
            <p>Keep hearing</p>
            <h3>"Sorry, I missed that. Could you say it again?"</h3>
            <p>when posed a question in your meetings? Consider giving us a chance.</p>
            <PrimaryButton onClick={navigateToLogin} flipShadow={true} className="probe-trigger">
              Jump In
            </PrimaryButton>
          </div>
          <p className="solution-text">
            Together, let's turn those countless lost hours into moments of productivity and purpose.
          </p>
          <div className="solution-thanks-ribbon">Thank you for trusting us with the first step.</div>
        </section>
      </div>
    </FadeTransition>
  );
});

export default Home;
