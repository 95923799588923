import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton } from '@framework/Button';
import './SessionOutro.scss';
import { useNavigate } from 'react-router-dom';
import { APP_CALENDAR } from '@path';

interface RouteProps {}

const SessionOutro: React.FC<RouteProps> = React.memo(({}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="session-outro-container single-page-container side-padding">
      <div className="title spray-magic">That's a wrap</div>
      <p>Meeting concluded successfully. Jump to the calendar to check your upcoming meetings.</p>
      <div className="actions">
        <PrimaryButton onClick={() => navigate(APP_CALENDAR)}>Calendar</PrimaryButton>
      </div>
    </div>
  );
});

export default SessionOutro;
