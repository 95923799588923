import { AnimatePresence } from 'framer-motion';
import { Route, Routes as Switch } from 'react-router-dom';
import {
  APP_HOME,
  APP_LOGIN,
  APP_LOGOUT,
  APP_ERROR,
  APP_SETUP,
  APP_DASHBOARD,
  APP_CALENDAR,
  APP_LAUNCHPAD,
  APP_SESSION,
  APP_ACTIVITY,
  APP_DIGEST,
  APP_SETTINGS,
  APP_PRICING,
  APP_TERMS,
  APP_PRIVACY,
  APP_REFUND,
  APP_CONTACT,
  APP_BECOME_PRO,
  APP_WORKSPACES,
  APP_PAYMENT_SUMMARY,
  APP_PAYMENT_SUCCESS,
  APP_PAYMENT_CANCEL,
} from '@path';
import ProtectedRoute from '@components/Auth/ProtectedRoute';
import Home from '@views/Home';
import Login from '@views/Login';
import LogOut from '@views/LogOut';
import Setup from '@views/Setup';
import Dashboard from '@views/Dashboard';
import Calendar from '@views/Calendar';
import Launchpad from '@views/Launchpad';
import Session from '@views/Session';
import Activity from '@views/Activity';
import Digest from '@views/Digest';
import NotFound from '@views/NotFound';
import Settings from '@views/Settings';
import Pricing from '@views/Pricing';
import Terms from '@views/Terms';
import Privacy from '@views/Privacy';
import Refund from '@views/Refund';
import Contact from '@views/Contact';
import WorkspaceSwitcher from '@views/Workspaces';
import PaymentSummary from '@views/Payment/Summary';
import PaymentSuccess from '@views/Payment/Success';
import PaymentCancel from '@views/Payment/Cancel';
import Error from '@views/Error';

const Routes = () => {
  return (
    <AnimatePresence mode="wait" initial={true}>
      <Switch>
        <Route path={APP_HOME} element={<Home />} />
        <Route path={APP_LOGIN} element={<Login />} />
        <Route path={APP_PRICING} element={<Pricing />} />
        <Route path={APP_TERMS} element={<Terms />} />
        <Route path={APP_PRIVACY} element={<Privacy />} />
        <Route path={APP_REFUND} element={<Refund />} />
        <Route path={APP_CONTACT} element={<Contact />} />
        <Route path={APP_ERROR} element={<Error />} />
        <Route
          path={APP_LOGOUT}
          element={
            <ProtectedRoute>
              <LogOut />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_SETUP}
          element={
            <ProtectedRoute>
              <Setup />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_LAUNCHPAD}
          element={
            <ProtectedRoute>
              <Launchpad />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_WORKSPACES}
          element={
            <ProtectedRoute>
              <WorkspaceSwitcher />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_BECOME_PRO}
          element={
            <ProtectedRoute>
              <Pricing />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_DASHBOARD}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_CALENDAR}
          element={
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_CALENDAR}/:id`}
          element={
            <ProtectedRoute>
              <Launchpad />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_SESSION}/:id`}
          element={
            <ProtectedRoute>
              <Session />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_ACTIVITY}/:id`}
          element={
            <ProtectedRoute>
              <Activity />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_DIGEST}/:id`}
          element={
            <ProtectedRoute>
              <Digest />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_SETTINGS}`}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_PAYMENT_SUMMARY}`}
          element={
            <ProtectedRoute>
              <PaymentSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_PAYMENT_SUCCESS}`}
          element={
            <ProtectedRoute>
              <PaymentSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${APP_PAYMENT_CANCEL}`}
          element={
            <ProtectedRoute>
              <PaymentCancel />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Switch>
    </AnimatePresence>
  );
};

export default Routes;
