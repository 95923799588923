import React, { useCallback, useEffect, useState } from 'react';
import Avatar from '@framework/Elements/Avatar';
import { diffInMinutesAndSeconds, padWithZeros } from '@utils';
import TimerSlider from './TimerSlider';
import { PrimaryButton } from '@framework/Button';
import { useMutation } from '@apollo/client';
import { UPDATE_COMPLETE_AGENDA, UPDATE_START_AGENDA } from '@queries';
import './AgendaItem.scss';

interface RouteProps {
  data: any;
  readOnly?: boolean;
  canStart?: boolean;
}

const AgendaItem: React.FC<RouteProps> = React.memo(({ data, readOnly = false, canStart = false }): JSX.Element => {
  const [timeTaken, setTimeTaken] = useState<string>('');

  const [startAgenda] = useMutation(UPDATE_START_AGENDA);
  const [completeAgenda] = useMutation(UPDATE_COMPLETE_AGENDA);

  const processData = useCallback((data) => {
    if (data.complete) {
      setTimeTaken(diffInMinutesAndSeconds(data.start_at, data.end_at));
    }
  }, []);

  useEffect(() => {
    processData(data);
  }, [data, processData]);

  const startItem = async () => {
    await startAgenda({
      variables: {
        id: data.id,
        startAt: new Date(),
      },
    });
  };

  const completeItem = async () => {
    await completeAgenda({
      variables: {
        id: data.id,
        endAt: new Date(),
      },
    });
  };

  return (
    <div
      className={`agenda-item-container ease-element ${data.complete ? 'complete' : ''} ${
        data.active ? 'active' : ''
      } ${readOnly || canStart || data.active ? 'available' : ''}`}
    >
      <div className="agenda-info">
        <div className="info-box">
          {data.lead && (
            <React.Fragment>
              <Avatar size="xs" src={data.rel_user_detail.avatar} name={data.rel_user_detail.name} />
              <span className="lead">{data.rel_user_detail.name}</span>
            </React.Fragment>
          )}
          {!data.complete && <span className="duration">{padWithZeros(data.duration, 2)} mins</span>}
          {data.complete && (
            <span className={`time-taken ${timeTaken > data.duration ? 'breached' : ''}`}>
              Wrapped in {timeTaken} mins
            </span>
          )}
        </div>
        <div className="action-box">
          {!readOnly && !data.complete && canStart && (
            <PrimaryButton size="xs" onClick={startItem}>
              Start
            </PrimaryButton>
          )}
          {!readOnly && data.active && (
            <PrimaryButton size="xs" onClick={completeItem}>
              Close
            </PrimaryButton>
          )}
        </div>
      </div>
      <h4>{data.title}</h4>
      <p>{data.detail}</p>
      {!readOnly && data.active && <TimerSlider startTime={new Date(data.start_at)} duration={data.duration} />}
    </div>
  );
});

export default AgendaItem;
