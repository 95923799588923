import React from 'react';
import './GridAnimations.scss';

interface RouteProps {
  numOfElements?: number;
}

const GridAnimations: React.FC<RouteProps> = React.memo(({ numOfElements = 1 }): JSX.Element => {
  return (
    <div className="loading-container">
      {Array.from({ length: numOfElements }).map((_, index) => (
        <div key={index} className="loading-section">
          <div className="loading-block title"></div>
          <div className="loading-block label"></div>
          <div className="loading-block field"></div>
          <div className="loading-block field"></div>
          <div className="loading-block button"></div>
        </div>
      ))}
    </div>
  );
});

export default GridAnimations;
