import React, { useCallback, useEffect, useState } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useClickOutside } from '@mantine/hooks';
import { isDateFuture, isDatePast } from '@utils';
import './SlotPicker.scss';

interface RouteProps {
  date: Date;
  value: Date;
  onSelect: Function;
  disabled?: boolean;
}

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.15 } },
};

const NOW = 'Now';

const SlotPicker: React.FC<RouteProps> = React.memo(({ date, value, onSelect, disabled = false }): JSX.Element => {
  const [options, setOptions] = useState<Date[]>();
  const [selectedValue, setSelectedValue] = useState<any>(NOW);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [forPastDate, setForPastDate] = useState<boolean>(false);

  useEffect(() => {
    setForPastDate(isDatePast(date));
  }, [date]);

  useEffect(() => {
    setSelectedValue(value ? value : isDateFuture(date) ? formatValue(options[0]) : NOW);
  }, [value, options]);

  const prepareOptions = useCallback(
    (date: Date) => {
      const dateToUse = date.getDate() === new Date().getDate() ? new Date() : date;

      // Round up minutes to the nearest quarter-hour
      let minutes = Math.ceil(dateToUse.getMinutes() / 15) * 15;
      let hours = dateToUse.getHours();

      if (date.getDate() !== new Date().getDate()) {
        minutes = 0;
        hours = 0;
      }

      if (minutes >= 60) {
        minutes = 0;
        hours += 1;
      }

      const slots: Date[] = [new Date(dateToUse)]; // Start with 'Now' if today or '00:00' if another day

      while (hours < 24) {
        slots.push(new Date(dateToUse.getFullYear(), dateToUse.getMonth(), dateToUse.getDate(), hours, minutes));
        minutes += 15;
        if (minutes >= 60) {
          minutes = 0;
          hours += 1;
        }
      }

      setOptions(slots);
    },
    [date],
  );

  useEffect(() => {
    prepareOptions(date);
  }, [date]);

  const closePicker = () => {
    setShowPicker(false);
  };

  const ref = useClickOutside(closePicker);

  const selectValue = (value) => {
    setSelectedValue(value);
    onSelect(value);
    closePicker();
  };

  const formatValue = (date: Date) => {
    return (
      (date.getHours() === 24 ? '00' : date.getHours().toString().padStart(2, '0')) +
      ':' +
      date.getMinutes().toString().padStart(2, '0')
    );
  };

  return (
    <div className={`slot-selector ${forPastDate || disabled ? 'disabled' : ''}`}>
      <div className="selected-value" onClick={() => (!forPastDate || !disabled ? setShowPicker(true) : null)}>
        {selectedValue && (typeof selectedValue === 'string' ? selectedValue : formatValue(selectedValue))}
      </div>
      <AnimatePresence mode="wait">
        {showPicker && (
          <motion.div
            ref={ref}
            className="options-list"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={transitionVariants}
          >
            {options?.map((option: Date, index) => {
              if (!(isDateFuture(date) && index === 0)) {
                return (
                  <div
                    key={index}
                    className={`option-item ease-element ${selectedValue === option ? 'active' : ''}`}
                    onClick={() => selectValue(index === 0 ? NOW : option)}
                  >
                    {index === 0 ? NOW : formatValue(option)}
                  </div>
                );
              }
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default SlotPicker;
