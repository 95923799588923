import React, { useState } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useClickOutside } from '@mantine/hooks';
import { SelectOption } from '@types';
import './RecurringDurationPicker.scss';

interface RouteProps {
  value: string;
  onSelect: Function;
}

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.15 } },
};

const options: SelectOption[] = [
  { name: 'Two weeks', value: '2_WEEKS' },
  { name: 'One month', value: '1_MONTH' },
];

const RecurringDurationPicker: React.FC<RouteProps> = React.memo(({ value, onSelect }): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const closePicker = () => {
    setShowPicker(false);
  };

  const ref = useClickOutside(closePicker);

  const selectValue = (value) => {
    setSelectedValue(value);
    onSelect(value);
    closePicker();
  };

  return (
    <div className="duration-selector">
      <div className="selected-value" onClick={() => setShowPicker(true)}>
        {selectedValue ? options?.find((item) => item.value === selectedValue).name : 'Set the duration'}
      </div>
      <AnimatePresence mode="wait">
        {showPicker && (
          <motion.div
            ref={ref}
            className="options-list"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={transitionVariants}
          >
            {options?.map((option: SelectOption, index) => (
              <div
                key={index}
                className={`option-item ease-element ${selectedValue === option ? 'active' : ''}`}
                onClick={() => selectValue(option.value)}
              >
                {option.name}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default RecurringDurationPicker;
