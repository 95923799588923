import React, { useCallback, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import FadeTransition from '@framework/Transition/FadeTransition';
import { TextField } from '@framework/Input';
import { PrimaryButton } from '@framework/Button';
import './Summary.scss';
import { CREATE_CHECKOUT_SESSION, INSERT_TEAM_INVOICE } from '@queries';
import { useMutation } from '@apollo/client';
import { BILLING_CYCLE_ANNUAL, BILLING_CYCLE_MONTHLY, PLANS_PRICE } from '@constants';
import { useLocation } from 'react-router-dom';
import { storeState } from '@state';
import { capitalize } from '@utils';
import { APP_PAYMENT_CANCEL, APP_PAYMENT_SUCCESS } from '@path';
import ensureUserSetup from '@components/User';

interface RouteProps {}

const Summary: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [seats, setSeats] = useState<any>(0);
  const [cycle, setCycle] = useState<any>(BILLING_CYCLE_MONTHLY);
  const [estimate, setEstimate] = useState<number>(0.0);
  const [payable, setPayable] = useState<number>(0.0);

  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const useQueryParam = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQueryParam();
  const seatCount = query.get('seats');

  useEffect(() => {
    setSeats(seatCount);
  }, [seatCount, setSeats]);

  const calculateCost = useCallback((seats, cycle) => {
    let selectedPlan;
    let planName;

    if (seats <= PLANS_PRICE.whisper.maxUser) {
      selectedPlan = PLANS_PRICE.whisper;
    } else if (seats <= PLANS_PRICE.murmur.maxUser) {
      selectedPlan = PLANS_PRICE.murmur;
    } else if (seats <= PLANS_PRICE.chatter.maxUser) {
      selectedPlan = PLANS_PRICE.chatter;
    } else {
      selectedPlan = PLANS_PRICE.roar;
    }
    const factor = cycle === BILLING_CYCLE_ANNUAL ? 12 : 1;
    const estimate = selectedPlan[cycle.toLowerCase()] * seats * factor;
    const payable = estimate;

    setEstimate(estimate);
    setPayable(payable);
  }, []);

  useEffect(() => {
    calculateCost(seats, cycle);
  }, [seats, cycle, calculateCost]);

  const [createCheckoutSession] = useMutation(CREATE_CHECKOUT_SESSION);
  const [insertInvoice] = useMutation(INSERT_TEAM_INVOICE);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const getAppUrl = () => {
    // Destructure the necessary properties from the location object
    const { protocol, hostname, port } = window.location;

    // If you have specific cases to handle regarding the port, you can add conditions here
    const portSegment = port ? `:${port}` : '';

    // Construct the URL
    const appUrl = `${protocol}//${hostname}${portSegment}`;

    return appUrl;
  };

  const proceedToPay = async () => {
    const stripe = await stripePromise;
    const appUrl = getAppUrl();

    // Fetch session ID from your API
    const response = await createCheckoutSession({
      variables: {
        cycle,
        seats: Number(seats),
        success_url: `${appUrl}${APP_PAYMENT_SUCCESS}`,
        cancel_url: `${appUrl}${APP_PAYMENT_CANCEL}`,
      },
    });
    const sessionId = response?.data?.createCheckoutSession?.sessionId;

    if (!sessionId) {
      //throw error and abort flow
    }

    await insertInvoice({
      variables: {
        teamId: activeTeam?.id,
        cycle,
        estimate,
        payable,
        seats,
        stripeSessionId: sessionId,
      },
    });

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({ sessionId });

    if (result.error) {
      // handle error
    }
  };

  return (
    <FadeTransition>
      <Elements stripe={stripePromise}>
        <div className="billing-summary-page-container single-page-container side-padding">
          <div className="summary-box">
            <h2 className="spray-magic">You're Almost a Pro!</h2>
            <div className="summary-details-container">
              <div className="preference-container">
                <div className="preference-box">
                  <h3>Your Preference</h3>
                  <div className="field-box">
                    <TextField
                      placeholder="300"
                      value={seats}
                      onChange={setSeats}
                      label="How many seats do you want to reserve?"
                    />
                  </div>
                </div>
                <div className="terms-box">
                  <h3>Quick Reminders Before Checkout!</h3>
                  <ul>
                    <li>Reserved seats are final and cannot be canceled.</li>
                    <li>You may upgrade from monthly to annual billing; reversing is not possible.</li>
                    <li>
                      Seats are reserved in full cycles only. Need more seats urgently before your next cycle? Reach us
                      at support@meating.app.
                    </li>
                    <li>
                      If a new reservation pushes your total seats into a lower pricing plan during the same cycle,
                      applicable refunds will be processed.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="summary-container">
                <h3>Bill Summary</h3>
                <div className="data-box">
                  <div className="item-list">
                    <div className="item-row">
                      <label>Seats being reserved</label>
                      <p>{seats}</p>
                    </div>
                    <div className="item-row">
                      <label>Billing cycle</label>
                      <p>{capitalize(cycle)}</p>
                    </div>
                    <div className="item-row">
                      <label>Cost estimate</label>
                      <p>${estimate}</p>
                    </div>
                    <div className="item-row">
                      <label>Amount to pay</label>
                      <p>${payable}</p>
                    </div>
                  </div>
                </div>
                <PrimaryButton size="md" onClick={proceedToPay} loading={false} className="payment-trigger">
                  Proceed to Pay
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Elements>
    </FadeTransition>
  );
});

export default ensureUserSetup(Summary);
