import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import './TabbedForm.scss';

interface RouteProps {
  active?: string;
  switchOnClick?: boolean;
  tabs: TabItem[];
  className?: string;
}

export interface TabItem {
  key: string;
  title: string | ReactNode;
  component: ReactNode;
  disabled: boolean;
}

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.6, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.3 } },
};

const TabbedForm: React.FC<RouteProps> = React.memo(
  ({ active, tabs, switchOnClick = true, className }): JSX.Element => {
    const [activeTab, setActiveTab] = useState<string>('');

    const initialProcessing = useCallback(() => {
      if (tabs && tabs.length > 0) {
        if (active) {
          setActiveTab(active);
        } else {
          const firstTab = tabs[0];
          setActiveTab(firstTab.key);
        }
      }
    }, [tabs, active]);

    useEffect(() => {
      initialProcessing();
    }, [active, initialProcessing]);

    const navigateToSection = (key: string) => {
      if (switchOnClick) {
        setActiveTab(key);
      }
    };

    return (
      <div className={`tab-form-container ${className ? className : ''}`}>
        <div className="tab-trigger-container">
          <nav>
            <ul>
              {tabs?.map((tab) => (
                <li
                  key={`tab-button-${tab.key}`}
                  className={`ease-element ${tab.key === activeTab ? `active` : ''} ${tab.disabled ? 'disabled' : ''} ${
                    !switchOnClick ? 'inactive' : ''
                  }`}
                  onClick={() => (!tab.disabled ? navigateToSection(tab.key) : null)}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="tab-section-container">
          <AnimatePresence mode="wait">
            {tabs?.map(
              (tab) =>
                activeTab === tab.key && (
                  <motion.section
                    key={`tab-element-${tab.key}`}
                    className="tab-data-item"
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={transitionVariants}
                  >
                    {tab.component}
                  </motion.section>
                ),
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  },
);

export default TabbedForm;
