import React, { PropsWithChildren, Ref } from 'react';
import './Button.scss';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

type OrNull<T> = T | null;

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLDivElement>>,
  ) => <div {...props} ref={ref} className={`editor-button ease-element ${active ? 'active' : ''}`} />,
);

export default Button;
