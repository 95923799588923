import React, { useCallback, useMemo } from 'react';
import { Editable, Slate, withReact } from 'slate-react';
import Element from './components/Element';
import Leaf from './components/Leaf';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';
import './Editor.scss';

interface RouteProps {
  value: any;
}

const deserialize = (string) => {
  return JSON.parse(string);
};

const RichTextView: React.FC<RouteProps> = React.memo(({ value }): JSX.Element => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <div className="rte-container">
      <Slate editor={editor} initialValue={deserialize(value)}>
        <Editable readOnly={true} renderElement={renderElement} renderLeaf={renderLeaf} />
      </Slate>
    </div>
  );
});

export default RichTextView;
