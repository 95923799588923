import React from 'react';
import './LeftPane.scss';
import { useNavigate } from 'react-router-dom';
import { APP_CONTACT, APP_PRIVACY, APP_REFUND, APP_TERMS } from '@path';

interface RouteProps {}

const LeftPane: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const navigate = useNavigate();

  const navigateToView = (path: string) => {
    navigate(path);
  };

  return (
    <div className="footer-pane">
      <nav>
        <ul>
          <li onClick={() => navigateToView(APP_TERMS)}>Terms</li>
          <li onClick={() => navigateToView(APP_PRIVACY)}>Privacy</li>
          <li onClick={() => navigateToView(APP_REFUND)}>Refund</li>
          <li onClick={() => navigateToView(APP_CONTACT)}>Contact</li>
        </ul>
      </nav>
      <span className="copyright">Copyright © 2023 Foolish Works</span>
    </div>
  );
});

export default LeftPane;
