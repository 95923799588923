import React, { useEffect, useState } from 'react';
import mobile from 'is-mobile';
import { useAuthenticated, useUserId } from '@nhost/react';
import { storeState } from '@state';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_BECOME_PRO, APP_CALENDAR, APP_ERROR, APP_SETUP } from '@path';
import Avatar from '@framework/Elements/Avatar';
import Icon from '@framework/Icon';
import MobileMenu from '../MobileMenu';
import { AnimatePresence } from 'framer-motion';
import './RightPane.scss';
import UserContextMenu from '../UserContextMenu';
import { PLANS_PRICE, USER_ROLES } from '@constants';

interface RouteProps {}

const HIDE_MENU_ON_ROUTES = [APP_SETUP, APP_ERROR];

const RightPane: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [showUserContextMenu, setShowUserContextMenu] = useState<boolean>(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  const self = storeState((state: any): any => state.user.self);
  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useAuthenticated();
  const isMobile = mobile();

  const hideMenuForRoutes = (path: string): boolean => {
    return HIDE_MENU_ON_ROUTES.includes(path);
  };

  return (
    <div className="header-pane header-actions-pane">
      {isAuthenticated &&
        self &&
        !hideMenuForRoutes(location.pathname) &&
        (isMobile ? (
          <Icon name="mobile-menu" className="mobile-menu-trigger" onClick={() => setShowMobileMenu(true)} />
        ) : (
          <React.Fragment>
            <nav>
              <ul>
                <li onClick={() => navigate(APP_CALENDAR)}>Calendar</li>
                {activeTeam?.role === USER_ROLES.ADMIN &&
                  activeTeam?.permitted_users === PLANS_PRICE.whisper.maxUser && (
                    <li onClick={() => navigate(APP_BECOME_PRO)}>Become Pro</li>
                  )}
              </ul>
            </nav>
            <div className="user-avatar-wrapper">
              <Avatar
                src={self.avatar || ''}
                name={self.name || 'user-avatar'}
                className="ease-element"
                showNameOnTooltip={false}
                onClick={() => setShowUserContextMenu(true)}
              />
              <AnimatePresence mode="wait">
                {!isMobile && isAuthenticated && showUserContextMenu && (
                  <UserContextMenu onClose={() => setShowUserContextMenu(false)} />
                )}
              </AnimatePresence>
            </div>
          </React.Fragment>
        ))}
      <AnimatePresence mode="wait">
        {isMobile && isAuthenticated && showMobileMenu && <MobileMenu onClose={() => setShowMobileMenu(false)} />}
      </AnimatePresence>
    </div>
  );
});

export default RightPane;
