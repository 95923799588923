import React from 'react';
import Avatar from '@framework/Elements/Avatar';
import './TaskItem.scss';

interface RouteProps {
  data: any;
}

const TaskItem: React.FC<RouteProps> = React.memo(({ data }): JSX.Element => {
  return (
    <div className="task-item-container ease-element">
      <div className="task-info">
        <div className="info-box">
          {data.assigned_to && (
            <React.Fragment>
              <Avatar size="xs" src={data.rel_user_detail.avatar} name={data.rel_user_detail.name} />
              <span className="lead">{data.rel_user_detail.name}</span>
            </React.Fragment>
          )}
        </div>
      </div>
      <h4>{data.title}</h4>
      <p>{data.description}</p>
    </div>
  );
});

export default TaskItem;
