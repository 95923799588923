import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Terms.scss';

interface RouteProps {}

const Terms: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <FadeTransition>
      <div className="terms-page-container side-padding">
        <h1>Terms and Conditions</h1>

        <p>Last updated: 28-Oct-23</p>
        <p>
          Thank you for choosing Meating.app. These Terms and Conditions ("Terms") govern your use of the Meating.app
          website and all related services, including mobile applications, provided by Foolish Works Technologies Pvt.
          Ltd. ("we," "us," "our," "Meating.app"). By using our Services, you agree to these Terms. If you disagree with
          any part of the terms, you may not access the service.
        </p>

        <h2>1. Accounts</h2>
        <p>
          When you create an account with us, you guarantee that the information you provide us is accurate, complete,
          and current. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your
          account on Service.
        </p>

        <h2>2. Intellectual Property</h2>
        <p>
          The Service and its original content, features, and functionality are and will remain the exclusive property
          of Foolish Works Technologies Pvt. Ltd. and its licensors. Our Service is protected by copyright, trademark,
          and other laws of both India and foreign countries.
        </p>

        <h2>3. Termination</h2>
        <p>
          We may terminate or suspend your account and bar access to Service immediately, without prior notice or
          liability, under our sole discretion, for any reason whatsoever and without limitation, including but not
          limited to a breach of Terms.
        </p>

        <h2>4. Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold harmless Foolish Works Technologies Pvt. Ltd., its licensee and
          licensors, and their employees, contractors, agents, officers, and directors, from and against any and all
          claims, damages, obligations, losses, liabilities, costs or debt, and expenses, resulting from or arising out
          of a) your use and access of Service, by you or any person using your account and password; b) a breach of
          these Terms, or c) Content posted on the Service.
        </p>

        <h2>5. Limitation of Liability</h2>
        <p>
          In no event shall Foolish Works Technologies Pvt. Ltd., nor its directors, employees, partners, agents,
          suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from
          (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any
          third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or
          alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence),
          or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if
          a remedy set forth herein is found to have failed of its essential purpose.
        </p>

        <h2>6. Disclaimer</h2>
        <p>
          Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis.
          The Service is provided without warranties of any kind, whether express or implied, including, but not limited
          to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of
          performance.
        </p>

        <h2>7. Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the laws of Tamil Nadu, India, without regard
          to its conflict of law provisions.
        </p>

        <h2>8. Changes</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
          material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a
          material change will be determined at our sole discretion.
        </p>

        <h2>Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <address>
          Foolish Works Technologies Pvt. Ltd.
          <br />
          42/3, Kallankadu Pudur, Elur PO, Namakkal, Tamil Nadu - 637018, Chennai,
          <br />
          Tamil Nadu, India
          <br />
          GSTIN: 33AAECF4842Q1Z9
          <br />
          PAN: AAECF4842Q
          <br />
        </address>
      </div>
    </FadeTransition>
  );
});

export default Terms;
