import { Action, action } from 'easy-peasy';
import { SelfDetail, Team } from '@types';

export interface UserModel {
  self?: SelfDetail;
  teams?: Team[];
  activeTeam?: Team;
  setSelf: Action<UserModel, SelfDetail>;
  setTeams: Action<UserModel, Team[]>;
  setActiveTeam: Action<UserModel, Team>;
}

const userModel: UserModel = {
  setSelf: action((state, payload) => {
    state.self = payload;
  }),
  setTeams: action((state, payload) => {
    state.teams = payload;
  }),
  setActiveTeam: action((state, payload) => {
    state.activeTeam = payload;
  }),
};

export default userModel;
