import React, { useEffect } from 'react';
import { useDyteClient } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';
import './VideoCallInterface.scss';

interface RouteProps {
  authToken: string;
}

const VideoCallInterface: React.FC<RouteProps> = React.memo(({ authToken }): JSX.Element => {
  const [meeting, initMeeting] = useDyteClient();

  useEffect(() => {
    if (authToken) {
      initMeeting({
        authToken: authToken,
        defaults: {
          audio: false,
          video: false,
        },
      });
    }
  }, [authToken]);

  return (
    <div className="session-video-container">
      {meeting ? <DyteMeeting meeting={meeting} mode="fill" size="sm" /> : 'Preparing video call'}
    </div>
  );
});

export default VideoCallInterface;
