import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Contact.scss';

interface RouteProps {}

const Contact: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <FadeTransition>
      <div className="contact-page-container single-page-container side-padding">
        <p>drop us a mail to</p>
        <h3>support@meating.app</h3>
      </div>
    </FadeTransition>
  );
});

export default Contact;
