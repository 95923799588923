import React, { useCallback, useEffect, useState } from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Pricing.scss';
import { PrimaryButton } from '@framework/Button';
import Switch from './components/Switch';
import { useDisclosure, useHover, useScrollIntoView } from '@mantine/hooks';
import { useAuthenticated } from '@nhost/react';
import { useNavigate } from 'react-router-dom';
import { APP_LOGIN, APP_PAYMENT_SUMMARY } from '@path';
import { PLANS_PRICE } from '@constants';

interface RouteProps {}

const PLAN_WHISPER = 'Whisper';
const PLAN_MURMUR = 'Murmur';
const PLAN_CHATTER = 'Chatter';
const PLAN_ROAR = 'Roar';

const AVAILABLE = `<div class="available" />`;

const Pricing: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const [annualPricing, pricingTermHandler] = useDisclosure(true);
  const [activePlan, setActivePlan] = useState<string>(PLAN_WHISPER);
  const [indicatorOffset, setIndicatorOffset] = useState<number>(0);
  const [userCount, setUserCount] = useState<number>(200);
  const [estCostMonthly, setEstCostMonthly] = useState<number>(0);
  const [estCostAnnually, setEstCostAnnually] = useState<number>(0);
  const [estSaveAnnually, setEstSaveAnnually] = useState<number>(0);

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  const authenticated = useAuthenticated();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate(APP_LOGIN);
  };

  const navigateToSummary = () => {
    navigate(`${APP_PAYMENT_SUMMARY}?seats=${userCount}`);
  };

  useEffect(() => {
    if (activePlan === PLAN_WHISPER) {
      setIndicatorOffset(33);
    } else if (activePlan === PLAN_MURMUR) {
      setIndicatorOffset(22);
    } else if (activePlan === PLAN_CHATTER) {
      setIndicatorOffset(11);
    } else if (activePlan === PLAN_ROAR) {
      setIndicatorOffset(0);
    }
  }, [activePlan]);

  const feature_list = [
    'Maximum Team Members',
    'Cost per User',
    'Access to all meeting type',
    'Video Call',
    'Advanced AI Features',
    'Priority Customer Support',
    'Unlimited Meeting Duration',
    'Integration with Third-party Tools',
  ];

  const plan_whisper: any = [`<label>Whisper</label>`, 5, 'Free', AVAILABLE, AVAILABLE];

  const plan_murmur: any = [
    `<label>Murmur</label>`,
    50,
    `$${annualPricing ? PLANS_PRICE.murmur.annual : PLANS_PRICE.murmur.monthly}`,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
  ];

  const plan_chatter: any = [
    `<label>Chatter</label>`,
    150,
    `$${annualPricing ? PLANS_PRICE.chatter.annual : PLANS_PRICE.chatter.monthly}`,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
  ];

  const plan_roar: any = [
    `<label>Roar</label>`,
    'Unlimited',
    `$${annualPricing ? PLANS_PRICE.roar.annual : PLANS_PRICE.roar.monthly}`,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
    AVAILABLE,
  ];

  const handleUserCountChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, ''); // Replace all non-numeric characters
    setUserCount(newValue);
  };

  const estimateCost = useCallback(
    (userCount: number) => {
      let selectedPlan;
      let planName;

      if (userCount <= PLANS_PRICE.whisper.maxUser) {
        selectedPlan = PLANS_PRICE.whisper;
        planName = PLAN_WHISPER;
      } else if (userCount <= PLANS_PRICE.murmur.maxUser) {
        selectedPlan = PLANS_PRICE.murmur;
        planName = PLAN_MURMUR;
      } else if (userCount <= PLANS_PRICE.chatter.maxUser) {
        selectedPlan = PLANS_PRICE.chatter;
        planName = PLAN_CHATTER;
      } else {
        selectedPlan = PLANS_PRICE.roar;
        planName = PLAN_ROAR;
      }
      setActivePlan(planName);

      const costPerMonthMonthly = selectedPlan.monthly * userCount;
      const costPerMonthAnnually = selectedPlan.annual * userCount;
      const annualSavings = costPerMonthMonthly * 12 - costPerMonthAnnually * 12;

      setEstCostMonthly(costPerMonthMonthly);
      setEstCostAnnually(costPerMonthAnnually);
      setEstSaveAnnually(annualSavings);
    },
    [PLANS_PRICE],
  );

  useEffect(() => {
    estimateCost(userCount);
  }, [userCount, estimateCost]);

  return (
    <FadeTransition>
      <div className="pricing-page-container side-padding">
        <section className="roi-container">
          <h1>ROI</h1>
          <h3>3 skipped standups with Meating, and your $15 is already back in your pocket!</h3>
          <p>
            With just one team member at a $10 hourly rate, a 30-minute standup costs you $5. Eliminate three such
            standups in a week using Meating, and there's your $15 monthly fee, for that user, instantly recovered. The
            beauty? This is just the tip of the savings iceberg.
          </p>
          <p className="separate">
            Think saving on skipped standups with <span className="replace-with-meating-logo-inline">meating</span> is a
            win? Honey, that's just us scratching the surface. Dive deeper, and you'll find treasures that'll make you
            wish you had more meetings just to use our features! Picking Meating? It's like choosing chocolate cake over
            stale bread. No brainer, right?
          </p>
          <PrimaryButton
            onClick={() =>
              scrollIntoView({
                alignment: 'center',
              })
            }
          >
            Convinced? Estimate the cost
          </PrimaryButton>
        </section>
        <section className="plans-container">
          <h2 className="spray-magic">Not costly, Priceless!</h2>
          <div className="term-container">
            <label className={`term ease-element ${annualPricing ? 'active' : ''}`}>Annual</label>
            <div className="switch-wrapper">
              <Switch state={annualPricing} onChange={() => pricingTermHandler.toggle()} />
            </div>
            <label className={`term ease-element ${!annualPricing ? 'active' : ''}`}>Monthly</label>
          </div>
          <div className="plan-illustration">
            <div className="feature-list">
              {feature_list.map((feature, index) => (
                <div key={`feature-${index}`}>{feature}</div>
              ))}
            </div>
            <div
              className={`plan-features ${activePlan === PLAN_WHISPER ? 'active' : ''}`}
              onMouseOver={() => setActivePlan(PLAN_WHISPER)}
            >
              {plan_whisper.map((feature, index) => (
                <div key={`whisper-${index}`} dangerouslySetInnerHTML={{ __html: feature }} />
              ))}
            </div>
            <div
              className={`plan-features ${activePlan === PLAN_MURMUR ? 'active' : ''}`}
              onMouseOver={() => setActivePlan(PLAN_MURMUR)}
            >
              {plan_murmur.map((feature, index) => (
                <div key={`murmur-${index}`} dangerouslySetInnerHTML={{ __html: feature }} />
              ))}
            </div>
            <div
              className={`plan-features ${activePlan === PLAN_CHATTER ? 'active' : ''}`}
              onMouseOver={() => setActivePlan(PLAN_CHATTER)}
            >
              {plan_chatter.map((feature, index) => (
                <div key={`chatter-${index}`} dangerouslySetInnerHTML={{ __html: feature }} />
              ))}
            </div>
            <div
              className={`plan-features ${activePlan === PLAN_ROAR ? 'active' : ''}`}
              onMouseOver={() => setActivePlan(PLAN_ROAR)}
            >
              {plan_roar.map((feature, index) => (
                <div key={`roar-${index}`} dangerouslySetInnerHTML={{ __html: feature }} />
              ))}
            </div>
            <div className="active-plan-indicator ease-element" style={{ right: `${indicatorOffset}rem` }}></div>
          </div>
        </section>
        <section ref={targetRef} className="estimation-container">
          <h2>Let's Estimate</h2>
          <div className="count-box">
            <label>How many users are on your team?</label>
            <input type="text" value={userCount} onChange={handleUserCountChange} placeholder="400" />
          </div>
          <div className="estimate-result-wrapper">
            <div className="value-box">
              <h3>{estCostMonthly}</h3>
              <p>per month</p>
              <p>when paid monthly</p>
            </div>
            <div className="value-box">
              <h3>{estCostAnnually}</h3>
              <p>per month</p>
              <p>when paid annually</p>
            </div>
            <div className="value-box">
              <h3>{estSaveAnnually}</h3>
              <p>saved per year</p>
              <p>when paid annually</p>
            </div>
          </div>
          {authenticated ? (
            <PrimaryButton onClick={navigateToSummary} flipShadow={true}>
              Let's Bill Now
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={navigateToLogin} flipShadow={true}>
              Try Meating
            </PrimaryButton>
          )}
        </section>
      </div>
    </FadeTransition>
  );
});

export default Pricing;
