import { Action, action } from 'easy-peasy';

export interface AppModel {
  showConfetti: boolean;
  refetchTeams: boolean;
  setShowConfetti: Action<AppModel, boolean>;
  setRefetchTeams: Action<AppModel, boolean>;
}

const appModel: AppModel = {
  showConfetti: false,
  refetchTeams: false,
  setShowConfetti: action((state, payload) => {
    state.showConfetti = payload;
  }),
  setRefetchTeams: action((state, payload) => {
    state.refetchTeams = payload;
  }),
};

export default appModel;
