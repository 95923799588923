import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '@framework/Button';
import { Slider, TextArea, TextField } from '@framework/Input';
import Avatar from '@framework/Elements/Avatar';
import SearchUser from '@components/SearchUser';
import { INSERT_MEETING_AGENDA, SELECT_USER_DETAILS, SUBSCRIBE_AGENDA } from '@queries';
import { useUserId } from '@nhost/react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import './AgendaForm.scss';
import { storeState } from '@state';
import AgendaList from '@components/Session/AgendaList';

interface RouteProps {
  meetingId: string;
  totalMins: number;
}

const AgendaForm: React.FC<RouteProps> = React.memo(({ meetingId, totalMins }): JSX.Element => {
  const [availableMins, setAvailableMins] = useState<number>(totalMins);
  const [agendas, setAgendas] = useState<any>();
  const [itemTitle, setItemTitle] = useState<string>('');
  const [itemDetail, setItemDetail] = useState<string>('');
  const [itemDuration, setItemDuration] = useState<number>(0);
  const [itemLead, setItemLead] = useState<any>(null);
  const [selfDetail, setSelfDetail] = useState<any>(null);

  const activeTeam = storeState((state: any): any => state.user.activeTeam);

  const userId = useUserId();

  const agendaSubscription = useSubscription(SUBSCRIBE_AGENDA, {
    variables: { meetingId },
    skip: !meetingId,
  });

  const { data: selfData } = useQuery(SELECT_USER_DETAILS, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'network-only',
  });
  const [saveAgenda, { loading: agendaSaveLoading }] = useMutation(INSERT_MEETING_AGENDA);

  useEffect(() => {
    setAgendas(agendaSubscription?.data?.meating_meeting_agenda);
  }, [agendaSubscription]);

  useEffect(() => {
    setSelfDetail(selfData?.meating_user_detail[0]);
  }, [selfData]);

  useEffect(() => {
    if (!itemLead) {
      setItemLead(selfDetail);
    }
  }, [itemLead, selfDetail]);

  useEffect(() => {
    let availableMins = totalMins;
    agendas?.forEach((agenda) => {
      availableMins -= agenda.duration;
    });
    setAvailableMins(availableMins);
  }, [agendas]);

  const saveTheAgendaItem = async () => {
    const data = {
      meetingId,
      title: itemTitle,
      detail: itemDetail,
      duration: itemDuration,
      order: agendas.length + 1,
      lead: itemLead.id,
      addedBy: userId,
    };

    await saveAgenda({ variables: data });

    //Save the item
    clearForm();
  };

  const clearForm = () => {
    setItemTitle('');
    setItemDetail('');
    setItemDuration(0);
    setItemLead(null);
  };

  return (
    <div className="agenda-form-container">
      <div className="form-wrapper">
        <div className="field-item">
          <TextField
            placeholder="Upcoming Tasks for the Next Week"
            label="Define the Central Theme of This Agenda Point"
            value={itemTitle}
            onChange={setItemTitle}
          />
        </div>
        <div className="field-item">
          <TextArea
            placeholder="Outline the tasks slated for the upcoming week, assigning roles and
                  setting deadlines."
            label="Define the Central Theme of This Agenda Point"
            value={itemDetail}
            onChange={setItemDetail}
          />
        </div>
        <div className="field-item">
          <Slider
            label="Set an Approximate Timeframe for This Discussion"
            value={itemDuration}
            onChange={setItemDuration}
            step={5}
            max={availableMins}
          />
        </div>
        <div className={`field-item ${activeTeam === undefined || activeTeam === null ? 'disabled' : ''}`}>
          <label>Designate the Individual Leading This Discussion Topic</label>
          <SearchUser
            teamId={activeTeam?.id}
            onSelect={setItemLead}
            disabled={activeTeam === undefined || activeTeam === null}
          />
        </div>
        {itemLead && (
          <div className="field-item">
            <div className="lead-card-container">
              <Avatar size="sm" src={itemLead.avatar} name={itemLead.name} />
              <label>{itemLead.name}</label>
            </div>
          </div>
        )}
        <div className="agenda-actions">
          <PrimaryButton size="sm" onClick={saveTheAgendaItem} loading={agendaSaveLoading}>
            Add to Agenda
          </PrimaryButton>
        </div>
      </div>
      <div className="agenda-list-wrapper">
        <AgendaList meetingId={meetingId} polling={true} readOnly={true} />
      </div>
    </div>
  );
});

export default AgendaForm;
