import React from 'react';
import moment from 'moment';
import mobile from 'is-mobile';
import { motion, AnimatePresence } from 'framer-motion';
import SilentDayNote from './SilentDayNote';
import { MeetingMinutes } from '@types';
import MeetingItem from './MeetingItem';
import './Weekend.scss';

interface RouteProps {
  data: any;
}

const Weekend: React.FC<RouteProps> = React.memo(({ data }): JSX.Element => {
  const isMobile = mobile();

  const renderDay = (day) => {
    if (isMobile) {
      const parts = day.split(' - ');
      return (
        <div className="day-items">
          <span>{parts[0].charAt(0)}</span>
          <span>{parts[1].charAt(0)}</span>
        </div>
      );
    } else {
      return day;
    }
  };

  const renderDate = (date) => {
    if (isMobile) {
      return date.includes('&')
        ? date
            .split(' & ')
            .map((d) => moment(d).format('DD'))
            .join('/')
        : moment(data.date).format('DD');
    } else {
      return date.includes('&')
        ? date
            .split(' & ')
            .map((d) => moment(d).format('DD'))
            .join(' - ')
        : moment(data.date).format('DD');
    }
  };

  return (
    <div className={`calendar-day-item weekend-item ${data.date === moment().format('YYYY-MM-DD') ? 'today' : ''}`}>
      <div className="calendar-day-header">
        <div className="calendar-day">{renderDay(data.dayNames)}</div>
        <div className="calendar-date">{renderDate(data.date)}</div>
      </div>
      <AnimatePresence>
        {!data.meetings || data.meetings.length === 0 ? (
          <SilentDayNote key={`hush-${data.date}`} isWeekend={true} />
        ) : (
          <div className="meeting-item-list">
            {data.meetings?.map((meeting: MeetingMinutes, index) => (
              <motion.div
                key={`wrapper-${meeting.id}`}
                initial={{ y: 5, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -5, opacity: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <MeetingItem key={`meeting-${meeting.id}`} data={meeting} isWeekend={true} />
              </motion.div>
            ))}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default Weekend;
