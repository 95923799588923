import React from 'react';
import { Loader } from '@framework/Elements';
import { motion } from 'framer-motion';
import './PageLoader.scss';

interface RouteProps {}

const transitionConfig = {
  inactive: {
    opacity: 0,
  },
  active: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

const PageLoader: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <motion.div
      className="page-loader-container"
      variants={transitionConfig}
      initial="inactive"
      animate="active"
      exit="exit"
    >
      <Loader size="lg" />
    </motion.div>
  );
});

export default PageLoader;
