import React, { useEffect, useState } from 'react';
import { RichText } from '@framework/Input';
import { PrimaryButton } from '@framework/Button';
import { Minute } from '@types';
import { useError } from '@components/Notification';
import { useMutation } from '@apollo/client';
import { INSERT_MEETING_MINUTE, UPDATE_MEETING_MINUTE } from '@queries';
import MinutesType from '@components/Session/MinutesType';
import './MinutesEditor.scss';

interface RouteProps {
  meetingId: string;
  editMessage: Minute | null;
  onEditComplete: Function;
}

const MinutesEditor: React.FC<RouteProps> = React.memo(({ meetingId, editMessage, onEditComplete }): JSX.Element => {
  const triggerError = useError();
  const [type, setType] = useState<string>('Discussion');
  const [message, setMessage] = useState<string>('');
  const [resetEditor, setResetEditor] = useState<boolean>(false);

  const [insertMinute, { loading: loadingInsertMinute, error: errorInsertMinute }] = useMutation(INSERT_MEETING_MINUTE);
  const [updateMinute, { loading: loadingUpdateMinute, error: errorUpdateMinute }] = useMutation(UPDATE_MEETING_MINUTE);

  useEffect(() => {
    if (errorInsertMinute || errorUpdateMinute) {
      triggerError(errorInsertMinute || errorUpdateMinute);
    }
  }, [errorInsertMinute, errorUpdateMinute, triggerError]);

  const handleAddMessage = async (minute: Minute) => {
    await insertMinute({
      variables: {
        meetingId: meetingId,
        type: minute.type,
        message: minute.message,
        time: new Date(),
      },
    });
  };

  const handleUpdateMessage = async (minute: Minute) => {
    await updateMinute({
      variables: minute,
    });
    onEditComplete();
  };

  useEffect(() => {
    if (editMessage) {
      loadMessage(editMessage);
    }
  }, [editMessage]);

  const loadMessage = (message: Minute) => {
    setType(message.type);
    setMessage(message.message);
  };

  const handleSave = () => {
    const minute: Minute = { type, message };

    if (editMessage) {
      minute.id = editMessage.id;
    }

    if (editMessage) {
      handleUpdateMessage(minute);
    } else {
      handleAddMessage(minute);
    }

    // reset the fields
    setMessage('');
    setType('Discussion');
    setResetEditor(true);
  };

  return (
    <div className="session-minutes-editor-container">
      <RichText
        value={message}
        placeholder="Meeting minutes: Topics, actions, decisions..."
        onChange={setMessage}
        resetEditor={resetEditor}
        onReset={() => setResetEditor(false)}
      />
      <div className="minutes-editor-actions">
        <div className="data-items">
          <MinutesType active={type} onChange={setType} />
        </div>
        <div className="actions-items">
          <PrimaryButton size="xs" onClick={handleSave} loading={loadingInsertMinute || loadingUpdateMinute}>
            Include
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
});

export default MinutesEditor;
