import React, { useRef, useEffect, useState } from 'react';
import './TimerSlider.scss';

interface TimerSliderProps {
  startTime: Date;
  duration: number;
}

const STATUS_SAFE = 'safe';
const STATUS_OKAYISH = 'okayish';
const STATUS_ALMOST = 'almost';
const STATUS_DONE = 'done';

const TimerSlider: React.FC<TimerSliderProps> = ({ startTime, duration }) => {
  const [status, setStatus] = useState<string>(STATUS_SAFE);
  const [timeLeftPercentage, setTimeLeftPercentage] = useState(100);
  const [parentWidth, setParentWidth] = useState<number | null>(null);
  const [endTime, setEndTime] = useState(new Date(startTime.getTime() + duration * 60000));

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const calculateTimeLeft = () => {
    const currentTime = new Date();
    const totalTime = endTime.getTime() - startTime.getTime();
    const timeLeft = endTime.getTime() - currentTime.getTime();

    const percentageLeft = Math.max(0, (timeLeft / totalTime) * 100);
    setTimeLeftPercentage(percentageLeft);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setParentWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(canvas.parentElement!);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (parentWidth && ctx) {
      canvas.width = parentWidth;

      ctx.clearRect(0, 0, parentWidth, 30);
      ctx.lineWidth = 1;

      let x = 0;
      const smallLineHeight = 5;
      const bigLineHeight = 10;
      const spaceBetweenLines = 4;

      ctx.lineWidth = 0.6; // Set the line width to 1 pixel
      ctx.strokeStyle = '#969da0'; // Set the line color to red

      while (x < parentWidth) {
        for (let i = 0; i < 5 && x < parentWidth; i++) {
          ctx.moveTo(x, 0);
          ctx.lineTo(x, smallLineHeight);
          x += spaceBetweenLines;
        }

        if (x < parentWidth) {
          ctx.moveTo(x, 0);
          ctx.lineTo(x, bigLineHeight);
          x += spaceBetweenLines;
        }
      }

      ctx.stroke();
    }
  }, [parentWidth]);

  useEffect(() => {
    if (timeLeftPercentage > 70) {
      setStatus(STATUS_SAFE);
    } else if (timeLeftPercentage > 30) {
      setStatus(STATUS_OKAYISH);
    } else if (timeLeftPercentage > 15) {
      setStatus(STATUS_ALMOST);
    } else {
      setStatus(STATUS_DONE);
    }
  }, [timeLeftPercentage, setStatus]);

  useEffect(() => {
    setEndTime(new Date(startTime.getTime() + duration * 60000));
    const timerInterval = setInterval(calculateTimeLeft, 50);
    return () => {
      clearInterval(timerInterval);
    };
  }, [startTime, duration]);

  return (
    <div className="time-slider-wrapper">
      <canvas ref={canvasRef} width="100%" height={30} />
      <div className={`time-slider-container ${status}`}>
        <div className="time-info start-time-info">
          {startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
        </div>
        <div className="time-info end-time-info">
          {endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
        </div>
        <div className="time-slider-completed-bar" style={{ width: 100 - timeLeftPercentage + '%' }}></div>
        <div className="time-slider-knob" style={{ left: 100 - timeLeftPercentage + '%' }}></div>
      </div>
    </div>
  );
};

export default TimerSlider;
