import React from 'react';
import './RightPane.scss';

interface RouteProps {}

const RightPane: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <div className="footer-pane">
      <div className="footer-meating-typo">
        <div className="typo-1">No Fluff, Just Substance</div>
        <div className="typo-2">That’s Meating!</div>
      </div>
    </div>
  );
});

export default RightPane;
