import React, { useCallback, useEffect, useState } from 'react';
import { formatDateForSession, getDateStatus } from '@utils';
import { useUserId } from '@nhost/react';
import { MEETING_SESSION_STATUS, SESSION_STATE } from '@constants';
import { PrimaryButton } from '@framework/Button';
import './SessionIntro.scss';
import { useMutation } from '@apollo/client';
import { INSERT_MEETING_SESSION } from '@queries';
import { APP_CALENDAR } from '@path';
import { useNavigate } from 'react-router-dom';

interface RouteProps {
  data: any;
  onJoin: Function;
}

const generateIntroTitle = (state: string) => {
  switch (state) {
    case SESSION_STATE.ACTIVE:
      return <div className="title spray-magic">The session is currently in motion</div>;
    case SESSION_STATE.EXPIRED:
      return <div className="title spray-warn">The event has concluded</div>;
    case SESSION_STATE.SCHEDULED:
      return <div className="title spray-warn">The session is yet to begin</div>;
  }
};

const SessionIntro: React.FC<RouteProps> = React.memo(({ data, onJoin }): JSX.Element => {
  const [sessionState, setSessionState] = useState<string>('');
  const [userInvited, setUserInvited] = useState<boolean>(false);

  const userId = useUserId();
  const navigate = useNavigate();

  const [addSession, { loading }] = useMutation(INSERT_MEETING_SESSION);

  const processMeetingData = useCallback(
    (data) => {
      const participants = data.rel_meeting_participants;
      setUserInvited(
        userId === data.created_by || participants.some((participant) => userId === participant.rel_user_detail.id),
      );
      setSessionState(getDateStatus(new Date(data.from_time), new Date(data.to_time)));
    },
    [userId],
  );

  useEffect(() => {
    if (data) {
      processMeetingData(data);
    }
  }, [data, processMeetingData]);

  const startSession = async () => {
    const response = await addSession({
      variables: {
        meetingId: data.id,
        userId,
        startTime: new Date(),
        status: MEETING_SESSION_STATUS.ACTIVE,
      },
    });
    onJoin(response?.data?.insert_meating_meeting_sessions?.returning[0].id);
  };

  return (
    <div className="session-intro-container single-page-container side-padding">
      {data && (
        <React.Fragment>
          {generateIntroTitle(sessionState)}
          <div className="name">{data.name}</div>
          <div className="schedule">{formatDateForSession(new Date(data.from_time), new Date(data.to_time))}</div>
          {sessionState !== SESSION_STATE.EXPIRED && (
            <div className="action">
              {userInvited ? (
                <React.Fragment>
                  <button className="action-item ease-element" onClick={() => navigate(`${APP_CALENDAR}/${data.id}`)}>
                    {data.created_by === userId ? 'Config' : 'Agenda'}
                  </button>
                  <button className="action-item ease-element" onClick={startSession}>
                    Join the Session
                  </button>
                </React.Fragment>
              ) : (
                <div className="notice">Regrettably, you are not on the guest list for this particular meeting.</div>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
});

export default SessionIntro;
