import React, { ReactNode, createRef, useEffect, useState } from 'react';
import mobile from 'is-mobile';
import { useClickOutside } from '@mantine/hooks';
import './Tooltip.scss';

interface RouteProps {
  message: string | ReactNode;
  align?: 'left' | 'right';
  className?: string;
  children: any;
}

const STANDARD_OFFSET = -8;

const Tooltip: React.FC<RouteProps> = React.memo(({ message, align = 'right', className, children }): JSX.Element => {
  const tooltipRef = createRef<HTMLDivElement>();
  const [show, setShow] = useState<boolean>(false);
  const [requiredHeight, setRequiredHeight] = useState<number>(0);
  const [topOffset, setTopOffset] = useState<number>(0);

  const isMobile = mobile();
  const ref = useClickOutside(() => (isMobile ? setShow(false) : null));

  useEffect(() => {
    calculateHeight();
  }, [tooltipRef]);

  useEffect(() => {
    positionTooltip();
  }, [requiredHeight]);

  const calculateHeight = () => {
    if (tooltipRef.current) {
      setRequiredHeight(tooltipRef.current.offsetHeight);
    }
  };

  const positionTooltip = () => {
    setTopOffset(STANDARD_OFFSET - requiredHeight);
  };

  return (
    <div
      ref={ref}
      className={`item-with-tooltip ${className}`}
      onMouseOver={() => (!isMobile ? setShow(true) : null)}
      onMouseOut={() => (!isMobile ? setShow(false) : null)}
      onTouchEnd={() => (isMobile ? setShow(true) : null)}
    >
      {children}
      {show && (
        <div
          ref={tooltipRef}
          className={`tooltip-message ${align}`}
          style={{ top: topOffset, opacity: topOffset < STANDARD_OFFSET ? 1 : 0 }}
        >
          {message}
        </div>
      )}
    </div>
  );
});

export default Tooltip;
