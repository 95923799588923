import React, { useEffect } from 'react';
import ReactSlider from 'react-slider';
import './Slider.scss';

interface RouteProps {
  label?: string;
  value: number;
  className?: string;
  onChange: Function;
  step?: number;
  min?: number;
  max?: number;
}

const Slider: React.FC<RouteProps> = React.memo(
  ({ label, value, className, onChange, step = 1, min = 0, max = 100 }): JSX.Element => {
    return (
      <div className="input-slider-container">
        <label>{label}</label>
        <div className={`input-slider-wrapper ${className ? className : ''}`}>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            value={value}
            step={step}
            min={min}
            max={max}
            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
            onChange={onChange}
          />
        </div>
      </div>
    );
  },
);

export default Slider;
