import React from 'react';
import './TextArea.scss';

interface RouteProps {
  label?: string;
  value: string;
  placeholder?: string;
  className?: string;
  onChange: Function;
  error?: boolean;
  align?: 'left' | 'center' | 'right';
  resizable?: boolean;
}

const TextArea: React.FC<RouteProps> = React.memo(
  ({
    label,
    value,
    placeholder = 'Type here',
    className,
    onChange,
    error,
    align = 'left',
    resizable = false,
  }): JSX.Element => {
    return (
      <div className="input-area-container">
        <label>{label}</label>
        <div
          className={`input-area-wrapper ${error ? 'error' : ''} ${
            resizable ? 'resizable' : ''
          } ${align} ${className}`}
        >
          <textarea
            value={value}
            placeholder={placeholder}
            className="text-field"
            onChange={(event) => onChange(event.target.value)}
          />
        </div>
      </div>
    );
  },
);

export default TextArea;
