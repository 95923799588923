import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Privacy.scss';

interface RouteProps {}

const Privacy: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <FadeTransition>
      <div className="privacy-page-container side-padding">
        <h1>Privacy Policy</h1>

        <p>Effective Date: 28-Oct-23</p>

        <p>
          Welcome to Meating.app, a product by Foolish Works Technologies Pvt. Ltd. This Privacy Policy outlines our
          practices concerning the collection, use, and sharing of your information. By using Meating.app, you consent
          to the terms and conditions of this policy.
        </p>

        <h2>1. Information Collection</h2>
        <p>
          We may collect personal and non-personal information that you provide to us or that is generated through your
          use of our platform. This includes, but is not limited to, your name, email address, and other identifiable
          data.
        </p>

        <h2>2. Use of Information</h2>
        <p>We use the information collected to:</p>
        <ul>
          <li>Provide, maintain, and improve our services.</li>
          <li>Process transactions and send related information.</li>
          <li>Respond to your comments, questions, and requests.</li>
          <li>Monitor and analyze usage and trends.</li>
          <li>Enhance the safety and security of our platform.</li>
        </ul>

        <h2>3. Sharing of Information</h2>
        <p>
          We do not share, sell, or lease your personal information to third parties unless required by law, or for
          operational purposes provided such third parties maintain the confidentiality of your data.
        </p>

        <h2>4. Data Security</h2>
        <p>
          We employ security measures to protect your information from access by unauthorized persons and against
          unlawful processing, accidental loss, destruction, and damage. While we strive to protect your information, we
          cannot guarantee its absolute security.
        </p>

        <h2>5. International Usage</h2>
        <p>
          Meating.app is developed by Foolish Works Technologies Pvt. Ltd. in India and may be accessed globally. By
          using our platform outside India, you understand and consent to the transfer of your personal information to
          India.
        </p>

        <h2>6. Changes to Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated
          effective date.
        </p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at:</p>
        <address>
          Foolish Works Technologies Pvt. Ltd.
          <br />
          42/3, Kallankadu Pudur, Elur PO, Namakkal, Tamil Nadu - 637018, Chennai,
          <br />
          Tamil Nadu, India
          <br />
          GSTIN: 33AAECF4842Q1Z9
          <br />
          PAN: AAECF4842Q
          <br />
        </address>
      </div>
    </FadeTransition>
  );
});

export default Privacy;
