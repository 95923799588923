import React, { useEffect, useState } from 'react';
import './SessionTimer.scss';

interface RouteProps {
  from: Date;
  to: Date;
}

enum SESSION_STATUS {
  OPEN = 'open', // Meeting hasn't started yet
  RELAXED = 'relaxed', // More than 70% of the meeting time remains
  ALERT = 'alert', // Between 50% to 70% of the meeting time remains
  CAUTION = 'caution', // Between 30% to 50% of the meeting time remains
  URGENT = 'urgent', // Between 10% to 30% of the meeting time remains
  CRITICAL = 'critical', // Less than 10% of the meeting time remains
  CLOSED = 'closed', // Meeting is complete
}

const SessionTimer: React.FC<RouteProps> = React.memo(({ from, to }): JSX.Element => {
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [sessionStatus, setSessionStatus] = useState<string>('');

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const updateTimer = () => {
      const currentTime = new Date().getTime();
      const timeToStart = from.getTime() - currentTime;
      const timeSinceStart = currentTime - from.getTime();
      const totalMeetingTime = to.getTime() - from.getTime();

      if (timeToStart > 0) {
        setSessionStatus(SESSION_STATUS.OPEN);
        if (timeToStart >= 24 * 3600 * 1000) {
          // More than 24 hours left
          const days = Math.floor(timeToStart / (24 * 3600 * 1000));
          setTimeLeft(`${days} days left to start`);
        } else if (timeToStart >= 3600 * 1000) {
          // Between 1 hour and 24 hours left
          const hours = Math.floor(timeToStart / (3600 * 1000));
          setTimeLeft(`${hours} hours left to start`);
        } else {
          // Less than an hour left
          const mins = Math.floor(timeToStart / 60000);
          setTimeLeft(`${mins} mins left to start`);
        }
      } else if (timeSinceStart >= 0 && timeSinceStart < totalMeetingTime) {
        // Calculate meeting status
        const percentageTimePassed = (timeSinceStart / totalMeetingTime) * 100;

        if (percentageTimePassed <= 30) setSessionStatus(SESSION_STATUS.RELAXED);
        else if (percentageTimePassed <= 50) setSessionStatus(SESSION_STATUS.ALERT);
        else if (percentageTimePassed <= 70) setSessionStatus(SESSION_STATUS.CAUTION);
        else if (percentageTimePassed <= 90) setSessionStatus(SESSION_STATUS.URGENT);
        else setSessionStatus(SESSION_STATUS.CRITICAL);

        // Current time is between 'from' and 'to'
        const mins = Math.floor((totalMeetingTime - timeSinceStart) / 60000);
        const secs = (((totalMeetingTime - timeSinceStart) % 60000) / 1000).toFixed(0);
        setTimeLeft(`${mins.toString().padStart(2, '0')}:${secs.padStart(2, '0')}`);
      } else {
        clearInterval(interval);
        setSessionStatus(SESSION_STATUS.CLOSED);
        setTimeLeft('00:00');
      }
    };

    updateTimer(); // Initial run
    interval = setInterval(updateTimer, 1000); // Subsequent runs

    return () => {
      clearInterval(interval);
    };
  }, [from, to]);

  return (
    <div className={`timer ease-element ${sessionStatus}`}>
      <div className="value">{timeLeft}</div>
    </div>
  );
});

export default SessionTimer;
