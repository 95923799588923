import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SELECT_AGENDA, SELECT_TASK, SUBSCRIBE_AGENDA, SUBSCRIBE_TASK } from '@queries';
import { useQuery, useSubscription } from '@apollo/client';
import TaskItem from './components/TaskItem';
import NoRecord from '@components/NoRecord';
import './TaskList.scss';

interface RouteProps {
  meetingId: string;
  polling?: boolean;
}

const TaskList: React.FC<RouteProps> = React.memo(({ meetingId, polling = false }): JSX.Element => {
  const [tasks, setTasks] = useState<any>();

  const taskSubscription = useSubscription(SUBSCRIBE_TASK, {
    variables: { meetingId },
    skip: !polling || !meetingId,
  });

  const { data: taskData } = useQuery(SELECT_TASK, {
    variables: { meetingId },
    skip: polling || !meetingId,
  });

  useEffect(() => {
    setTasks(taskSubscription?.data?.meating_meeting_task);
  }, [taskSubscription, setTasks]);

  useEffect(() => {
    setTasks(taskData?.meating_meeting_task);
  }, [taskData, setTasks]);

  return (
    <div className="agenda-list-container">
      <AnimatePresence>
        {tasks?.map((task, index) => (
          <motion.div
            key={task.id}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 10, opacity: 0 }}
            transition={{ duration: 1, delay: index * 0.2 }}
          >
            <TaskItem key={index} data={task} />
          </motion.div>
        ))}
        {(tasks === undefined || tasks?.length === 0) && <NoRecord />}
      </AnimatePresence>
    </div>
  );
});

export default TaskList;
