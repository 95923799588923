import React from 'react';
import './Loader.scss';

interface RouteProps {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  negative?: boolean;
}

const Loader: React.FC<RouteProps> = React.memo(({ size = 'sm', negative = false }): JSX.Element => {
  return <div className={`spinner ${size} ${negative ? 'negative' : ''}`}></div>;
});

export default Loader;
