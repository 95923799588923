import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSubscription } from '@apollo/client';
import { SUBSCRIBE_AGENDA } from '@queries';
import './AgendaList.scss';
import NoRecord from '@components/NoRecord';
import AgendaItem from '@components/Session/AgendaList/components/AgendaItem';

interface RouteProps {
  meetingId: string;
}

const AgendaList: React.FC<RouteProps> = React.memo(({ meetingId }): JSX.Element => {
  const [agendas, setAgendas] = useState<any>();
  const [canStart, setCanStart] = useState<boolean>(true);

  const agendaSubscription = useSubscription(SUBSCRIBE_AGENDA, {
    variables: { meetingId },
    skip: !meetingId,
  });

  useEffect(() => {
    setAgendas(agendaSubscription?.data?.meating_meeting_agenda);
  }, [agendaSubscription]);

  useEffect(() => {
    setCanStart(!agendas?.some((item) => item.active));
  }, [agendas]);

  return (
    <div className="session-agenda-items-wrapper">
      <div className="agenda-list">
        <AnimatePresence>
          {agendas?.map((agenda, index) => (
            <motion.div
              key={agenda.id}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ duration: 1, delay: index * 0.2 }}
            >
              <AgendaItem key={index} data={agenda} canStart={canStart} />
            </motion.div>
          ))}
          {agendas?.length === 0 && <NoRecord />}
        </AnimatePresence>
      </div>
    </div>
  );
});

export default AgendaList;
