import React, { useCallback, useEffect, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { Editor, createEditor, BaseEditor, Descendant, Transforms } from 'slate';
import { HistoryEditor, withHistory } from 'slate-history';
import Element from './components/Element';
import Leaf from './components/Leaf';
import MarkButton from './components/MarkButton';
import BlockButton from './components/BlockButton';
import Toolbar from './components/Toolbar';
import ButtonGroup from './components/ButtonGroup';
import './RichText.scss';
import './Editor.scss';

interface RouteProps {
  asText?: boolean;
  value?: string;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  onChange: Function;
  resetEditor?: boolean;
  onReset?: Function;
  disabled?: boolean;
}

type CustomText = { text: string; bold?: boolean; italic?: boolean; code?: boolean };
type CustomElement = { type: 'paragraph' | 'block-quote'; align?: string; children: CustomText[] };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const serialize = (value) => {
  return JSON.stringify(value);
};

const deserialize = (string) => {
  return JSON.parse(string);
};

const RichText: React.FC<RouteProps> = React.memo(
  ({ placeholder = 'Draft your thoughts', onChange, value, disabled, resetEditor, onReset }): JSX.Element => {
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(() => withReact(withHistory(createEditor())), []);

    useEffect(() => {
      if (resetEditor) {
        Transforms.select(editor, Editor.start(editor, []));
        editor.children = value ? deserialize(value) : initialValue;
        onReset();
      }
    }, [resetEditor]);

    return (
      <div className={`editor-container ${disabled ? 'disabled' : ''}`}>
        <Slate
          editor={editor}
          initialValue={value ? deserialize(value) : initialValue}
          onChange={(value) => {
            onChange(serialize(value));
          }}
        >
          {!disabled && (
            <Toolbar>
              <ButtonGroup>
                <BlockButton format="heading-one" tooltip="Heading 1" icon="editor-text-h1" />
                <BlockButton format="heading-two" tooltip="Heading 2" icon="editor-text-h2" />
                <BlockButton format="heading-three" tooltip="Heading 3" icon="editor-text-h3" />
                <BlockButton format="heading-four" tooltip="Heading 4" icon="editor-text-h4" />
                <BlockButton format="heading-five" tooltip="Heading 5" icon="editor-text-h5" />
                <BlockButton format="heading-six" tooltip="Heading 6" icon="editor-text-h6" />
              </ButtonGroup>
              <ButtonGroup>
                <BlockButton format="left" tooltip="Align left" icon="editor-align-left" />
                <BlockButton format="center" tooltip="Align center" icon="editor-align-center" />
                <BlockButton format="right" tooltip="Align right" icon="editor-align-right" />
                <BlockButton format="justify" tooltip="Align justify" icon="editor-align-justify" />
              </ButtonGroup>
              <ButtonGroup>
                <MarkButton format="bold" tooltip="Bold" icon="editor-style-bold" />
                <MarkButton format="italic" tooltip="Itlicize" icon="editor-style-italic" />
                <MarkButton format="underline" tooltip="Underline" icon="editor-style-underline" />
                <MarkButton format="strike" tooltip="Strike through" icon="editor-style-strike" />
              </ButtonGroup>
              {/* <ButtonGroup>
                <BlockButton format="numbered-list" tooltip="Numbered list" icon="editor-list-numbered" />
                <BlockButton format="bulleted-list" tooltip="Bullet list" icon="editor-list-bullet" />
              </ButtonGroup> */}
              <ButtonGroup>
                <BlockButton format="block-quote" tooltip="Block quote" icon="editor-quote" />
                <MarkButton format="highlight" tooltip="Highlight text" icon="editor-highlight" />
                {/* <MarkButton format="code" tooltip="Highlight code" icon="editor-code-line" /> */}
              </ButtonGroup>
            </Toolbar>
          )}
          <div className="editor-play-container rte-container ease-element">
            <Editable
              readOnly={disabled}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              spellCheck
              autoFocus
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />
          </div>
        </Slate>
      </div>
    );
  },
);

export default RichText;
