import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SELECT_AGENDA, SUBSCRIBE_AGENDA } from '@queries';
import { useQuery, useSubscription } from '@apollo/client';
import AgendaItem from './components/AgendaItem';
import NoRecord from '@components/NoRecord';
import './AgendaList.scss';

interface RouteProps {
  meetingId: string;
  polling?: boolean;
  readOnly?: boolean;
}

const AgendaList: React.FC<RouteProps> = React.memo(({ meetingId, polling = false, readOnly = false }): JSX.Element => {
  const [agendas, setAgendas] = useState<any>();
  const [canStart, setCanStart] = useState<boolean>(true);

  const agendaSubscription = useSubscription(SUBSCRIBE_AGENDA, {
    variables: { meetingId },
    skip: !polling || !meetingId,
  });

  const { data: agendaData } = useQuery(SELECT_AGENDA, {
    variables: { meetingId },
    skip: polling || !meetingId,
  });

  useEffect(() => {
    setAgendas(agendaSubscription?.data?.meating_meeting_agenda);
  }, [agendaSubscription, setAgendas]);

  useEffect(() => {
    setAgendas(agendaData?.meating_meeting_agenda);
  }, [agendaData, setAgendas]);

  useEffect(() => {
    setCanStart(!agendas?.some((item) => item.active));
  }, [agendas]);

  return (
    <div className="agenda-list-container">
      <AnimatePresence>
        {agendas?.map((agenda, index) => (
          <motion.div
            key={agenda.id}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 10, opacity: 0 }}
            transition={{ duration: 1, delay: index * 0.2 }}
          >
            <AgendaItem key={index} data={agenda} canStart={canStart} readOnly={readOnly} />
          </motion.div>
        ))}
        {(agendas === undefined || agendas?.length === 0) && <NoRecord />}
      </AnimatePresence>
    </div>
  );
});

export default AgendaList;
