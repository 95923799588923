import React from 'react';
import { toStrictTime } from '@utils';
import RichTextView from '@framework/Input/RichText/View';
import MinutesType from '../../../components/Session/MinutesType';
import './MinuteItem.scss';

interface RouteProps {
  data: any;
}

const MinuteItem: React.FC<RouteProps> = React.memo(({ data }): JSX.Element => {
  return (
    <div className={`session-minute-item-container ease-element`}>
      <div className="minute-info-data">
        <MinutesType active={data.type} asValue={true} />
        <div className="time">{toStrictTime(new Date(data.time))}</div>
      </div>
      {data.message && <RichTextView value={data.message} />}
    </div>
  );
});

export default MinuteItem;
