// Context route definitions
export const APP_HOME = '/';
export const APP_LOGIN = '/login';
export const APP_LOGOUT = '/logout';
export const APP_SETUP = '/setup';
export const APP_DASHBOARD = '/dashboard';
export const APP_CALENDAR = '/calendar';
export const APP_LAUNCHPAD = '/meetings/new';
export const APP_SESSION = '/session';
export const APP_ACTIVITY = '/activity';
export const APP_DIGEST = '/digest';
export const APP_SETTINGS = '/settings';
export const APP_PRICING = '/pricing';
export const APP_TERMS = '/terms';
export const APP_PRIVACY = '/privacy';
export const APP_REFUND = '/refund';
export const APP_CONTACT = '/contact';
export const APP_BECOME_PRO = '/become-pro';
export const APP_WORKSPACES = '/workspaces';
export const APP_PAYMENT_SUMMARY = '/payment/summary';
export const APP_PAYMENT_SUCCESS = '/payment/success';
export const APP_PAYMENT_CANCEL = '/payment/cancel';
export const APP_ERROR = '/error';
