import React from 'react';
import FadeTransition from '@framework/Transition/FadeTransition';
import './Dashboard.scss';
import ensureUserSetup from '@components/User';
import { storeState } from '@state';

interface RouteProps {}

const Dashboard: React.FC<RouteProps> = React.memo((): JSX.Element => {
  const self = storeState((state: any): any => state.user.self);

  return (
    <FadeTransition>
      <div className="dashboard-page-container single-page-container side-padding">
        <h3>Welcome</h3>
        <h2>{self?.name}</h2>
      </div>
    </FadeTransition>
  );
});

export default ensureUserSetup(Dashboard);
