import React, { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAuthenticated, useUserId } from '@nhost/react';
import { SELECT_USER_DETAILS } from '@queries';
import { storeActions } from '@state';
import { SelfDetail } from '@types';

interface RouteProps {}

const UserInfo: React.FC<RouteProps> = ({}) => {
  const setSelf = storeActions((action: any): any => action.user.setSelf);

  const isAuthenticated = useAuthenticated();
  const userId = useUserId();

  const { data, refetch } = useQuery(SELECT_USER_DETAILS, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  });

  const setUserData = useCallback(
    async (data) => {
      const self: SelfDetail = {
        id: data.id,
        name: data.name,
        avatar: data.avatar,
        email: data.rel_user.email,
      };
      await setSelf(self);
    },
    [setSelf],
  );

  useEffect(() => {
    if (isAuthenticated && userId) {
      refetch();
    }
  }, [userId, isAuthenticated, refetch]);

  useEffect(() => {
    if (data?.meating_user_detail?.length > 0) {
      setUserData(data?.meating_user_detail[0]);
    }
  }, [data, setUserData]);

  return <></>;
};

export default UserInfo;
