import React, { useCallback, useEffect, useState } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useDebouncedValue, useClickOutside } from '@mantine/hooks';
import { TextField } from '@framework/Input';
import { useLazyQuery, gql } from '@apollo/client';
import { PLACEHOLDER_FOR_COMPONENT_BUILT_QUERY } from '@queries';
import { useUserId } from '@nhost/react';
import Avatar from '@framework/Elements/Avatar';
import './SearchUser.scss';

interface RouteProps {
  teamId: string;
  placeholder?: string;
  onSelect: Function;
  showSelf?: boolean;
  addedUser?: any[];
  disabled?: boolean;
}

const transitionVariants: Variants = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3, type: 'spring' } },
  exit: { opacity: 0, y: -5, transition: { duration: 0.15 } },
};

//Query build here as email field is of type citext in postgres and hasura doesn't have a citext type for query
const generateSearchQuery = (teamId: String, pattern: String) => {
  return gql`
    query {
      meating_team_members(where: {
        _and: [
          { team_id: { _eq: "${teamId}" } },
          {
            _or: [
              { rel_user_detail: { name: { _ilike: "%${pattern}%" } } },
              { rel_user_detail: { rel_user: { email: { _ilike: "%${pattern}%" } } } }
            ]
          }
        ]
      }) {
        rel_user_detail {
          id
          name
          avatar
          rel_user {
            email
          }
        }
      }
    }
  `;
};

const SearchUser: React.FC<RouteProps> = React.memo(
  ({
    teamId,
    placeholder = 'emailaddress@hahahoho.com',
    onSelect,
    showSelf = true,
    addedUser,
    disabled = false,
  }): JSX.Element => {
    const userId = useUserId();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [users, setUsers] = useState<any>();
    const [processSearchTerm] = useDebouncedValue(searchTerm, 200);

    const [getUsers, { loading, error, data: userList }] = useLazyQuery(PLACEHOLDER_FOR_COMPONENT_BUILT_QUERY);

    const closeOptions = () => {
      setSearchTerm('');
      setUsers(null);
    };

    const ref = useClickOutside(closeOptions);

    const searchUsers = useCallback(
      async (key: string) => {
        const searchQuery = generateSearchQuery(teamId, `%${key}%`);
        await getUsers({
          query: searchQuery,
          fetchPolicy: 'network-only',
        });
      },
      [teamId],
    );

    useEffect(() => {
      if (processSearchTerm && processSearchTerm.length >= 3) searchUsers(processSearchTerm);
      if (processSearchTerm === '') closeOptions();
    }, [processSearchTerm, searchUsers]);

    useEffect(() => {
      if (userList && userList.meating_team_members) {
        setUsers(userList.meating_team_members);
      }
    }, [userList]);

    const userSelected = (user: any) => {
      const userData = {
        id: user.rel_user_detail.id,
        name: user.rel_user_detail.name,
        email: user.rel_user_detail.rel_user.email,
        avatar: user.rel_user_detail.avatar,
      };
      onSelect(userData);
      closeOptions();
    };

    return (
      <div ref={ref} className="user-search-field-container">
        <div className="search-field-wrapper">
          <TextField placeholder={placeholder} value={searchTerm} onChange={setSearchTerm} disabled={disabled} />
          {loading && (
            <div className="dot-loader-wrapper">
              <div className="dot-loader"></div>
            </div>
          )}
        </div>
        <AnimatePresence mode="wait">
          {users && (
            <motion.div
              className="user-list-container"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={transitionVariants}
            >
              <div className="user-list-box">
                {users?.map((user) => {
                  const isUserInList = addedUser
                    ? addedUser.some((listUser) => listUser.id === user.rel_user_detail.id)
                    : false;

                  return (
                    !isUserInList &&
                    ((user.rel_user_detail.id === userId && showSelf) || user.rel_user_detail.id !== userId) && (
                      <div
                        key={user.rel_user_detail.id}
                        onClick={() => userSelected(user)}
                        className="user-item-wrapper ease-element"
                      >
                        <Avatar src={user.rel_user_detail.avatar} name={user.rel_user_detail.name} />
                        <div className="detail-wrapper">
                          <h5>{user.rel_user_detail.name}</h5>
                          <p>{user.rel_user_detail.rel_user.email}</p>
                        </div>
                      </div>
                    )
                  );
                })}

                {users.length === 0 && (
                  <div className="no-users-note">
                    🤷 No matches this time.
                    <br />
                    Consider adjusting your search.
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  },
);

export default SearchUser;
