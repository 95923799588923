import React from 'react';
import './Footer.scss';
import LeftPane from './components/LeftPane';
import RightPane from './components/RightPane';

interface RouteProps {}

const Footer: React.FC<RouteProps> = React.memo((): JSX.Element => {
  return (
    <footer>
      <div className="footer-wrapper side-padding ease-element">
        <LeftPane />
        <RightPane />
      </div>
    </footer>
  );
});

export default Footer;
