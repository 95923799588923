import React from 'react';
import UserInfo from './components/UserInfo';
import UserTeams from './components/UserTeams';
import Bulletin from './components/Bulletin';
import WorkspaceDomainHandler from './components/WorkspaceDomainHandler';

interface RouteProps {}

/*
Lurker is silent stalker for Meating. Include all your background processing in lurker as separate components for easy maintenance.
*/
const Lurker: React.FC<RouteProps> = ({}) => {
  return (
    <React.Fragment>
      <UserInfo />
      <UserTeams />
      <Bulletin />
      <WorkspaceDomainHandler />
    </React.Fragment>
  );
};

export default Lurker;
